import React, { useState, useMemo, useCallback, useEffect } from "react";
import { Permission } from "generatedTypes";
import { useToastAlertContext } from "contexts/ToastAlertContext";
import Input from "components/Input";
import { Form, Button } from "components";
import Select from "components/Select";
import { usePermissionsContext } from "features/permissions/context";
import { usePlansContext } from "containers/Plans/context/Plans.context";
import {
  useDetachPermissionFromPlanMutation,
  useLinkPermissionsToPlanMutation,
  useUpdatePermissionMutation,
} from "../../mutations.generated";

interface Props {
  permission: Permission;
  closeModal: () => void;
  handleDelete: () => void;
}

const EditPermissionForm = ({
  permission,
  closeModal,
  handleDelete,
}: Props) => {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [addedPlans, setAddedPlans] = useState([]);
  const [isFormValid, setIsFormValid] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { plans } = usePlansContext();
  const { refetchPermissions } = usePermissionsContext();
  const { createToastAlert } = useToastAlertContext();
  const [updatePermission] = useUpdatePermissionMutation();
  const [link] = useLinkPermissionsToPlanMutation();
  const [detach] = useDetachPermissionFromPlanMutation();

  const planOptions = useMemo(
    () =>
      plans?.map((plan) => ({
        label: plan?.name,
        value: plan?.id,
      })),
    [plans]
  );

  const associatedPlans = useMemo(
    () =>
      plans
        ?.filter(
          (pp) =>
            pp.permissions?.find((perm) => perm.id === permission?.id) !==
            undefined
        )
        .map((p) => p?.id),
    [permission?.id, plans]
  );

  useEffect(() => {
    setName(permission?.name);
    setDescription(permission?.description);
    setAddedPlans(
      planOptions?.filter((o) => associatedPlans?.includes(o.value))
    );
  }, [associatedPlans, permission?.description, permission?.name, planOptions]);

  const planIds = addedPlans?.map(({ value }) => value);
  const createdPlans = planIds?.filter((p) => !associatedPlans.includes(p));
  const deletedPlans = associatedPlans?.filter((p) => !planIds.includes(p));

  const handleOnSubmit = useCallback(
    async (event) => {
      event.preventDefault();
      setIsSubmitting(true);
      createToastAlert({
        processing: true,
      });

      try {
        await updatePermission({
          variables: {
            input: {
              name,
              description,
              permissionId: permission?.id,
            },
          },
        });

        await Promise.all(
          createdPlans.map((p) =>
            link({
              variables: {
                input: { permissionIds: [permission?.id], planId: p },
              },
            })
          )
        );

        await Promise.all(
          deletedPlans.map((p) =>
            detach({
              variables: { input: { permissionId: permission?.id, planId: p } },
            })
          )
        );

        await refetchPermissions();

        createToastAlert({
          alertType: "success",
          message: "Permission was successfully updated.",
        });

        closeModal();
      } catch (err) {
        createToastAlert({
          alertType: "error",
          message: "Permission could not be updated.",
        });
      } finally {
        setIsSubmitting(false);
      }
    },
    [createdPlans, deletedPlans, description, name, permission?.id]
  );

  return (
    <Form onSubmit={handleOnSubmit} onValidityCheck={setIsFormValid}>
      <div className="flex flex-col gap-6 p-5">
        <Input
          label="Name"
          placeholder="e.g. can:edit"
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
          description="For internal use. Pick something descriptive & easy to remember."
        />
        <Input
          label="Description"
          placeholder="Enter a description"
          infoHintText="Not shown to users"
          description="For internal use. Only appears in the dashboard."
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
        <Select
          label="Assign to existing plans"
          placeholder="Select a plan to add"
          options={planOptions}
          value={addedPlans}
          onChange={(o) => setAddedPlans(o)}
          isMulti
        />
        <button className="sr-only" type="submit">
          Submit
        </button>
      </div>
      <div className="flex justify-between items-center p-5 border-t">
        <Button
          text="Delete"
          buttonStyle="danger"
          onClick={handleDelete}
          dataCy="delete-permission-button"
        />
        <div className="flex">
          <Button
            text="Cancel"
            buttonStyle="skeleton"
            onClick={closeModal}
            tw="mr-2"
          />
          <Button
            text="Save"
            onClick={handleOnSubmit}
            type="submit"
            isDisabled={!isFormValid}
            isLoading={isSubmitting}
          />
        </div>
      </div>
    </Form>
  );
};

export default EditPermissionForm;
