import React, { ReactNode, useContext, useState } from "react";
import { MemberstackEnv } from "generatedTypes";
import ErrorPage from "containers/ErrorPage";
import { logInDevelopmentEnvironment } from "helpers/logInDevelopmentEnvironment";
import { getApolloContext } from "@apollo/client";
import { getClient } from "apolloClient";
import { useLocalStorageEnv } from "hooks/useLocalStorage";

interface ApolloContextType {
  updateClient: (env: MemberstackEnv) => void;
}

const defaultContext: ApolloContextType = {
  updateClient: () => {},
};

const ApolloContext = React.createContext<ApolloContextType>(defaultContext);

const useApolloContext = () => useContext(ApolloContext);

const ApolloContextProvider = ({ children }: { children: ReactNode }) => {
  const { storedEnv } = useLocalStorageEnv("_ms-env", MemberstackEnv.Live);
  const [client, setClient] = useState(getClient(storedEnv));
  const Apollo = getApolloContext();

  const updateClient = (env: MemberstackEnv) => {
    try {
      logInDevelopmentEnvironment(
        `📣 env changed to ${env} mode - getting client`
      );
      setClient(getClient(env));
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  if (!client) return <ErrorPage isFullScreen />;
  return (
    <ApolloContext.Provider value={{ updateClient }}>
      <Apollo.Provider value={{ client }}>{children}</Apollo.Provider>
    </ApolloContext.Provider>
  );
};

export { useApolloContext, ApolloContextProvider };
