import React, { lazy, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";

import {
  useGetMemberMetricsByReportQuery,
  useGetMembersQuery,
} from "features/members";
import { useEnvContext } from "contexts/EnvironmentContext";
import { useGetMemberEventsDashboardQuery } from "features/events";
import useDashboardEvent from "hooks/useWebsockets/dashboard.event";
import { useAppDataContext } from "contexts/AppDataContext";
import { MemberMetricsReport } from "generatedTypes";
import LeftEmptyState from "assets/images/empty_state_left.png";
import RightEmptyState from "assets/images/empty_state_right.png";
import { Banner, MetricsCard, EnvSwitcher, MemberLimitBox } from "components";
import { ArrowCircleUpOutlined, PeopleAltOutlined } from "@mui/icons-material";
import { logInDevelopmentEnvironment } from "helpers/logInDevelopmentEnvironment";
import SuspenseWrapper from "components/SuspenseWrapper/SuspenseWrapper";
import { MembersTable } from "./MembersTable";
import { EventLogTable } from "./EventLogTable";
import { Stats } from "./Stats";
import { SignupGraph } from "./SignupGraph/SignupGraph";
import { NewCommentsTable } from "./NewCommentsTable";

const Dashboard = () => {
  const navigate = useNavigate();
  const { isSandboxEnv, env } = useEnvContext();
  const dashboardEvents = useDashboardEvent();
  const { appData, isSubscribed } = useAppDataContext();

  const {
    data: membersData,
    loading,
    refetch: memberRefetch,
  } = useGetMembersQuery({
    variables: {
      first: 4,
    },
  });

  const {
    data: eventsData,
    loading: eventsLoading,
    refetch: eventRefetch,
  } = useGetMemberEventsDashboardQuery({
    variables: {
      first: 5,
    },
  });

  const {
    data: memberMetrics,
    loading: memberMetricsLoading,
    refetch: refetchMemberMetrics,
  } = useGetMemberMetricsByReportQuery({
    variables: {
      report: MemberMetricsReport.TotalMemberCountCompareLastMonth,
    },
  });

  const {
    data: signupMetrics,
    loading: signupMetricsLoading,
    refetch: refetchSignupMetrics,
  } = useGetMemberMetricsByReportQuery({
    variables: {
      report: MemberMetricsReport.MemberSignupCount_60DaysAnd_30Days,
    },
  });

  const handleDashboardEvent = (_data) => {
    logInDevelopmentEnvironment(
      `🚨 ${_data.event} event received - trigger refetch`
    );
    if (_data.event === "member.created" && env === _data.env) {
      refetchMemberMetrics();
      memberRefetch();
    }
    eventRefetch();
  };

  useEffect(() => {
    dashboardEvents.subscribe("dashboard-page", handleDashboardEvent);
    return () => {
      dashboardEvents.unsubscribe("dashboard-page");
    };
  });

  useEffect(() => {
    refetchMemberMetrics();
  }, [refetchMemberMetrics]);

  useEffect(() => {
    refetchSignupMetrics();
  }, [refetchSignupMetrics]);

  const memberMetricsInfo = memberMetrics?.getMemberMetricsByReport;
  const signupMetricsInfo = signupMetrics?.getMemberMetricsByReport;

  // this fixes the cache issue and forces apollo to get only the first 5 members
  useEffect(() => {
    memberRefetch();
  }, [memberRefetch]);

  // this fixes the cache issue and forces apollo to get only the first 4 events
  useEffect(() => {
    eventRefetch();
  }, [eventRefetch]);

  const MetricsAndGraph = useMemo(() => {
    if (loading || memberMetricsLoading || signupMetricsLoading) {
      return (
        <div>
          <div className="grid gap-x-2 grid-cols-2">
            <MetricsCard loading />
            <MetricsCard loading />
          </div>
        </div>
      );
    }
    if (!membersData?.getMembers?.edges?.length && !isSubscribed) {
      return (
        <div className="flex justify-between">
          <div className="pt-3">
            <img
              src={LeftEmptyState}
              alt="Empty Member Dashboard"
              className="max-w-[200px]"
            />
          </div>
          <div className="pt-2 hidden lg:block">
            <img
              src={RightEmptyState}
              alt="Empty Member Dashboard"
              className="max-w-[140px]"
            />
          </div>
        </div>
      );
    }
    return (
      <div className="grid gap-x-2 grid-cols-2">
        <MetricsCard
          title="Total Members"
          percentChange={memberMetricsInfo?.percentChange || 0}
          value={memberMetricsInfo?.totalMemberCount || 0}
          compared="last month"
          loading={memberMetricsLoading}
          icon={
            <PeopleAltOutlined
              className="text-app-blue500"
              style={{ width: "16px", height: "16px" }}
            />
          }
        />
        <MetricsCard
          title="Signups (last 30 days)"
          percentChange={signupMetricsInfo?.percentChange || 0}
          value={signupMetricsInfo?.memberSignupCount30Days || 0}
          compared="previous 30 days"
          loading={signupMetricsLoading}
          icon={
            <ArrowCircleUpOutlined
              className="text-app-purple500"
              style={{ width: "16px", height: "16px" }}
            />
          }
        />
      </div>
    );
  }, [
    navigate,
    loading,
    memberMetricsInfo?.percentChange,
    memberMetricsInfo?.totalMemberCount,
    memberMetricsLoading,
    membersData?.getMembers?.edges?.length,
    signupMetricsInfo?.memberSignupCount30Days,
    signupMetricsInfo?.percentChange,
    signupMetricsLoading,
  ]);

  return (
    <div className="flex flex-col flex-1 min-w-[640px] mr-3 gap-4">
      <div className="flex items-center justify-between mt-1">
        <h2 className="font-bold text-h3">Welcome back!</h2>
        {isSandboxEnv && (
          <Banner text="Test mode enabled — you’re now viewing test data" />
        )}
        <div className="flex gap-2">
          {!isSandboxEnv && appData?.memberCountLimit !== 0 && (
            <MemberLimitBox
              memberCountLimit={appData?.memberCountLimit}
              totalMemberCount={memberMetricsInfo?.totalMemberCount}
            />
          )}
          <EnvSwitcher />
        </div>
      </div>
      <div className="flex flex-col lg:grid grid-cols-2 gap-4">
        {MetricsAndGraph}
        <div className="grid gap-x-2 gap-y-2 grid-cols-2">
          <Stats />
        </div>
        <SignupGraph hasBorder />
        <EventLogTable eventsData={eventsData} eventsLoading={eventsLoading} />
      </div>
      <div className="flex flex-col lg:grid grid-cols-2 gap-4">
        <MembersTable membersData={membersData} membersLoading={loading} />
        <NewCommentsTable />
      </div>
    </div>
  );
};

export default Dashboard;
