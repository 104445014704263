import { MemberEventSource, MemberEventType } from "generatedTypes";
import { convertEnumToArray } from "helpers/convertEnumToArray";
import React, { useCallback, useMemo, useRef } from "react";
import {
  Create,
  Login,
  Refresh,
  AddCircleOutlineOutlined,
  Update,
  Close,
} from "@mui/icons-material";

export const initialEventState = {
  createdAt: Date.now(),
  data: {
    auth: { email: "" },
    customFields: null,
    id: "",
    metaData: {},
    planConnections: [],
    verified: false,
  },
  event: "",
  id: "",
  memberId: "",
  source: "",
  __typename: "",
};

export const MapEventToTag = {
  MEMBER_CREATED: {
    label: "Member Created",
    icon: <Create />,
    variant: "secondary",
  },
  MEMBER_LOGGEDIN: { label: "Logged in", icon: <Login />, variant: "success" },
  MEMBER_UPDATED: {
    label: "Member Updated",
    icon: <Refresh />,
    variant: "warning",
  },
  MEMBER_PLAN_ADDED: {
    label: "Plan Added",
    icon: <AddCircleOutlineOutlined />,
    variant: "primary",
  },
  MEMBER_PLAN_UPDATED: {
    label: "Plan Updated",
    icon: <Update />,
    variant: "tan",
  },
  MEMBER_PLAN_CANCELED: {
    label: "Plan Canceled",
    icon: <Close />,
    variant: "danger",
  },
};

export const useEventFiltering = ({ searchValue, refetch, searchBy }) => {
  const eventTypesRef = useRef(undefined);
  const sourceRef = useRef(undefined);

  const memberEventTypes = convertEnumToArray(MemberEventType).map((type) => ({
    id: type,
    label: MapEventToTag[type].label,
  }));
  const memberEventSource = convertEnumToArray(MemberEventSource).map(
    (source) => ({
      id: source,
      label: source,
    })
  );

  const handleFilter = useCallback(
    (type) => (itemSelected) => {
      if (type === "eventTypes") {
        eventTypesRef.current = itemSelected;
        return refetch({
          filters: {
            eventTypes: itemSelected,
            sourceTypes: sourceRef.current,
            [searchBy]: searchValue,
          },
        });
      }
      sourceRef.current = itemSelected;
      return refetch({
        filters: {
          eventTypes: eventTypesRef.current,
          sourceTypes: itemSelected,
          [searchBy]: searchValue,
        },
      });
    },
    [refetch, searchBy, searchValue]
  );

  const filterOptions = useMemo(
    () => [
      {
        label: "Event Types",
        value: "eventTypes",
        items: memberEventTypes,
        onFilter: handleFilter("eventTypes"),
      },
      {
        label: "Source",
        value: "source",
        items: memberEventSource,
        onFilter: handleFilter("source"),
      },
    ],
    [handleFilter, memberEventSource, memberEventTypes]
  );

  return {
    filterOptions,
    events: eventTypesRef,
    source: sourceRef,
  };
};

// import { useCallback, useEffect } from "react";
// import { debounce } from "lodash";

// export const useEventSearchWithFilter = ({
//   refetch,
//   planIds,
//   status,
//   setSearchValue,
// }) => {
//   const handleSearch = useCallback(
//     (inputValue) => {
//       refetch({
//         search: inputValue,
//         filters: { planIds: planIds.current, status: status.current },
//       });
//     },
//     [planIds, status]
//   );

//   const debounceSearch = useCallback(debounce(handleSearch, 900), []);

//   const changeHandler = useCallback(
//     (value) => {
//       setSearchValue(value);
//       debounceSearch(value);
//     },
//     [debounceSearch]
//   );

//   // Stop the invocation of the debounced function after unmounting
//   useEffect(() => {
//     return () => debounceSearch.cancel();
//     // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, []);

//   return [changeHandler];
// };
