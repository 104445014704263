import md5 from "md5";

const colorSchemes = {
  tan: {
    bg: "rgba(252, 245, 235, 1)",
    text: "rgba(180, 113, 24, 1)",
  },
  greyBlue: {
    bg: "rgba(227, 230, 243, 1)",
    text: "rgba(83, 101, 182, 1)",
  },
  red: {
    bg: "rgba(255, 234, 238, 1)",
    text: "rgba(255, 61, 71, 1)",
  },
  blue: {
    bg: "rgba(231, 241, 255, 1)",
    text: "rgba(41, 98, 255, 1)",
  },
  green: {
    bg: "rgba(228, 247, 233, 1)",
    text: "rgba(46, 200, 97, 1)",
  },
  purple: {
    bg: "rgba(240, 230, 253, 1)",
    text: "rgba(140, 66, 244, 1)",
  },
  grey: {
    bg: "rgba(246, 246, 246, 1)",
    text: "rgba(164, 164, 164, 1)",
  },
  lemon: {
    bg: "rgba(255, 255, 234, 1)",
    text: "rgba(181, 184, 0, 1)",
  },
  pink: {
    bg: "rgba(255, 231, 254, 1)",
    text: "rgba(238, 41, 255, 1)",
  },
};

// create a random color generator function based on the member's email address using the color schemes above
export const generateEmailColor = (
  email: string
): { bg: string; text: string } => {
  const emailHash = md5(email);
  const colorScheme =
    Object.keys(colorSchemes)[
      parseInt(emailHash.slice(0, 1), 16) % Object.keys(colorSchemes).length
    ];
  return colorSchemes[colorScheme];
};
