import React, { useCallback, useEffect, useState } from "react";
import Input from "components/Input";
import { isError } from "helpers/typeguards";
import { useToastAlertContext } from "contexts/ToastAlertContext";
import { Modal, ModalTypes } from "components/Modal";
import {
  AttributeInstallationBox,
  Button,
  Switch,
  Accordion,
  AccordionItem,
  Link,
} from "components";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { InfoTooltip } from "components/Tooltip";
import { RadioButtonGroup } from "components/RadioButton";
import { CustomFieldVisibility } from "generatedTypes";
import { useUpdateCustomFieldMutation } from "../mutations.generated";
import { DeleteCustomFieldModal } from "./DeleteCustomFieldModal";

const visibilityOptions = [
  {
    label: "Private",
    value: CustomFieldVisibility.Private,
    description: "Not accessible when posting. Only visible to the member.",
  },
  {
    label: "Public",
    value: CustomFieldVisibility.Public,
    description: "Visible to anyone on the web IF the member makes a post.",
  },
];

interface Props extends ModalTypes {
  id: string;
  label: string;
  uniqueKey: string;
  hidden: boolean;
  visibility: CustomFieldVisibility;
}

export const EditCustomFieldModal = ({
  showModal,
  setShowModal,
  id,
  label: initialLabel,
  hidden: initialHidden,
  uniqueKey,
  visibility: initialVisibility,
}: Props) => {
  const { createToastAlert } = useToastAlertContext();

  const [state, setState] = useState({
    label: initialLabel,
    hidden: initialHidden,
    visibility: initialVisibility,
  });

  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const handleShowDeleteModal = useCallback(() => setShowDeleteModal(true), []);
  const [modalError, setModalError] = useState(null);

  useEffect(() => {
    setState({
      label: initialLabel,
      hidden: initialHidden,
      visibility: initialVisibility,
    });
  }, [initialLabel, initialHidden, uniqueKey, initialVisibility]);

  const [updateCustomField, { loading }] = useUpdateCustomFieldMutation({
    variables: {
      input: {
        customFieldId: id,
        ...state,
      },
    },
    update: (cache, { data }) => {
      if (data?.updateCustomField) {
        cache.modify({
          id: cache.identify({ id, __typename: "CustomField" }),
          fields: {
            label: () => data.updateCustomField.label,
            hidden: () => data.updateCustomField.hidden,
            visibility: () => data.updateCustomField.visibility,
          },
        });
      }
    },
    onCompleted: () => {
      createToastAlert({
        alertType: "success",
        message: "Your changes were successfully saved",
      });
      setState({
        label: "",
        hidden: false,
        visibility: CustomFieldVisibility.Private,
      });
      setShowModal(false);
    },
    onError: (e) => {
      if (isError(e)) {
        setModalError(e.message);
      }
    },
  });

  const handleVisibilityChange = (value) =>
    setState((prevState) => ({ ...prevState, visibility: value }));

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      await updateCustomField();
    },
    [updateCustomField]
  );

  const hasChanged =
    state.label !== initialLabel ||
    state.hidden !== initialHidden ||
    state.visibility !== initialVisibility;

  return (
    <>
      <Modal
        setShowModal={setShowModal}
        removePadding
        showModal={showModal}
        title="Edit Custom Field"
        showDivider
        width="460px"
        actionButtons={{
          confirm: {
            label: "Save",
            onConfirm: handleSubmit,
            isDisabled: !hasChanged,
            isLoading: loading,
            dataCy: "save-custom-field-button",
          },
          cancel: { label: "Cancel" },
        }}
        bottomSectionComponent={
          <Button
            buttonStyle="skeleton"
            leftIcon={<DeleteOutlineOutlinedIcon />}
            text="Delete Custom Field"
            onClick={handleShowDeleteModal}
            tw="w-[fit-content]"
            dataCy="delete-custom-field-button"
          />
        }
        errorMessage={modalError}
        onErrorClose={() => setModalError(null)}
      >
        <form onSubmit={handleSubmit} className="p-5 pb-0">
          <Input
            required
            value={state.label}
            label="Custom Field Name"
            placeholder={state.label}
            onChange={(e) => setState({ ...state, label: e.target.value })}
            dataCy="edit-custom-field-name-input"
            description="Name of the custom field to be edited"
          />
          <AttributeInstallationBox
            description="Add this Custom Field as an attribute to show member data on your site."
            docsLink="https://docs.memberstack.com/hc/en-us/articles/7389502579355-Custom-Fields-Inputs"
            nameValue="data-ms-member"
            idValue={uniqueKey}
            className="mt-6"
          />
          <div className="mt-6 w-full p-3 rounded-lg bg-app-gray50">
            <Switch
              id="hide-from-prebuilt-ui"
              name="hideFromPrebuiltUI"
              isChecked={state.hidden}
              onChange={() => setState({ ...state, hidden: !state.hidden })}
              label="Hide from Pre-built UI"
              description="Hide the field from the prebuilt signup and profile UI"
            />
          </div>
          <button type="submit" className="sr-only">
            Submit
          </button>
        </form>
        <Accordion name="commenting" striped={false} persistent={false}>
          <AccordionItem
            label="Advanced/Commenting"
            value="advanced-commenting"
            isBeta
          >
            <div className="text-body-sm text-app-gray600 mb-2">
              Enable discussion threads and commenting.{" "}
              <Link
                to="https://docs.memberstack.com/hc/en-us/articles/18966275380123"
                showAsLink
                isExternal
                target="_blank"
                underline
              >
                Help Docs
              </Link>
              <p className="mt-4 font-bold text-black">Data Visibility</p>
              Decide if this data should be accessible via commenting UI.
            </div>
            <RadioButtonGroup
              label="Visibility"
              selectedValue={state.visibility}
              options={visibilityOptions}
              name="visibility"
              onChange={handleVisibilityChange}
              gap={8}
              labelClassName="font-bold text-body-sm"
            />
          </AccordionItem>
        </Accordion>
      </Modal>
      <DeleteCustomFieldModal
        id={id}
        label={state.label}
        showModal={showDeleteModal}
        setShowModal={setShowDeleteModal}
        closeEditModal={() => setShowModal(false)}
      />
    </>
  );
};
