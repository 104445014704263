import React from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import Text from "components/Text";
import { Button } from "components";
import { Stripe } from "components/Icons";
import Spacer from "components/Spacer";
import StripePreview from "assets/images/stripe_checkout_preview.jpg";

export const NoPayment = () => {
  const { appId } = useParams<{ appId: string }>();
  const navigate = useNavigate();

  return (
    <div className="flex-1 flex flex-col justify-between p-5 gap-5">
      <div className="flex flex-col items-start justify-center bg-app-gray50 rounded-lg p-8">
        <Stripe tw="w-20 h-10" />
        <Spacer spacing="small" />
        <Text className="mb-5">
          Please connect your Stripe account to add a price to your plan.
        </Text>
        <Button
          text="Connect"
          onClick={() => navigate(`/apps/${appId}/settings/stripe-settings`)}
        />
      </div>

      <div className=" bg-app-gray50 rounded-lg p-3 max-w-[380px]">
        <img
          src={StripePreview}
          alt="stripe preview"
          className="w-fit rounded-md"
        />
        <div className="text-base font-bold mt-2">
          Want to Preview Stripe Checkout?
        </div>
        <div className="text-base">Demo what your customers will see.</div>
        <Link
          target="_blank"
          to="https://memberstack-pricing-demo.webflow.io/"
          className="mt-4 text-base font-semibold text-app-blue500"
        >
          Launch the demo →
        </Link>
      </div>
    </div>
  );
};
