import { useMemberMetricsQuery } from "features";
import { useCallback, useMemo, useState } from "react";
import { format } from "date-fns";

export const getReadableDate = (utcDate) =>
  format(new Date(utcDate * 1000), "yyyy MMM dd: HH:mm:ss");

// get previous period based on current period end date and interval
export const getPreviousPeriod = (currentPeriod) => {
  const previousPeriodEndTimestamp = currentPeriod.startTimestamp - 1;
  const previousPeriodStartTimestamp =
    previousPeriodEndTimestamp -
    (currentPeriod.endTimestamp - currentPeriod.startTimestamp);

  return {
    startTimestamp: previousPeriodStartTimestamp,
    endTimestamp: previousPeriodEndTimestamp,
  };
};

const useGetMembersGraphMetrics = ({ range, interval }) => {
  const [memberMetricsData, setMemberMetricsData] = useState<{
    currPeriod: Record<string, number>;
    prevPeriod: Record<string, number>;
  }>(null);

  const currentPeriod = useMemo(
    () => ({
      // @ts-ignore
      startTimestamp: Math.round(range?.from / 1000),
      endTimestamp: Math.round(range?.to?.setMilliseconds(0) / 1000),
    }),
    [range?.from, range?.to]
  );

  const previousPeriod = getPreviousPeriod(currentPeriod);

  const timezone =
    Intl?.DateTimeFormat().resolvedOptions().timeZone || "America/New_York";

  const { loading: loadingCur, refetch: refetchCur } = useMemberMetricsQuery({
    variables: {
      input: {
        startTimestamp: currentPeriod.startTimestamp,
        endTimestamp: currentPeriod.endTimestamp,
        interval,
        timezone,
      },
    },
    onCompleted: ({ getMemberMetrics }) => {
      setMemberMetricsData((prev) => ({
        ...prev,
        currPeriod: getMemberMetrics,
      }));
    },
  });

  const { loading: loadingPrev, refetch: refetchPrev } = useMemberMetricsQuery({
    variables: {
      input: {
        startTimestamp: previousPeriod.startTimestamp,
        endTimestamp: previousPeriod.endTimestamp,
        interval,
        timezone,
      },
    },
    onCompleted: ({ getMemberMetrics }) => {
      setMemberMetricsData((prev) => ({
        ...prev,
        prevPeriod: getMemberMetrics,
      }));
    },
  });

  const refetchMemberMetrics = useCallback(() => {
    refetchCur();
    refetchPrev();
  }, [refetchCur, refetchPrev]);

  const loading = useMemo(
    () => loadingCur || loadingPrev,
    [loadingCur, loadingPrev]
  );

  const isDataAvailable = useMemo(() => {
    return (
      memberMetricsData?.currPeriod &&
      Object.keys(memberMetricsData?.currPeriod)?.length > 0 &&
      memberMetricsData?.prevPeriod &&
      Object.keys(memberMetricsData?.prevPeriod)?.length > 0
    );
  }, [memberMetricsData]);

  const isReady = useMemo(
    () => !loading && isDataAvailable,
    [loading, isDataAvailable]
  );

  return { isReady, refetchMemberMetrics, memberMetricsData };
};

export default useGetMembersGraphMetrics;
