import React from "react";
import { Card } from "components/Card";
import mergeClassNames from "helpers/mergeClassNames/mergeClassNames";
import Skeleton from "react-loading-skeleton";
import { PostImageContainer, PostStatusBar } from "./CommentCard.styles";

interface Props {
  className?: string;
  isCompact?: boolean;
}

export const CommentCardSkeleton = ({ className, isCompact }: Props) => {
  return (
    <Card
      className={mergeClassNames("p-3 flex gap-4", className)}
      hideBorder={false}
    >
      {!isCompact && <PostStatusBar postStatus={undefined} />}
      <div className="flex gap-4 flex-col w-full">
        <div className="flex justify-between">
          {!isCompact ? (
            <div className="flex items-center">
              <PostImageContainer postStatus={undefined}>
                <Skeleton
                  className="rounded-full p-px w-full h-full flex"
                  style={{ borderRadius: "9999px", display: "flex" }}
                />
              </PostImageContainer>
              <div className="flex flex-col">
                <Skeleton width={178} />
                <Skeleton width={170} />
              </div>
            </div>
          ) : (
            <Skeleton width={77} height={24} />
          )}

          <div className="flex items-center gap-3">
            <Skeleton width={100} height={16} />
            <Skeleton width={60} height={16} />
            <Skeleton width={16} height={24} className="rounded-full" />
          </div>
        </div>
        <Skeleton width="70%" height={24} />
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-3 text-utility-tag-md text-app-gray700 font-bold">
            <Skeleton width={30} height={21} />
            <Skeleton width={30} height={21} />
          </div>
          <div className="flex items-center gap-2">
            <Skeleton width={81} height={30} />
            <Skeleton width={65} height={30} />
            <Skeleton width={56} height={30} />
          </div>
        </div>
      </div>
    </Card>
  );
};
