import React, { useEffect, useRef, useState, useMemo } from "react";
import { Button, ContentWrapper, Link, Switch } from "components";
import { useParams } from "react-router-dom";
import {
  useGetClientMessageTextQuery,
  useUpdateClientMessageTextMutation,
} from "features/translations";
import { useFormTranslations } from "hooks";
import { getObjectWithKeys } from "helpers/getObjectWithKeys";
import { isObjectEqual } from "helpers/isObjectEqual";
import { removeEmpty } from "helpers/removeEmpty";
import { trimObjectStringValues } from "helpers/trimObjectStringValues";
import { useUpdateAppMutation } from "features";
import useOnSubmit from "hooks/useOnSubmit/useOnSubmit";
import { ChevronLeft } from "@mui/icons-material";
import { useAppDataContext } from "contexts/AppDataContext";
import { useUnsavedChangesContext } from "contexts/UnsavedChangesContext";
import { ErrorMessagesLoader } from "./ErrorMessagesLoader";
import { ErrorMessagesForm } from "./ErrorMessagesForm";

const DEFAULT_FORM_STATE = {
  "invalid-credentials": "",
  "use-social-login": "",
  "password-reset-instructions": "",
  "invalid-reset-code": "",
  "invalid-password-to-short": "",
  "account-already-connected": "",
  "account-connect-failed": "",
  "account-not-found": "",
  "account-connect-requires-login": "",
  "account-set-password-before-disconnect": "",
  "account-not-connected": "",
  "domain-not-permitted": "",
  "already-have-plan": "",
  "login-required": "",
  "email-already-in-use": "",
  "invalid-password": "",
  "invalid-captcha": "",
  "email-verification-sent": "",
  "plan-successfully-removed": "",
  "plan-successfully-added": "",
  "logout-successful": "",
  "signup-successful": "",
  "login-successful": "",
  "account-connect-successful": "",
  "account-disconnect-successful": "",
  "member-profile-updated": "",
  "member-email-updated": "",
  "member-password-updated": "",
  "member-password-successfully-reset": "",
  "plan-member-limit-reached": "",
  "passwordless-email-not-found": "",
  "invalid-passwordless-login-code": "",
};

export const ErrorMessages = () => {
  const valRef = useRef(null);
  const [showSettings, setShowSettings] = useState(false);
  const { appData, refetch: appRefetch } = useAppDataContext();
  const { appId } = useParams<{ appId: string }>();
  const [form, setForm] = useState<{ [key: string]: string }>(
    DEFAULT_FORM_STATE
  );
  const { loading, refetch, data } = useGetClientMessageTextQuery();
  const { setHasUnsavedChanges } = useUnsavedChangesContext();

  const errorMessage = useMemo(
    () => data?.getClientMessageText.textObject,
    [data?.getClientMessageText.textObject]
  );
  const extractedState = useMemo(
    () =>
      getObjectWithKeys(errorMessage || {}, Object.keys(DEFAULT_FORM_STATE)),
    [errorMessage]
  );

  useEffect(
    () => setShowSettings(appData?.clientMessageTextEnabled),
    [appData?.clientMessageTextEnabled]
  );

  useEffect(() => {
    if (!errorMessage) {
      return;
    }
    valRef.current = extractedState;
    setForm(extractedState);
  }, [errorMessage, extractedState]);

  const { formProgress, handleInputChange } = useFormTranslations({
    form,
    setForm,
  });

  const [
    updateErrorMessages,
    { loading: updatingErrorTexts, error: errorUpdatingErrorText },
  ] = useUpdateClientMessageTextMutation();

  const [updateAppSetting, { loading: updatingAppSetting }] =
    useUpdateAppMutation();

  const { submit: handleUpdateErrorMessages } = useOnSubmit({
    action: updateErrorMessages,
    fields: { textObject: form },
    refetch,
    errorMsg: errorUpdatingErrorText?.message,
    successMsg: "Your changes were successfully saved",
  });

  const { submit: handleEnableErrorMessageText } = useOnSubmit({
    action: updateAppSetting,
    fields: { clientMessageTextEnabled: showSettings },
    refetch: appRefetch,
    errorMsg: errorUpdatingErrorText?.message,
    successMsg: "Your changes were successfully saved",
  });

  const formChanged = !(
    isObjectEqual(
      removeEmpty(valRef.current || {}),
      removeEmpty(trimObjectStringValues(form))
    ) && appData?.clientMessageTextEnabled === showSettings
  );

  useEffect(() => {
    if (formChanged) {
      return setHasUnsavedChanges(true);
    }
    return setHasUnsavedChanges(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formChanged]);

  if (loading) return <ErrorMessagesLoader />;

  const handleSubmit = async (e) => {
    e.preventDefault();

    await handleUpdateErrorMessages();
    if (showSettings !== appData?.clientMessageTextEnabled) {
      await handleEnableErrorMessageText();
    }
  };

  return (
    <ContentWrapper className="flex flex-col h-full">
      <div className="p-4">
        <Link
          to={`/apps/${appId}/settings/translations`}
          type="button"
          tw="flex self-start items-center mb-1"
        >
          <ChevronLeft tw="!h-4 !w-4" />
          <span tw="truncate max-w-[28ch]">Translation</span>
        </Link>
        <div className="flex items-center justify-between mb-6">
          <h3 className="text-h3 font-bold">
            Translate Error & Success Messages
          </h3>
          <Button
            text="Save"
            onClick={(e) => handleSubmit(e)}
            isLoading={updatingErrorTexts}
            isDisabled={!formChanged}
          />
        </div>
        <Switch
          id="clientMessageTextEnabled"
          isChecked={showSettings}
          onChange={() => setShowSettings(!showSettings)}
          label="Enable Error & Success Translations"
          name="clientMessageTextEnabled"
          disabled={updatingAppSetting}
        />
      </div>
      {showSettings && (
        <ErrorMessagesForm
          disableInputs={updatingErrorTexts}
          handleSubmit={handleSubmit}
          form={form}
          formProgress={formProgress}
          handleInputChange={handleInputChange}
        />
      )}
    </ContentWrapper>
  );
};
