import React from "react";
import { Modal, ModalTypes } from "components/Modal";

const ExportCommentsSuccessModal = ({
  setShowModal,
  showModal,
}: ModalTypes) => (
  <Modal
    setShowModal={setShowModal}
    showModal={showModal}
    title="Your export is on the way!"
    description="Please check your email for your export files. Export done in batches of 10,000 comments as a CSV file."
    width="480px"
    showDivider={false}
    showBottomSection={false}
  />
);

export default ExportCommentsSuccessModal;
