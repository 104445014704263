import React, { useCallback, useMemo, useState } from "react";
import { Modal } from "components/Modal";
import Text from "components/Text";
import { useToastAlertContext } from "contexts/ToastAlertContext";
import { useNavigate, useParams } from "react-router-dom";
import { useDeletePriceMutation } from "features";
import { getResponseError } from "helpers/getResponseError";

export const DeletePriceModal = ({ showModal, setShowModal, price }) => {
  const navigate = useNavigate();
  const { appId, priceId, planId } = useParams<{
    priceId: string;
    appId: string;
    planId: string;
  }>();

  const { createToastAlert } = useToastAlertContext();
  const [modalError, setModalError] = useState(null);

  const isPricePage = Boolean(priceId);

  const [deletePrice, { loading }] = useDeletePriceMutation({
    variables: {
      input: {
        priceId: price?.id,
      },
    },
    update: (cache) => {
      if (isPricePage) {
        navigate(`/apps/${appId}/plans/${planId}`);
      }
      cache.evict({ id: `Price:${price?.id}` });
    },
    onCompleted: () => {
      setShowModal(false);
      createToastAlert({
        alertType: "success",
        message: "Price deleted successfully.",
      });
    },
    onError: (error) => {
      setModalError(getResponseError(error));
    },
  });

  const deletePriceHandler = useCallback(async () => {
    createToastAlert({ processing: true });
    await deletePrice();
  }, [createToastAlert, deletePrice]);

  const message = useMemo(
    () =>
      !price?.memberCount ? (
        <>
          This action cannot be undone. Proceed with deleting the{" "}
          <b>{price?.name}</b> price?
        </>
      ) : (
        "This price cannot be deleted because it already has associated members."
      ),
    [price?.memberCount, price?.name]
  );

  return (
    <Modal
      setShowModal={setShowModal}
      showModal={showModal}
      title="Confirm deletion"
      showDivider
      actionButtons={
        !price?.memberCount
          ? {
              cancel: {
                label: "Cancel",
              },
              confirm: {
                label: "Delete price",
                onConfirm: deletePriceHandler,
                isLoading: loading,
                buttonStyle: "danger",
              },
            }
          : {
              cancel: {
                label: "Okay",
              },
            }
      }
      errorMessage={modalError}
      onErrorClose={() => setModalError(null)}
    >
      <Text className="mb-2">{message}</Text>
    </Modal>
  );
};
