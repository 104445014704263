/* eslint-disable react/no-children-prop */
import React, { lazy } from "react";
import { Navigate } from "react-router-dom";
import {
  VerificationEmail,
  PasswordResetEmail,
  PasswordlessEmail,
  Emails,
  HelloAndWelcome,
  AbandonedCart,
} from "containers/Settings/emails";
import {
  ErrorMessages,
  ResetPasswordRequest,
  TranslateLogin,
  Translation,
  ChangePassword,
  TranslateAuth,
} from "containers/Settings/translation";
import { UiComponents } from "containers/Community/UiComponents";
import { CommunitySettings } from "containers/Community/Settings/CommunitySettings";
import {
  General,
  Notifications as CommunityNotifications,
  Emails as CommunityEmails,
} from "containers/Community/Settings";
import RootLayout from "containers/Layouts/RootLayout";
import AuthLayout from "containers/Layouts/AuthLayout";
import { CommentingNotification } from "containers/Settings/emails/Templates/CommentingNotification";
import { CreatePlanPrice } from "containers/Plans/Plan/plan-settings/Prices/CreatePlanPrice";
import { EditPlanPrice } from "containers/Plans/Plan/plan-settings/Prices/EditPlanPrice";
import Plans, { PlanDetails, EditPlan, CreatePlan } from "containers/Plans";
import AppsLayout from "containers/Layouts/AppLayout";
import PrivateLayout from "containers/Layouts/PrivateLayout";
import Dashboard from "containers/Dashboard/Dashboard";
import Members from "containers/Members";
import GatedContent from "containers/GatedContent";
import Components from "containers/Components";
import Community from "containers/Community/Community";
import EventLog from "containers/EventLog/EventLog";
import Devtools from "containers/Devtools";
import Settings from "containers/Settings";
import DefaulPlanSettings from "containers/Plans/Plan/DefaultSettings/DefaultSettings";
import CommentsPage from "containers/Community/Comments";

const LazyLogin = React.lazy(() => import("containers/Login"));
const LazyReset = React.lazy(() => import("containers/Reset"));
const LazySignup = React.lazy(() => import("containers/Signup"));
const Lazy2FA = React.lazy(
  () => import("containers/TwoFactorAuthenticator/TwoFactorAuthenticator")
);
const LazyStripeConnect = React.lazy(
  () => import("containers/Settings/stripe-connect")
);
const GettingStarted = lazy(() => import("containers/GettingStarted"));
const WebhookSettings = lazy(
  () => import("containers/Devtools/Webhooks/WebhookSettings")
);
const Permissions = lazy(() => import("containers/Permissions"));
const PageNotFound = lazy(() => import("containers/PageNotFound"));
const LazyCreateApp = lazy(() => import("containers/CreateApp"));
const AuthProviders = lazy(
  () => import("containers/Settings/auth-integration")
);
const SettingsDangerZone = lazy(
  () => import("containers/Settings/danger-zone")
);
const AppSettings = lazy(
  () => import("containers/Settings/application-settings")
);
const InstallCode = lazy(() => import("containers/Settings/install-code"));
const TeamSettings = lazy(() => import("containers/Settings/team-settings"));
const Design = lazy(() => import("containers/Settings/design"));
const Billing = lazy(() => import("containers/Settings/billing"));
const StripeSettingsPage = lazy(
  () => import("containers/Settings/stripe-settings/StripeSettingsPage")
);
const ConnectWebflowPage = lazy(
  () => import("containers/Settings/connect-webflow/ConnectWebflow")
);
const Notifications = lazy(
  () => import("containers/Settings/notifications/Notifications")
);
const ReactCode = lazy(
  () => import("containers/Settings/install-code/ReactCode")
);
const WebflowCode = lazy(
  () => import("containers/Settings/install-code/WebflowCode")
);
const VanillaCode = lazy(
  () => import("containers/Settings/install-code/Vanilla")
);
const Channels = lazy(() => import("containers/Community/Channels"));
const Profile = lazy(() => import("containers/Members/MemberPane/Profile"));
const Connections = lazy(
  () => import("containers/Members/MemberPane/Connections")
);
const Comments = lazy(
  () => import("containers/Members/MemberPane/Comments/Comments")
);

export default [
  {
    element: <RootLayout />,
    children: [
      { path: "*", element: <PageNotFound /> },
      {
        element: <AuthLayout />,
        children: [
          { path: "/login", element: <LazyLogin /> },
          { path: "/reset", element: <LazyReset /> },
          { path: "/signup", element: <LazySignup /> },
        ],
      },
      {
        element: <PrivateLayout />,
        children: [
          { path: "/", element: <Navigate to="/apps" replace /> },
          { path: "/two-factor-authenticator", element: <Lazy2FA /> },
          { path: "/stripe/callback", element: <LazyStripeConnect /> },
          { path: "/create-app", element: <LazyCreateApp /> },
          { path: "/getting-started" },
          { path: "/dashboard/*" },
          { path: "/members/*" },
          { path: "/plans/*" },
          {
            path: "/plan/redirect",
            element: <Navigate to="/plans/redirects" replace />,
          },
          { path: "/gated-content/*" },
          { path: "/community/*" },
          { path: "/event-log/*" },
          { path: "/devtools/*" },
          { path: "/settings/*" },
          {
            path: "/billing",
            element: <Navigate to="/settings/billing" replace />,
          },
          {
            path: "/billing/commenting-pricing",
            element: (
              <Navigate to="/settings/billing/commenting-pricing" replace />
            ),
          },
          {
            path: "/apps",
            children: [
              {
                path: ":appId",
                element: <AppsLayout />,
                children: [
                  {
                    index: true,
                    element: <Navigate to="dashboard" replace />,
                  },
                  { path: "getting-started", element: <GettingStarted /> },
                  { path: "dashboard", element: <Dashboard /> },
                  {
                    path: "members",
                    element: <Members />,
                    children: [
                      {
                        path: ":memberId",
                        children: [
                          {
                            index: true,
                            element: <Navigate to="profile" replace />,
                          },
                          {
                            path: "profile",
                            element: <Profile />,
                          },
                          {
                            path: "connections",
                            element: <Connections />,
                          },
                          {
                            path: "comments",
                            element: <Comments />,
                          },
                        ],
                      },
                    ],
                  },
                  {
                    path: "plans",
                    children: [
                      {
                        index: true,
                        element: <Navigate to="redirects" replace />,
                      },
                      {
                        path: "create",
                        element: <CreatePlan />,
                      },
                      {
                        path: ":planId/create-price",
                        element: <CreatePlanPrice />,
                      },

                      {
                        path: ":planId/price/:priceId",
                        element: <EditPlanPrice />,
                      },
                      {
                        element: <Plans />,
                        children: [
                          {
                            path: "redirects",
                            element: <DefaulPlanSettings />,
                          },
                          {
                            path: ":planId",
                            children: [
                              {
                                index: true,
                                element: <PlanDetails />,
                              },
                              {
                                path: "edit-plan",
                                element: <EditPlan />,
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                  { path: "gated-content", element: <GatedContent /> },
                  { path: "editor", element: <Components /> },
                  {
                    path: "community",
                    element: <Community />,
                    children: [
                      {
                        index: true,
                        element: <Navigate to="comments/ALL" replace />,
                      },
                      {
                        path: "comments",
                        element: <CommentsPage />,
                        children: [
                          {
                            index: true,
                            element: <Navigate to="ALL" replace />,
                          },
                          { path: ":status" },
                        ],
                      },
                      { path: "channels", element: <Channels /> },
                      { path: "ui-components", element: <UiComponents /> },
                      {
                        path: "settings",
                        element: <CommunitySettings />,
                        children: [
                          { index: true, element: <Navigate to="general" /> },
                          { path: "general", element: <General /> },
                          {
                            path: "notifications",
                            element: <CommunityNotifications />,
                          },
                          { path: "emails", element: <CommunityEmails /> },
                        ],
                      },
                    ],
                  },
                  { path: "event-log", element: <EventLog /> },
                  {
                    path: "devtools",
                    children: [
                      {
                        index: true,
                        element: <Devtools />,
                      },
                      {
                        path: "permissions",
                        element: <Permissions />,
                      },
                      {
                        path: "webhooks/:webhookId",
                        element: <WebhookSettings />,
                      },
                    ],
                  },
                  {
                    path: "settings",
                    children: [
                      {
                        element: <Settings />,
                        children: [
                          {
                            index: true,
                            element: <Navigate to="application" replace />,
                          },
                          { path: "application", element: <AppSettings /> },
                          {
                            path: "install-code",
                            element: <InstallCode />,
                            children: [
                              {
                                index: true,
                                element: <Navigate to="webflow" replace />,
                              },
                              { path: "webflow", element: <WebflowCode /> },
                              { path: "react", element: <ReactCode /> },
                              { path: "vanilla", element: <VanillaCode /> },
                            ],
                          },
                          {
                            path: "notifications",
                            element: <Notifications />,
                          },
                          { path: "team", element: <TeamSettings /> },
                          {
                            path: "auth-providers",
                            element: <AuthProviders />,
                          },
                          {
                            path: "stripe-settings",
                            element: <StripeSettingsPage />,
                          },
                          {
                            path: "danger-zone",
                            element: <SettingsDangerZone />,
                          },
                          {
                            path: "connect-webflow",
                            element: <ConnectWebflowPage />,
                          },
                          { path: "design", element: <Design /> },
                          {
                            path: "emails",
                            element: <Emails />,
                          },
                          {
                            path: "translations",
                            element: <Translation />,
                          },
                          {
                            path: "billing",
                            element: <Billing />,
                            children: [
                              {
                                path: "commenting-pricing",
                                element: <Billing />,
                              },
                            ],
                          },
                        ],
                      },
                      {
                        path: "emails/password-reset-email",
                        element: <PasswordResetEmail />,
                      },
                      {
                        path: "emails/passwordless-email",
                        element: <PasswordlessEmail />,
                      },
                      {
                        path: "emails/email-verification",
                        element: <VerificationEmail />,
                      },
                      {
                        path: "emails/hello-and-welcome",
                        element: <HelloAndWelcome />,
                      },
                      {
                        path: "emails/abandoned-cart",
                        element: <AbandonedCart />,
                      },
                      {
                        path: "emails/commenting-notification",
                        element: <CommentingNotification />,
                      },
                      {
                        path: "translations/error-messages",
                        element: <ErrorMessages />,
                      },
                      {
                        path: "translations/reset-password-request",
                        element: <ResetPasswordRequest />,
                      },
                      {
                        path: "translations/translate-login",
                        element: <TranslateLogin />,
                      },
                      {
                        path: "translations/change-password",
                        element: <ChangePassword />,
                      },
                      {
                        path: "translations/translate-auth",
                        element: <TranslateAuth />,
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
];

// const {
//   members,
//   plan,
//   settings,
//   emails,
//   passwordResetEmail,
//   emailVerification,
//   redirects,
//   permissions,
//   devtools,
//   gatedContent,
//   billing,
//   apps,
//   installCode,
//   gettingStarted,
//   translations,
//   connectWebflow,
//   eventLog,
//   dashboard,
// };

// export const shareableLinks = [

//   {
//     path: "/devtools/permissions",
//     url: (id) => `/${apps}/${id}/${devtools}/${permissions}`,
//   },
//   {
//     path: "/gated-content",
//     url: (id) => `/${apps}/${id}/${gatedContent}`,
//   },
//   {
//     path: "/devtools",
//     url: (id) => `/${apps}/${id}/${devtools}`,
//   },
//   {
//     path: "/community",
//     url: (id) => `/${apps}/${id}/community/comments`,
//   },
//   {
//     path: "/community/comments",
//     url: (id) => `/${apps}/${id}/community/comments`,
//   },
//   {
//     path: "/event-log",
//     url: (id) => `/${apps}/${id}/${eventLog}`,
//   },
//   {
//     path: "/settings",
//     url: (id) => `/${apps}/${id}/${settings}`,
//   },
//   {
//     path: "/settings/application",
//     url: (id) => `/${apps}/${id}/${settings}/application`,
//   },
//   {
//     path: "/settings/install-code",
//     url: (id) => `/${apps}/${id}/${settings}/${installCode}/webflow`,
//   },
//   {
//     path: "/billing",
//     url: (id) => `/${apps}/${id}/${settings}/${billing}`,
//   },
//   {
//     path: "/billing/commenting-pricing",
//     url: (id) => `/${apps}/${id}/${settings}/${billing}/commenting-pricing`,
//   },
//   {
//     path: "/settings/billing",
//     url: (id) => `/${apps}/${id}/${settings}/${billing}`,
//   },
//   {
//     path: "/settings/translations",
//     url: (id) => `/${apps}/${id}/${settings}/${translations}`,
//   },
//   {
//     path: "/settings/install-code/webflow",
//     url: (id) => `/${apps}/${id}/${settings}/${installCode}/webflow`,
//   },
//   {
//     path: "/settings/connect-webflow",
//     url: (id) => `/${apps}/${id}/${settings}/${connectWebflow}`,
//   },
//   {
//     path: "/settings/install-code/react",
//     url: (id) => `/${apps}/${id}/${settings}/${installCode}/react`,
//   },
//   {
//     path: "/settings/install-code/vanilla",
//     url: (id) => `/${apps}/${id}/${settings}/${installCode}/vanilla`,
//   },
//   {
//     path: "/settings/team",
//     url: (id) => `/${apps}/${id}/${settings}/team`,
//   },
//   {
//     path: "/settings/notifications",
//     url: (id) => `/${apps}/${id}/${settings}/notifications`,
//   },
//   {
//     path: "/settings/emails",
//     url: (id) => `/${apps}/${id}/${settings}/emails`,
//   },
//   {
//     path: "/settings/payment-settings",
//     url: (id) => `/${apps}/${id}/${settings}/payment-settings`,
//   },
//   {
//     path: "/settings/danger-zone",
//     url: (id) => `/${apps}/${id}/${settings}/danger-zone`,
//   },
//   {
//     path: "/settings/design",
//     url: (id) => `/${apps}/${id}/${settings}/design`,
//   },
//   {
//     path: "/settings/stripe-settings",
//     url: (id) => `/${apps}/${id}/${settings}/stripe-settings`,
//   },
// ];
