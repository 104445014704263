import React from "react";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { useToastAlertContext } from "contexts/ToastAlertContext";
import Select from "components/Select";

interface Props {
  name: string;
  value: string;
  toastFeedback: string;
}

export const InstallationInput = ({ name, value, toastFeedback }: Props) => {
  const { createToastAlert } = useToastAlertContext();

  const handleCopy = async (event) => {
    event.stopPropagation();
    try {
      await navigator.clipboard.writeText(value.toString());
      createToastAlert({
        alertType: "success",
        message: toastFeedback,
      });
    } catch (err) {
      createToastAlert({
        alertType: "error",
        message: "Failed to copy text",
      });
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleCopy(event);
    }
  };

  return (
    <div
      className="relative mt-1 rounded-md shadow-sm w-full"
      role="button"
      tabIndex={0}
      onKeyPress={handleKeyDown}
    >
      <input
        id={name}
        type="text"
        name={name}
        value={value}
        className="block w-full h-8 rounded-sm border-gray-300 pr-10 p-2 bg-app-gray800 text-app-gray200 cursor-pointer text-sm"
        readOnly
        onClick={handleCopy}
      />
      <div className="absolute inset-y-0 right-0 flex items-center pr-3">
        <ContentCopyIcon
          className="h-5 w-5 text-app-gray200"
          onClick={handleCopy}
        />
      </div>
    </div>
  );
};

export const InstallationSelect = ({ options, toastFeedback }) => {
  return (
    <Select
      showCopy
      options={options}
      // value={value}
      preselectValue
      className="w-full"
      inverted
      copyMessage={toastFeedback}
    />
  );
};
