import React from "react";
import { Modal } from "components/Modal";
import { Link } from "components";

export const CreatePriceModal = ({
  showModal,
  setShowModal,
  onConfirm,
  isLoading,
}) => (
  <Modal
    setShowModal={setShowModal}
    showModal={showModal}
    title="Create a new price"
    showDivider
    actionButtons={{
      cancel: {
        label: "Cancel",
      },
      confirm: {
        label: "Create new price",
        onConfirm: () => onConfirm(),
        isLoading,
      },
    }}
  >
    <div className="font-bold text-base">
      Once you create a new price,{" "}
      <span className="underline">it cannot be edited</span>.
    </div>
    <div className="mt-2">
      You will still be able to delete or archive it after it’s created.{" "}
      <Link
        to="https://docs.memberstack.com/hc/en-us/articles/7403200877211-Guide-to-Understanding-Paid-Plans-Prices-in-2-0"
        isExternal
        underline
        target="_blank"
        className="!text-base"
      >
        Learn more
      </Link>
    </div>
  </Modal>
);
