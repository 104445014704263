import { EmptyState } from "components";
import React from "react";
import Skeleton from "react-loading-skeleton";
import EmptyComments from "assets/images/empty-comments.svg";
import { useNavigate, useParams } from "react-router-dom";
import { CommentCardSkeleton } from "components/CommentCard/CommentCardSkeleton";

export const CommunityLoader = ({ title }: { title: string }) => (
  <>
    <div className="flex justify-between items-center p-5 border-b">
      <h4 className="text-base">{title}</h4>
    </div>
    <div className="p-5 h-full">
      <div className="h-full flex flex-col gap-2">
        <Skeleton width="100%" height={title === "Channels" ? 54 : 160} />
        <Skeleton width="100%" height={title === "Channels" ? 54 : 160} />
        <Skeleton width="100%" height={title === "Channels" ? 54 : 160} />
        <Skeleton width="100%" height={title === "Channels" ? 54 : 160} />
      </div>
    </div>
  </>
);

export const CommentsLoader = ({ isCompact }: { isCompact: boolean }) => (
  <div className="p-5 h-full">
    <div className="h-full flex flex-col gap-2">
      <CommentCardSkeleton isCompact={isCompact} />
      <CommentCardSkeleton isCompact={isCompact} />
      <CommentCardSkeleton isCompact={isCompact} />
      <CommentCardSkeleton isCompact={isCompact} />
    </div>
  </div>
);

export const CommunityEmptyState = ({
  description,
  title = "Get Started with Commenting",
}: {
  description: string;
  title?: string;
}) => {
  const navigate = useNavigate();
  const { appId } = useParams<{ appId: string }>();

  return (
    <EmptyState
      isFullScreen
      id="channels-empty-state"
      text={title}
      description={description}
      buttonText="Install Commenting"
      onCreateClick={() => navigate(`/apps/${appId}/community/ui-components`)}
      onDemoClick={() =>
        window.open(
          "https://docs.memberstack.com/hc/en-us/articles/18966275380123",
          "_blank"
        )
      }
      svgImage={<EmptyComments />}
    />
  );
};
