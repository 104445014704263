import React, { Suspense } from "react";
import { render } from "react-dom";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import * as Sentry from "@sentry/react";
import { init } from "commandbar";
import Chart from "chart.js/auto";
import { CategoryScale } from "chart.js";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { PostHogProvider } from "posthog-js/react";
import { ThemeProvider } from "styled-components";
import { IntlProvider } from "react-intl";
import { theme } from "styles";
import { ApolloContextProvider } from "contexts/ApolloContext";
import { EnvContextProvider } from "contexts/EnvironmentContext";
import { ToastAlertContextProvider } from "contexts/ToastAlertContext";
import Loader from "components/Loader";
import { translations } from "./translations";
import routes from "./routes";
import "./styles.css";

// commandbar init
init("06b079c5");

if (process.env.NODE_ENV !== "development") {
  Sentry.init({
    environment: process.env.NODE_ENV,
    dsn: "https://3c74adeedba147bb8148ba13021a7267@o220641.ingest.sentry.io/1427842",
  });
}

Chart.register(CategoryScale);

const locale = navigator.language.split(/[-_]/)[0];
const messages = translations[locale];

const router = createBrowserRouter(routes);

render(
  <Suspense fallback={<Loader />}>
    <GoogleOAuthProvider clientId={process.env.GOOGLE_CLIENT_ID}>
      <PostHogProvider
        apiKey={process.env.REACT_APP_PUBLIC_POSTHOG_KEY}
        options={{
          api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
        }}
      >
        <ApolloContextProvider>
          <EnvContextProvider>
            <ThemeProvider theme={theme}>
              <IntlProvider
                locale={locale}
                messages={messages}
                defaultLocale="en"
              >
                <ToastAlertContextProvider>
                  <RouterProvider
                    router={router}
                    fallbackElement={<Loader />}
                  />
                </ToastAlertContextProvider>
              </IntlProvider>
            </ThemeProvider>
          </EnvContextProvider>
        </ApolloContextProvider>
      </PostHogProvider>
    </GoogleOAuthProvider>
  </Suspense>,
  document.getElementById("container")
);
