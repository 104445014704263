import { ContentWrapper } from "components";
import React, { useEffect, useState } from "react";
import { useResolvedPath, useLocation } from "react-router-dom";

const Component = () => {
  const match = useResolvedPath("");
  const location = useLocation();
  const [src, setSrc] = useState(null);

  useEffect(() => {
    const url = match.pathname;
    const path = location.pathname;
    const subpath = path.slice(path.indexOf(url) + url.length);
    if (subpath) {
      setSrc(`https://www.memberstack.com${subpath}`);
    } else {
      setSrc("https://www.memberstack.com/webflow-components/signup");
    }
  }, [match, location.pathname]);

  return (
    <ContentWrapper>
      {src && (
        <iframe
          src={src}
          title="component page"
          width="100%"
          height="100%"
          className="rounded-none rounded-tl-xl rounded-bl-xl"
        />
      )}
    </ContentWrapper>
  );
};

export default Component;
