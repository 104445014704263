import React, { useEffect, useState, useMemo, useCallback } from "react";
import { useParams } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import InfiniteScroll from "react-infinite-scroll-component";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";

import {
  useDeletePostMutation,
  useExportPostsMutation,
  useUpdatePostMutation,
} from "features/comments/mutations.generated";
import {
  useGetPostLinkLazyQuery,
  useGetPostsQuery,
} from "features/comments/queries.generated";
import { getRelativeTimeFromNow } from "helpers/getRelativeTimeFromNow";
import { PostStatus, UpdatePostInput } from "generatedTypes";
import { getResponseError } from "helpers/getResponseError";
import Tag from "components/Tag";
import { TabItem, TabNav, Tabs } from "components/Tabs";
import { useToastAlertContext } from "contexts/ToastAlertContext";
import { CommentCard } from "components/CommentCard";
import { Dropdown } from "components";
import { useAppDataContext } from "contexts/AppDataContext";
import { LockOutlined } from "@mui/icons-material";
import { CommentsLoader, CommunityEmptyState } from "./Community.states";
import {
  STATUS_TABS,
  TabStatus,
  VALID_STATUS_VALUES,
  contentMap,
} from "./community.utils";
import ExportCommentsSuccessModal from "./ExportCommentsSuccessModal";

const Comments = ({
  posts,
  fetchMore,
  refetch,
  selectedStatus,
  loading,
  error,
}) => {
  const [hasMore, setHasMore] = useState(true);
  const [currentProcessingId, setCurrentProcessingId] = useState<string>(null);

  const { createToastAlert } = useToastAlertContext();

  const [getPostLink] = useGetPostLinkLazyQuery();

  const [updatePost, { loading: updatingPost }] = useUpdatePostMutation({
    update: (cache, { data }) => {
      if (data?.updatePost) {
        const updatedPost = data.updatePost;
        cache.modify({
          id: cache.identify({ id: updatedPost.id, __typename: "Post" }),
          fields: {
            status: () => updatedPost.status,
            content: () => updatedPost.content,
          },
        });
      }
    },
    onCompleted: () => {
      createToastAlert({
        alertType: "success",
        message: "Post updated successfully.",
      });
      // refetch();
    },
  });

  const [deletePost] = useDeletePostMutation({
    update: (cache, { data }) => {
      if (data?.deletePost) {
        const deletedPostId = data.deletePost;
        cache.evict({
          id: cache.identify({ id: deletedPostId, __typename: "Post" }),
        });
      }
    },
    onCompleted: () => {
      createToastAlert({
        alertType: "success",
        message: "Post deleted successfully.",
      });
      refetch();
    },
  });

  const { title, description } =
    contentMap[selectedStatus] || contentMap.DEFAULT_CONTENT;

  const comments = posts?.getPosts?.edges?.map((edge) => edge.node);
  const endCursor = posts?.getPosts?.pageInfo?.endCursor;

  useEffect(() => {
    setHasMore(comments?.length > 0);
  }, [comments?.length]);

  const handleUpdate = (variables: UpdatePostInput) => {
    createToastAlert({ processing: true });
    setCurrentProcessingId(variables.postId);
    updatePost({ variables: { input: variables } });
  };

  const handleDelete = (postId) => {
    createToastAlert({ processing: true });
    deletePost({ variables: { input: { postId } } });
  };

  const handleOnReplyClick = async (id) => {
    try {
      const { data } = await getPostLink({
        variables: {
          id,
        },
      });
      if (!data?.getPostLink) {
        return createToastAlert({
          alertType: "error",
          message: "Oops! We don't know what page the post is on.",
        });
      }
      window.open(data.getPostLink.url, "_blank");
    } catch (err) {
      createToastAlert({
        alertType: "error",
        message: getResponseError(err),
      });
    }
    return null;
  };

  const fetchMoreData = async () => {
    if (hasMore) {
      const { data } = await fetchMore({
        variables: {
          after: endCursor,
        },
      });

      if (
        data &&
        data.getPosts &&
        data.getPosts.edges &&
        data.getPosts.edges.length === 0
      ) {
        setHasMore(false);
      } else {
        setHasMore(true);
      }
    }
  };

  if (loading) return <CommentsLoader isCompact={false} />;
  if (error) return <div>Unable to load comments</div>;

  if (comments.length === 0)
    return <CommunityEmptyState title={title} description={description} />;

  return (
    <InfiniteScroll
      className="p-5 pb-40 gap-2 flex flex-col"
      height="100%"
      dataLength={comments.length}
      next={fetchMoreData}
      hasMore={hasMore}
      loader={<Skeleton width="100%" height={160} />}
    >
      {comments.map((post) => (
        <CommentCard
          id={post.id}
          avatarUrl={post.member.profileImage}
          key={post.id}
          comment={post.content}
          email={post.member.auth.email}
          ipAddress={post.ip}
          upVotes={post.likedByCount}
          downVotes={post.dislikedByCount}
          timestamp={getRelativeTimeFromNow(post.createdAt)}
          postStatus={post.status}
          onStatusChange={(status) => handleUpdate({ postId: post.id, status })}
          onSave={(content) => handleUpdate({ postId: post.id, content })}
          onDeletion={() => handleDelete(post.id)}
          onReplyClick={() => handleOnReplyClick(post.id)}
          loading={currentProcessingId === post.id && updatingPost}
          aiSpamLevel={post.aiSpamLevel}
          isNewPost={post.isNew}
        />
      ))}
    </InfiniteScroll>
  );
};

const CommentsPage = () => {
  const params = useParams<{ status: PostStatus }>();
  const { isSubscribed } = useAppDataContext();
  const [showModal, setShowModal] = useState(false);

  const selectedStatus = VALID_STATUS_VALUES.includes(params.status)
    ? params.status
    : undefined;

  const { loading, error, data, refetch, fetchMore } = useGetPostsQuery({
    fetchPolicy: "network-only",
    nextFetchPolicy: "cache-first",
    variables: {
      filters: {
        status: selectedStatus,
      },
    },
  });

  const [exportPosts] = useExportPostsMutation();
  const { createToastAlert } = useToastAlertContext();

  // get new comments from data
  const newComments = useMemo(
    () => data?.getPosts?.edges?.filter((edge) => edge.node.isNew)?.length,
    [data]
  );

  const handleExport = useCallback(async () => {
    try {
      const { data: exportData } = await exportPosts();
      if (exportData?.exportPosts?.jobId) {
        setShowModal(true);
        createToastAlert({
          alertType: "success",
          message: "Exporting comments...",
        });
      }
    } catch (err) {
      createToastAlert({
        alertType: "error",
        message: "Failed to export comments",
      });
    }
  }, [createToastAlert, exportPosts]);

  const options = useMemo(
    () => [
      // {
      //   text: "Import Comments CSV",
      //   id: "importCommentsCSV",
      //   disabled: !isSubscribed,
      //   tooltipText: !isSubscribed
      //     ? "A paid plan is required to export comments"
      //     : null,
      // },
      {
        text: "Export Comments CSV",
        id: "exportCommentsCSV",
        disabled: !isSubscribed,
        Icon: !isSubscribed && <LockOutlined />,
        onClick: handleExport,
        tooltipText: !isSubscribed
          ? "A paid plan is required to export comments"
          : null,
      },
    ],
    [isSubscribed, handleExport]
  );

  return (
    <>
      <div className="flex justify-between items-center p-5 border-b">
        <div className="flex items-center gap-2">
          <h2 className="text-base">Comments</h2>
          {!!newComments && (
            <Tag
              text={newComments}
              variant="fadedBlue"
              size="small"
              icon={<ArrowUpwardIcon />}
            />
          )}
        </div>
        <div className="flex gap-4 items-start">
          <Tabs
            isExpanded={false}
            showBottomBorder={false}
            onTabChange={(value: TabStatus) => {
              refetch({
                filters: {
                  // @ts-ignore
                  status: value === "all" ? undefined : value,
                },
              });
            }}
          >
            <TabNav>
              {STATUS_TABS.map((tab) => (
                <TabItem
                  key={tab.label}
                  id={tab.value}
                  label={tab.label}
                  url={tab.url}
                />
              ))}
            </TabNav>
          </Tabs>

          <Dropdown
            options={options}
            onSelect={(value: TabStatus) => undefined}
          />
        </div>
      </div>
      <Comments
        posts={data}
        loading={loading}
        error={error}
        fetchMore={fetchMore}
        refetch={refetch}
        selectedStatus={selectedStatus}
      />
      <ExportCommentsSuccessModal
        showModal={showModal}
        setShowModal={setShowModal}
      />
    </>
  );
};

export default CommentsPage;
