import React from "react";
// import { OverflowList } from "react-overflow-list";
import { FolderOutlined, InsertDriveFileOutlined } from "@mui/icons-material";
import {
  FilterType,
  PlanConnectionStatus,
  RestrictedUrl,
} from "generatedTypes";
import Tag from "components/Tag";
import PlanStatusTag from "components/PlanStatusTag";
import { Switch } from "components/Switch";
import cn from "helpers/cn";
import { CopyButton } from "../Button";

interface SwitchT {
  isChecked: boolean;
  onChange?: (isChecked: boolean) => void;
  disabled?: boolean;
  id?: string;
}
interface Props {
  onClick?: () => void;
  name: string;
  urls?: RestrictedUrl[];
  contentId: string;
  plans?: { id: string; name: string }[];
  className?: string;
  switchProps?: SwitchT;
  dataCy?: string;
  selected?: boolean;
}

const filterTypes = {
  [FilterType.Starts]: "Starting with",
  [FilterType.Equals]: "Equal to",
};

export const GatedContentCard = ({
  onClick,
  name,
  contentId,
  urls = [],
  plans = [],
  switchProps = null,
  className,
  dataCy,
  selected,
}: Props) => {
  const handleKeyDown = (event) => {
    if (event.key === "Enter" || event.key === " ") {
      event.preventDefault();
      event.stopPropagation();
      onClick();
    }
  };
  return (
    <div
      onClick={onClick}
      role="button"
      tabIndex={0}
      onKeyDown={(e) => handleKeyDown(e)}
      className={cn(
        className,
        "cursor-pointer p-3 rounded-lg hover:bg-[#f8f8f8b4] border shadow-sm",
        `${selected ? "border-[#2962FF]" : "border-[#eebf8154]"}`
      )}
      data-testid="gatedContentContainer"
      data-cy={dataCy}
    >
      <div tw="flex justify-between items-center">
        <div className="flex flex-wrap items-center justify-between w-full">
          {switchProps ? (
            <div className="flex items-center">
              <Switch
                isChecked={switchProps.isChecked}
                onChange={switchProps.onChange}
                disabled={switchProps.disabled}
                id={switchProps.id}
                tw="mr-3"
              />
              <h2 className="text-base font-bold">{name}</h2>
            </div>
          ) : (
            <h2 tw="text-base font-bold">{name}</h2>
          )}

          <CopyButton
            value={contentId}
            text={contentId}
            message="ID was successfully copied"
            inverted
            tw="font-medium! text-white! items-center h-[32px]"
          />
        </div>
      </div>
      {plans?.length > 0 && (
        <div className="flex flex-wrap justify-start items-center gap-1 mt-3">
          {plans.map((plan) => (
            <PlanStatusTag
              planName={plan.name}
              key={plan.id}
              status={PlanConnectionStatus.Trialing}
              showTooltip={false}
            />
          ))}
        </div>
      )}
      {urls?.length > 0 && (
        <div className="flex flex-wrap items-center content-between gap-x-1 gap-y-3 mt-3">
          {urls.map((url) => (
            <Tag
              key={url.id}
              size="medium"
              text={`${filterTypes[url.filter]} ${url.url}`}
              variant="tan"
              icon={
                url.filter === "EQUALS" ? (
                  <FolderOutlined className="h-4! w-4! fill-[#b47118]" />
                ) : (
                  <InsertDriveFileOutlined className="h-4! w-4! fill-[#b4711]" />
                )
              }
            />
          ))}
        </div>
      )}
    </div>
  );
};
