import React, { useEffect, useState } from "react";
import { Link, LogicSelector } from "components";
import { useUpdatePlanLogicMutation } from "features/plans";
import { Plan, PriceType } from "generatedTypes";
import Tag from "components/Tag";
import useSubmit from "hooks/useSubmit";
import { isObjectEqual } from "helpers/isObjectEqual";

import {
  formatLogicValues,
  getPlanLogicOptions,
  getPlanLogicValues,
} from "containers/Plans/utils";
import { usePlansContext } from "containers/Plans/context/Plans.context";
import { useUnsavedChangesContext } from "contexts/UnsavedChangesContext";
import { PlanAccordionItem } from "./PlanAccordionItem";

interface PlanLogicProps {
  plan: Plan;
  refetch: () => void;
}

export const PlanLogic = ({ plan, refetch }: PlanLogicProps) => {
  const { logic: logicExt, isPaid, id } = plan;
  const { freePlans, allPrices } = usePlansContext();
  const { setHasUnsavedChanges, hasUnsavedChanges } =
    useUnsavedChangesContext();

  const [addedLogicExpanded, setAddedLogicExpanded] = useState(false);
  const [removedLogicExpanded, setRemovedLogicExpanded] = useState(false);

  const [logic, setLogic] = useState(logicExt);

  useEffect(() => {
    setLogic(logicExt);
  }, [logicExt]);

  const [updatePlanLogic, { loading: updatingPlanLogic }] =
    useUpdatePlanLogicMutation();

  const { submit: handleUpdatePlanLogic } = useSubmit({
    action: updatePlanLogic,
    fields: {
      planId: plan?.id,
      ...{
        addedLogic: formatLogicValues(logic.addedLogic),
        removedLogic: formatLogicValues(logic.removedLogic),
      },
    },
    success: "Logic was successfully updated.",
    refetch,
  });

  const recurringPrices = allPrices.filter(
    (prices) => prices.type !== PriceType.Onetime
  );

  const otherFreePlans = freePlans.filter((_plan) => _plan.value !== id);

  const onSelectChange = (logicType, type) => (selected) => {
    const value = { [type]: selected[type].map((v) => v.value) };
    setLogic({ ...logic, [logicType]: { ...logic[logicType], ...value } });
  };

  const onToggleSwitch = (logicType, type) => (selected) => {
    const value = { [type]: selected };
    setLogic({ ...logic, [logicType]: { ...logic[logicType], ...value } });
  };

  const addedLogic = getPlanLogicValues(
    logic,
    otherFreePlans,
    recurringPrices,
    "addedLogic"
  );
  const removedLogic = getPlanLogicValues(
    logic,
    otherFreePlans,
    recurringPrices,
    "removedLogic"
  );

  const addedLogicOption = getPlanLogicOptions(
    logic,
    otherFreePlans,
    "addedLogic"
  );
  const removedLogicOption = getPlanLogicOptions(
    logic,
    otherFreePlans,
    "removedLogic"
  );

  useEffect(() => {
    if (isObjectEqual(logicExt || {}, logic)) {
      setHasUnsavedChanges(false);
    } else {
      setHasUnsavedChanges(true);
    }
  }, [logic, logicExt]);

  useEffect(() => {
    console.log(logic);
  }, [logic]);

  return (
    <PlanAccordionItem
      label="Plan Logic"
      value="planLogic"
      onSave={() => handleUpdatePlanLogic()}
      isSaveDisabled={!hasUnsavedChanges}
      buttonLoading={updatingPlanLogic}
      onReset={() => setLogic(logicExt)}
    >
      <div>
        <p className="text-body-sm text-app-gray600 mb-4">
          Set what happens when this plan is purchased or cancelled, e.g. if
          this plan is purchased then remove all of the member&lsquo;s free
          plans.{" "}
          <Link
            to="https://docs.memberstack.com/hc/en-us/articles/14084137149339-Plan-Logic"
            target="_blank"
            isExternal
            underline
          >
            Help Docs →
          </Link>
        </p>
        <div tw="flex gap-2 flex-col">
          <div tw="flex rounded-lg cursor-default shadow-lg border p-3 flex-wrap flex-col gap-2">
            <p tw="text-body-sm text-app-gray600 mr-2">
              When plan is{" "}
              <Tag
                text={isPaid ? "purchased" : "added"}
                variant="success"
                isSolid
                className="ml-1 font-bold"
              />{" "}
              then{" "}
              {addedLogic.remove.length === 0 &&
                addedLogic.add.length === 0 &&
                addedLogic.cancelRecurring.length === 0 && (
                  <button
                    type="button"
                    className="bg-app-gray100 text-app-gray600 py-1 px-2 rounded-md text-sm leading-4"
                    onClick={() => setAddedLogicExpanded(true)}
                  >
                    +
                  </button>
                )}
            </p>
            {(addedLogic.remove.length > 0 ||
              addedLogic.add.length > 0 ||
              addedLogic.cancelRecurring.length > 0 ||
              addedLogicExpanded) && (
              <>
                <LogicSelector
                  id="addedLogic_remove"
                  type="remove"
                  planPlaceholder="select free plans"
                  planOptions={addedLogicOption.remove}
                  onChange={onSelectChange("addedLogic", "remove")}
                  value={
                    logic.addedLogic.removeAllFree ? [] : addedLogic.remove
                  }
                  isDisabled={logic.addedLogic.removeAllFree}
                  isChecked={logic.addedLogic.removeAllFree}
                  onToggleSwitch={onToggleSwitch("addedLogic", "removeAllFree")}
                  switchLabel="Remove all free plans"
                />
                <LogicSelector
                  id="addedLogic_add"
                  type="add"
                  planPlaceholder="select free plans"
                  planOptions={addedLogicOption.add}
                  onChange={onSelectChange("addedLogic", "add")}
                  value={addedLogic.add}
                />
                <LogicSelector
                  id="addedLogic_cancel"
                  type="cancelRecurring"
                  planOptions={recurringPrices}
                  onChange={onSelectChange("addedLogic", "cancelRecurring")}
                  value={
                    logic.addedLogic.cancelAllRecurring
                      ? []
                      : addedLogic.cancelRecurring
                  }
                  planPlaceholder="select paid plan prices"
                  isDisabled={logic.addedLogic.cancelAllRecurring}
                  isChecked={logic.addedLogic.cancelAllRecurring}
                  onToggleSwitch={onToggleSwitch(
                    "addedLogic",
                    "cancelAllRecurring"
                  )}
                  switchLabel="Cancel all recurring plans"
                />
              </>
            )}
          </div>
          <div tw="flex rounded-lg cursor-default shadow-lg border p-3 flex-wrap flex-col gap-2">
            <p className="text-body-sm text-app-gray600">
              When plan is{" "}
              <Tag
                text={isPaid ? "cancelled" : "removed"}
                variant="secondary"
                isSolid
                className="ml-1 font-bold"
              />{" "}
              then{" "}
              {removedLogic.remove.length === 0 &&
                removedLogic.add.length === 0 &&
                removedLogic.cancelRecurring.length === 0 && (
                  <button
                    type="button"
                    className="bg-app-gray100 text-app-gray600 py-1 px-2 rounded-md text-sm leading-4"
                    onClick={() => setRemovedLogicExpanded(true)}
                  >
                    +
                  </button>
                )}
            </p>
            {(removedLogic.remove.length > 0 ||
              removedLogic.add.length > 0 ||
              removedLogic.cancelRecurring.length > 0 ||
              removedLogicExpanded) && (
              <>
                <LogicSelector
                  id="removedLogic_remove"
                  type="remove"
                  planPlaceholder="select plans"
                  planOptions={removedLogicOption.add}
                  onChange={onSelectChange("removedLogic", "remove")}
                  value={
                    logic.removedLogic.removeAllFree ? [] : removedLogic.remove
                  }
                  isDisabled={logic.removedLogic.removeAllFree}
                  isChecked={logic.removedLogic.removeAllFree}
                  onToggleSwitch={onToggleSwitch(
                    "removedLogic",
                    "removeAllFree"
                  )}
                  switchLabel="Remove all free plans"
                />
                <LogicSelector
                  id="removedLogic_add"
                  type="add"
                  planPlaceholder="select plans"
                  planOptions={removedLogicOption.remove}
                  onChange={onSelectChange("removedLogic", "add")}
                  value={removedLogic.add}
                />
                <LogicSelector
                  id="removedLogic_cancel"
                  type="cancelRecurring"
                  planOptions={recurringPrices}
                  onChange={onSelectChange("removedLogic", "cancelRecurring")}
                  value={
                    logic.removedLogic.cancelAllRecurring
                      ? []
                      : removedLogic.cancelRecurring
                  }
                  planPlaceholder="select prices"
                  isDisabled={logic.removedLogic.cancelAllRecurring}
                  isChecked={logic.removedLogic.cancelAllRecurring}
                  onToggleSwitch={onToggleSwitch(
                    "removedLogic",
                    "cancelAllRecurring"
                  )}
                  switchLabel="Cancel all recurring plans"
                />
              </>
            )}
          </div>
        </div>
      </div>
    </PlanAccordionItem>
  );
};
