import React, { useMemo } from "react";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";

import { EnvSwitcher, MemberLimitBox } from "components";
import { formatPluralizedString } from "helpers/formatPluralizedString";
import { useAppDataContext } from "contexts/AppDataContext";
import { useEnvContext } from "contexts/EnvironmentContext";
import Tag from "components/Tag";
import AddMember from "../AddMemberDropdown";
import CustomFieldsDropdown from "../CustomFieldsDropdown";
import MembersPageDropdown from "../Dropdown";

const TEST_MODE_MEMBER_LIMIT = 50;

const Header = ({ totalMembers, planIds, status, noOfNewMembers }) => {
  const { appData } = useAppDataContext();
  const { isSandboxEnv } = useEnvContext();

  const membersCount = useMemo(
    () => formatPluralizedString(totalMembers, "Member"),
    [totalMembers]
  );

  const isMemberLimitReached = useMemo(
    () => totalMembers >= TEST_MODE_MEMBER_LIMIT,
    [totalMembers]
  );

  return (
    <div className="flex items-center flex-row justify-between mx-5 mt-5 mb-4">
      <div className="flex items-center gap-2">
        <h3 className="text-base font-bold overflow-ellipsis whitespace-nowrap overflow-hidden">
          {membersCount}
        </h3>
        {!!noOfNewMembers && (
          <Tag
            text={noOfNewMembers}
            variant="fadedBlue"
            size="small"
            icon={<ArrowUpwardIcon />}
          />
        )}
      </div>
      <div className="flex items-center gap-2">
        {isSandboxEnv && appData?.testModeMemberLimitEnabled && (
          <MemberLimitBox
            memberCountLimit={TEST_MODE_MEMBER_LIMIT}
            totalMemberCount={totalMembers}
            text="Test Members"
            interactionType="hover"
          />
        )}
        <EnvSwitcher responsive />
        <CustomFieldsDropdown />
        <AddMember isMemberLimitReached={isMemberLimitReached} />
        <MembersPageDropdown planIds={planIds} status={status} />
      </div>
    </div>
  );
};

export default Header;
