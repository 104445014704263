import React, { useMemo } from "react";
import Input from "components/Input";
import PilledTabs from "components/PilledTabs";
import PriceInput from "components/PriceInput";
import { PriceType } from "generatedTypes";
import { InputGroup, Link } from "components";
import Select from "components/Select";
import { Warning } from "@mui/icons-material";
import { currencies } from "constants/currencies";
import mergeClassNames from "helpers/mergeClassNames/mergeClassNames";

const frequencyOptions = [
  { label: "weeks", value: "WEEKLY" },
  { label: "months", value: "MONTHLY" },
  { label: "years", value: "YEARLY" },
];

export const PriceInformation = ({
  setPriceInputs,
  priceInputs,
  disabled,
  hasTaxValue,
  name,
  type,
  taxType,
  amount,
  currency,
  intervalCount,
  intervalType,
  maxTeamMembers,
  teamAccountsEnabled,
}) => {
  const handleOnChange = ({ target: { name: fieldName, value } }) => {
    setPriceInputs((prev) => ({
      ...prev,
      [fieldName]:
        fieldName === "intervalCount" || fieldName === "maxTeamMembers"
          ? parseInt(value, 10)
          : value,
    }));
  };

  const planCurrency = currencies.find(
    (_currency) => _currency.label === currency
  );
  const planPrice = `${planCurrency.symbol} ${amount}`;

  const taxOptions = useMemo(
    () => [
      { label: "Select an option", value: "" },
      { label: `Yes (Final price is ${planPrice})`, value: "INCLUSIVE" },
      {
        label: `No (Final price is ${planPrice} plus tax)`,
        value: "EXCLUSIVE",
      },
    ],
    [planPrice]
  );

  const priceTaxOption = useMemo(
    () => taxOptions.find((option) => option.value === taxType),
    [taxOptions, taxType]
  );

  const handleTaxOptionChange = (selected) => {
    setPriceInputs({ ...priceInputs, taxType: selected.value });
  };

  const frequencyOption = useMemo(
    () => frequencyOptions.find((option) => option.value === intervalType),
    [intervalType]
  );

  const renderPriceInput = useMemo(
    () => (
      <PriceInput
        label="Amount"
        name="amount"
        inputValue={amount}
        initialCurrency={currency}
        disabled={disabled}
        placeholder="Enter a price"
        onChange={({ value }) =>
          setPriceInputs({ ...priceInputs, amount: value })
        }
        onCurrencyChange={(_currency) =>
          setPriceInputs({ ...priceInputs, currency: _currency })
        }
      />
    ),
    [amount, currency, disabled, setPriceInputs, priceInputs]
  );

  const renderFrequencyInput = useMemo(() => {
    const handleFrequencyOptionChange = (selected) => {
      // if intervalType is equal to yearly, the intervalCount cannot be greater than 1 or less than 1
      if (selected.value === "YEARLY") {
        return setPriceInputs({
          ...priceInputs,
          intervalType: selected.value,
          intervalCount: 1,
        });
      }
      return setPriceInputs({
        ...priceInputs,
        intervalType: selected.value,
      });
    };

    const handleFrequencyInputChange = ({ target: { value } }) => {
      // if intervalType is equal to yearly, the intervalCount cannot be greater than 1 or less than 1
      if (intervalType === "YEARLY") {
        setPriceInputs({
          ...priceInputs,
          intervalCount: 1,
        });
      }
      setPriceInputs({
        ...priceInputs,
        intervalCount: parseInt(value, 10),
      });
    };

    return (
      <Input
        required
        type="number"
        placeholder="Enter a frequency"
        value={intervalCount}
        name="intervalCount"
        onChange={(e) => handleFrequencyInputChange(e)}
        leading="Every"
        trailing={
          <div tw="flex">
            <Select
              label=""
              value={frequencyOption}
              onChange={(selected) => handleFrequencyOptionChange(selected)}
              options={frequencyOptions}
              inline
              isDisabled={disabled}
            />
          </div>
        }
        trailingInline
        hideIndicators
        disabled={disabled || intervalType === "YEARLY"}
      />
    );
  }, [
    intervalCount,
    frequencyOption,
    disabled,
    intervalType,
    setPriceInputs,
    priceInputs,
  ]);

  return (
    <>
      <div className="flex flex-col gap-5 p-5">
        <Input
          label="Name"
          name="name"
          placeholder="Pro Monthly"
          description="Internal use only. Used for attributes and APIs."
          required
          value={name}
          onChange={(e) => handleOnChange(e)}
          maxLength={100}
        />
        <>
          <PilledTabs
            label="Type"
            disabled={disabled}
            tabs={[
              { label: "Recurring", value: PriceType.Subscription },
              { label: "One-time", value: PriceType.Onetime },
            ]}
            active={type}
            onChange={(value) =>
              setPriceInputs({ ...priceInputs, type: value })
            }
          />
          {type === PriceType.Subscription ? (
            <>
              <InputGroup
                items={[[renderPriceInput], [renderFrequencyInput]]}
                disabled={disabled}
              />
              {intervalType === "YEARLY" && (
                <p className="text-utility-tag-md text-app-gray600 mt-1">
                  It’s currently not possible to set the interval to more than
                  every 1 year. &nbsp;
                  <Link
                    isExternal
                    target="_blank"
                    underline
                    to="https://docs.memberstack.com/hc/en-us/community/posts/12656709015323--Wishlist-Interval-for-yearly-plans-is-currently-not-greater-than-1-year"
                  >
                    Learn more
                  </Link>
                </p>
              )}
            </>
          ) : (
            renderPriceInput
          )}
        </>
        {teamAccountsEnabled === true && (
          <Input
            label="Maximum number of seats"
            name="maxTeamMembers"
            type="number"
            placeholder="0"
            hideIndicators
            value={maxTeamMembers}
            onChange={(e) => handleOnChange(e)}
            disabled={disabled}
          />
        )}
        {disabled && (
          <div className={mergeClassNames("pt-5", hasTaxValue && "opacity-50")}>
            <Select
              label="Include tax in price"
              options={taxOptions}
              placeholder="Select an option"
              onChange={handleTaxOptionChange}
              value={priceTaxOption}
              isDisabled={hasTaxValue}
              preselectValue={false}
            />
            {/* TODO: Make this its own component, maybe Warn component */}
            {!hasTaxValue && (
              <div className="flex gap-1 mt-1">
                <Warning
                  className="warning"
                  style={{
                    width: "16px",
                    height: "16px",
                    fill: "#E28C0A",
                    margin: "4px",
                  }}
                />
                <p className="text-body-sm">
                  Your selection will update all subscriptions with this price.{" "}
                  <Link
                    target="_blank"
                    underline
                    isBold
                    isExternal
                    to="https://stripe.com/docs/billing/taxes/tax-rates#inclusive-vs-exclusive-tax"
                  >
                    Learn More
                  </Link>
                </p>
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};
