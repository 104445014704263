import React, { useCallback, useState } from "react";
import mergeClassNames from "helpers/mergeClassNames/mergeClassNames";
import { removeItem } from "helpers/string-manipulations";
import cn from "helpers/cn";
import { Button } from "../Button";
import { CheckboxListProps } from "./Checkbox.types";
import { Checkbox } from "./Checkbox";

export const CheckboxList = ({
  className,
  checkboxes,
  showDivider,
  type = "rounded",
  enableSelectAll = false,
  onCheck,
  checkItems = [],
  emptyOption,
}: CheckboxListProps) => {
  const [checkedItems, setCheckedItems] = useState<string[]>(checkItems);
  const [isCheckAll, setIsCheckAll] = useState<boolean>(false);
  const [isDisabledAll, setIsDisabledAll] = useState<boolean>(false);

  const handleCheck = useCallback(
    (event) => {
      const { checked, id } = event.target;
      if (!checked) {
        const newCleansedItems = removeItem(id, checkedItems);
        setCheckedItems(newCleansedItems);
        return onCheck && onCheck(newCleansedItems);
      }
      setCheckedItems([...checkedItems, id]);
      return onCheck && onCheck([...checkedItems, id]);
    },
    [checkedItems, onCheck]
  );

  const handleSelectAll = useCallback(() => {
    if (isDisabledAll) return;
    setIsCheckAll(!isCheckAll);
    if (isCheckAll) {
      setCheckedItems([]);
      if (onCheck) onCheck([]);
      return;
    }
    setCheckedItems(checkboxes.map((checkbox) => checkbox.id));
    if (onCheck) onCheck(checkboxes.map((checkbox) => checkbox.id));
  }, [checkboxes, isCheckAll, onCheck]);

  const handleEmptyOptionCheck = useCallback(
    (event) => {
      if (event.target.checked) setCheckedItems([]);
      setIsDisabledAll(event.target.checked);
      return event.target.checked
        ? onCheck([], { noPlans: true })
        : onCheck([]);
    },
    [emptyOption, isCheckAll, isDisabledAll, onCheck]
  );

  return (
    <div className={mergeClassNames("flex flex-col justify-center", className)}>
      {checkboxes.map(({ label, id, disabled, readOnly, name }, idx) => (
        <React.Fragment key={id}>
          <Checkbox
            checked={checkedItems.includes(id)}
            label={label}
            name={name}
            id={id}
            onChange={handleCheck}
            disabled={disabled || isDisabledAll}
            value={id}
            readOnly={readOnly}
            type={type}
            className="py-2 px-3"
          />
          {showDivider && idx !== checkboxes.length - 1 && (
            <div className="h-px bg-app-gray200" />
          )}
        </React.Fragment>
      ))}
      {enableSelectAll && (
        <Button
          text={isCheckAll ? "↑ Deselect all" : "↑ Select all"}
          buttonStyle="transparent"
          tw="w-full justify-start px-3 py-2"
          className={cn({
            "opacity-50": isDisabledAll,
          })}
          onClick={handleSelectAll}
        />
      )}
      {emptyOption && (
        <div className="px-3 py-2">
          <Checkbox label={emptyOption} onChange={handleEmptyOptionCheck} />
        </div>
      )}
    </div>
  );
};
