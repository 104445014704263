import { Button } from "components";
import { RadioButton } from "components/RadioButton";
import React from "react";

const admins = [
  {
    name: "Duncan Hamra",
    value: "all-message",
  },
  {
    name: "Tyler Bell",
    value: "all-message",
  },
  {
    name: "Josh Lopez",
    value: "all-message",
  },
];

const members = [
  {
    name: "Member",
    value: "all-message",
  },
  {
    name: "Moderator",
    value: "all-message",
  },
];

export const Notifications = () => {
  return (
    <div className="w-full flex flex-col">
      <div className="flex justify-between items-center p-5 border-b">
        <h4 className="text-base">Notifications</h4>
        <Button text="Save" />
      </div>
      <div className="p-7 border-b">
        <h3 className="text-h3 font-bold mb-2">Admin Notifications</h3>
        <p className="text-body-sm text-app-gray600 mb-7">
          Choose if and when admin should receive content notifications.
        </p>
        <table className="">
          <thead>
            <tr>
              <th className="text-body-sm font-bold py-2 px-4" align="left">
                Admin
              </th>
              <th className="text-body-sm font-bold py-2 px-4" align="left">
                All Messages
              </th>
              <th className="text-body-sm font-bold py-2 px-4" align="left">
                Requires Moderation
              </th>
              <th className="text-body-sm font-bold py-2 px-4" align="left">
                Nothing
              </th>
            </tr>
          </thead>
          <tbody>
            {admins.map(({ name, value }) => (
              <tr key={name} className="border-b">
                <td className="text-body-sm p-4" align="left">
                  {name}
                </td>
                <td align="center">
                  <RadioButton
                    name={name}
                    value="all-message"
                    selectedValue={value}
                  />
                </td>
                <td align="center">
                  <RadioButton
                    name={name}
                    value="require-moderation"
                    selectedValue={value}
                  />
                </td>
                <td align="center">
                  <RadioButton
                    name={name}
                    value="nothing"
                    selectedValue={value}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="p-7">
        <h3 className="text-h3 font-bold mb-2">Member Notifications</h3>
        <p className="text-body-sm text-app-gray600 mb-7">
          Choose if and when members should receive content notifications.
        </p>
        <table className="">
          <thead>
            <tr>
              <th className="text-body-sm font-bold py-2 px-4" align="left">
                Admin
              </th>
              <th className="text-body-sm font-bold py-2 px-4" align="left">
                All Messages
              </th>
              <th className="text-body-sm font-bold py-2 px-4" align="left">
                Requires Moderation
              </th>
              <th className="text-body-sm font-bold py-2 px-4" align="left">
                Nothing
              </th>
            </tr>
          </thead>
          <tbody>
            {members.map(({ name, value }) => (
              <tr key={name} className="border-b">
                <td className="text-body-sm p-4" align="left">
                  {name}
                </td>
                <td align="center">
                  <RadioButton
                    name={name}
                    value="all-message"
                    selectedValue={value}
                  />
                </td>
                <td align="center">
                  <RadioButton
                    name={name}
                    value="require-moderation"
                    selectedValue={value}
                  />
                </td>
                <td align="center">
                  <RadioButton
                    name={name}
                    value="nothing"
                    selectedValue={value}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};
