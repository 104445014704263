import React, { useEffect, useState, useRef, useReducer, useMemo } from "react";
import useGenerateComponentID from "hooks/useComponentId";
import {
  ErrorOutline,
  CheckCircleOutline,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import cn from "helpers/cn";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { Label } from "../Label/Label";
import { CopyButton } from "../Button/CopyButton";

export interface InputProps {
  id?: string;
  /**
   * Minimum for range of numeric input
   */
  min?: string;
  /**
   * Maximum for range of numeric input
   */
  max?: string;
  /**
   * The type of input (this component implements textual types)
   */
  type?: string;
  /**
   * The step of numeric increment/decrement permitted
   */
  step?: string;
  /**
   * DOM name for the element
   */
  name?: string;
  /**
   * Value
   */
  value?: string | number | readonly string[];
  /**
   * Label to appear above the input
   */
  label?: string;
  /**
   *
   */
  title?: string;
  /**
   * Regex pattern for input validation
   */
  pattern?: string;
  /**
   * Prevent modification and grey the input
   */
  disabled?: boolean;
  /**
   * Require the component in validation
   */
  required?: boolean;
  /**
   * Similar to disabled but without the styling change
   */
  readOnly?: boolean;
  /**
   * Ghost text to appear before any value is added
   */
  placeholder?: string;
  /**
   * onChange callback
   */
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  /**
   * onClick callback, useful for controlling click events in higher components
   */
  onClick?: (event: React.MouseEventHandler<HTMLInputElement>) => void;
  /**
   * Renders a copy button at the end of the input. This is overridden by leading elements.
   */
  allowCopy?: boolean;
  /**
   * Minimum length for text inputs
   */
  minLength?: number;
  /**
   * Maximum length for text inputs
   */
  maxLength?: number;
  /**
   * Hide stylized indicators like the 'Optional' tag and error symbol regardless of state
   */
  hideIndicators?: boolean;
  /**
   * Hide label
   */
  hideLabel?: boolean;
  /**
   * Hint text to appear in the top-right corner, above the input. Replaces "Optional".
   */
  hintText?: string | React.ReactNode;
  /**
   * Text to appear as a hover info hint.
   */
  infoHintText?: string;
  /**
   * Text or element to appear in front of the input
   */
  leading?: React.ReactNode | string;
  /**
   * Toggle the visual demarcation for the leading element
   */
  leadingInline?: boolean;
  /**
   * Text or element to appear at the end of the input. Superseded in password mode.
   */
  trailing?: React.ReactNode | string;
  /**
   * Toggle the visual demarcation for the trailing element
   */
  trailingInline?: boolean;
  /**
   * Toggle preset padding on leading & trailing elements
   */
  disableLeadingPadding?: boolean;
  disableTrailingPadding?: boolean;

  /**
   * Override padding on actual input element
   */
  inputPadding?: {
    left?: string;
    right?: string;
    top?: string;
    bottom?: string;
  };
  /*
   * Error message to be displayed
   */
  errorMessage?: string;
  /**
   * Success message to be displayed
   */
  successMessage?: string;
  /**
   * specifies whether or not an input field should have autocomplete enabled.
   */
  autocomplete?: string;
  className?: string;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  inverted?: boolean;
  dataCy?: string;
  subLabel?: string;
  description?: React.ReactNode;
  inputWidth?: number;
  hintLabel?: React.ReactNode;
}

const Input = (props: InputProps) => {
  const {
    id,
    min,
    max,
    type,
    step,
    name,
    label,
    hideLabel,
    value,
    title,
    pattern,
    disabled,
    readOnly,
    required,
    allowCopy,
    minLength,
    maxLength,
    placeholder,
    onChange,
    onClick,
    onBlur,
    leading: leadingElement,
    leadingInline,
    trailing,
    trailingInline,
    hideIndicators,
    hintText = undefined,
    hintLabel = undefined,
    infoHintText,
    inputPadding,
    disableLeadingPadding,
    errorMessage,
    successMessage,
    autocomplete = "off",
    className,
    inverted,
    dataCy,
    subLabel,
    description,
    inputWidth,
    disableTrailingPadding = false,
  } = props;
  const [showPassword, setShowPassword] = useState(false);
  const [inputState, dispatchInputState] = useReducer(
    (state, action) => {
      const newState = { ...state, ...action };
      if (newState.firstInput && newState.firstBlur) {
        newState.message = newState.text;
      }
      return newState;
    },
    {
      message: "",
      text: "",
      firstInput: false,
      firstBlur: false,
      isFocused: false,
    }
  );
  const inputId = useGenerateComponentID();
  const inputEl = useRef<HTMLInputElement>();

  const handleOnClick = (e) => onClick && onClick(e);

  const typeOverride = useMemo(() => {
    if (type === "password" && showPassword) {
      return "text";
    }

    return type;
  }, [type, showPassword]);

  const renderLabelText = useMemo(
    () =>
      label && (
        <Label
          htmlFor={inputId}
          text={label}
          hint={infoHintText}
          className={hideLabel && "sr-only"}
        />
      ),
    [hideLabel, infoHintText, inputId, label]
  );

  const renderCornerHint = useMemo(() => {
    const optional =
      !hideIndicators && !required && !readOnly && !disabled && "Optional";

    if (hintLabel) {
      return <div className="text-app-gray600 text-body-sm">{hintLabel}</div>;
    }

    if (hintText)
      return (
        <div
          className={cn(
            "text-app-gray600 text-body-sm transition-all h-full",
            inputState.isFocused
              ? "translate-y-0 h-full"
              : "-translate-y-[5px] h-0"
          )}
        >
          {hintText}
        </div>
      );

    if (optional) {
      return (
        <div className="text-app-gray600 text-body-sm transition-all h-full">
          {optional}
        </div>
      );
    }
    return null;
  }, [
    hideIndicators,
    required,
    readOnly,
    disabled,
    hintLabel,
    hintText,
    inputState.isFocused,
  ]);

  const renderText = useMemo(() => {
    if (inputState.message || errorMessage) {
      return (
        <div className="flex gap-2 items-center text-body-sm text-primary-red font-medium">
          <ErrorOutline tw="w-4! h-4!" />
          <p>{inputState.message || errorMessage}</p>
        </div>
      );
    }
    if (successMessage) {
      return (
        <div className="flex gap-2 items-center mt-2 text-body-sm text-app-green700 font-medium">
          <CheckCircleOutline tw="w-4! h-4!" />
          <p>{successMessage}</p>
        </div>
      );
    }
    return null;
  }, [inputState.message, errorMessage, successMessage]);

  const trailingElement = useMemo(() => {
    const renderPasswordToggle = () => {
      const handlePasswordToggle = () => {
        setShowPassword(!showPassword);
      };
      const ButtonIcon = showPassword ? (
        <VisibilityOff style={{ fontSize: "16px", color: "black" }} />
      ) : (
        <Visibility style={{ fontSize: "16px", color: "black" }} />
      );

      return (
        <div
          className="bg-transparent border-0 pointer-events-auto cursor-pointer text-app-gray3"
          onClick={handlePasswordToggle}
          onKeyDown={() => {}}
          role="button"
          tabIndex={0}
        >
          <div className="flex items-center justify-center">{ButtonIcon}</div>
        </div>
      );
    };

    const renderErrorIcon = () =>
      !hideIndicators && inputState.message ? (
        <ErrorOutlineIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
      ) : null;

    const renderCopy = () => {
      return (
        <div className="bg-transparent border-0 pointer-events-auto cursor-pointer text-app-gray3">
          <div className="flex items-center justify-center">
            <CopyButton
              value={value?.toString()}
              noPadding
              inverted={inverted}
            />
          </div>
        </div>
      );
    };

    if (type === "password") {
      return renderPasswordToggle();
    }

    if (inputState.message) {
      return renderErrorIcon();
    }

    if (!trailing && allowCopy) {
      return renderCopy();
    }
    return trailing;
  }, [
    allowCopy,
    inputState.message,
    hideIndicators,
    showPassword,
    trailing,
    type,
    value,
    inverted,
  ]);

  const renderLeading = useMemo(() => {
    const renderLeadingOutline = (el) => (
      <div
        className={cn(
          "inline-flex items-center rounded-l-md rounded-r-none! border-r-0 border-app-gray200 bg-app-gray50 border-0 text-body-sm",
          disableLeadingPadding ? "" : "px-3 py-2"
        )}
      >
        {el}
      </div>
    );
    const renderLeadingInline = (el) => (
      <div
        className={cn(
          "leadingInline inline-flex items-center bg-white rounded-l-md border-0",
          disableLeadingPadding ? "p-0" : "py-2 pl-3",
          {
            "border-red-300": !!inputState.message,
          }
        )}
      >
        <div className="flex text-app-gray500 text-body-sm">{el}</div>
      </div>
    );
    if (leadingElement) {
      return leadingInline
        ? renderLeadingInline(leadingElement)
        : renderLeadingOutline(leadingElement);
    }

    return null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [leadingElement, inputState.message, leadingInline]);

  const renderTrailing = useMemo(() => {
    const renderTrailingOutline = (el) => (
      <div
        className={cn(
          "flex items-center",
          disableTrailingPadding ? "p-0" : "py-1 pr-1"
        )}
      >
        {el}
      </div>
    );
    const renderTrailingInline = (el) => (
      <div
        className={cn(
          "flex items-center justify-center bg-white rounded-br-md rounded-tr-md border-width-0 pr-3",
          disableTrailingPadding ? "p-0" : "pr-3",
          {
            "border-red-300": !!inputState.message,
          }
        )}
      >
        <span className="flex text-app-gray500 text-body-sm">{el}</span>
      </div>
    );

    if (trailingElement) {
      return trailingInline
        ? renderTrailingInline(trailingElement)
        : renderTrailingOutline(trailingElement);
    }

    return null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trailingElement, trailingInline, inputState.message]);

  // set focused state on input
  useEffect(() => {
    const handleFocus = () => {
      dispatchInputState({ isFocused: true, firstBlur: false });
    };
    const handleBlur = () => {
      dispatchInputState({ firstBlur: true, isFocused: false });
    };

    if (inputEl?.current) {
      inputEl.current.addEventListener("focus", handleFocus);
      inputEl.current.addEventListener("blur", handleBlur);
    }

    return () => {
      if (inputEl?.current) {
        inputEl.current.removeEventListener("focus", handleFocus);
        inputEl.current.removeEventListener("blur", handleBlur);
      }
    };
  }, []);

  return (
    <div className={className}>
      {renderLabelText && (
        <div className="flex justify-between items-center mb-1">
          {renderLabelText}
          {renderCornerHint}
        </div>
      )}
      {subLabel && (
        <p tw="text-utility-md text-app-gray600 mb-2 cursor-default">
          {subLabel}
        </p>
      )}
      <div
        style={{
          width: inputWidth || "auto",
        }}
        className={cn(
          "flex relative border border-app-gray200 bg-white rounded-md focus-within:outline focus-within:outline-app-blue500 focus-within:border-transparent",
          inverted && "rounded-sm border-[#212121] bg-[#2b2b2b]",
          // Error state
          (!!inputState.message || Boolean(errorMessage)) &&
            "outline border-transparent outline-error-fill text-error-fill focus-within:outline-error-fill",
          // success state
          Boolean(successMessage) &&
            "outline border-transparent outline-app-green700"
        )}
      >
        {renderLeading}
        <input
          type={typeOverride}
          id={id || inputId}
          aria-label={label}
          name={name}
          min={min}
          max={max}
          step={step}
          value={value}
          title={title}
          ref={inputEl}
          pattern={pattern}
          disabled={disabled}
          readOnly={readOnly}
          required={required}
          placeholder={placeholder}
          onChange={onChange}
          onClick={handleOnClick}
          minLength={minLength}
          maxLength={maxLength}
          onBlur={onBlur}
          onInput={() => dispatchInputState({ firstInput: true })}
          autoComplete={autocomplete}
          onWheel={(e) => {
            if (type === "number") {
              e.currentTarget.blur();
            }
          }}
          data-cy={dataCy}
          style={{
            padding: `${inputPadding?.top || "6px"} ${inputPadding?.right || "12px"} ${inputPadding?.bottom || "6px"} ${inputPadding?.left || "12px"}`,
          }}
          className={cn(
            "text-body-sm border-0 bg-transparent w-full block flex-1 min-w-0 outline-none",
            inverted && "bg-[#2b2b2b] text-[#d9d9d9] p-2 text-sm",
            disabled && "bg-app-gray50 text-app-gray300 cursor-not-allowed"
          )}
        />
        {renderTrailing}
      </div>
      {description && (
        <div
          className={`overflow-hidden text-sm cursor-default text-app-gray600 transition-all mt-1 ${inputState.isFocused ? "opacity-100 h-auto" : "opacity-0 h-0"}`}
        >
          {description}
        </div>
      )}
      {renderText}
    </div>
  );
};
Input.defaultProps = {
  min: undefined,
  max: undefined,
  step: undefined,
  name: undefined,
  label: "",
  type: "text",
  pattern: null,
  required: false,
  disabled: false,
  hideIndicators: false,
  hintText: "",
  infoHintText: "",
  title: undefined,
  onChange: undefined,
  onClick: undefined,
  placeholder: undefined,
  readOnly: false,
  allowCopy: false,
  minLength: undefined,
  maxLength: undefined,
  leading: null,
  leadingInline: false,
  trailing: null,
  trailingInline: false,
  inputPadding: {},
  autocomplete: "off",
};

export default Input;
