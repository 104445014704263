import { gql } from "@apollo/client";

export const SIGNUP_MEMBER = gql`
  mutation SignupMemberEmailPassword($input: SignupMemberEmailPasswordInput!) {
    signupMemberEmailPassword(input: $input) {
      member {
        id
        auth {
          email
        }
        profileImage
        customFields
        createdAt
        lastLogin
        planConnections {
          id
          status
          plan {
            id
            name
          }
        }
      }
    }
  }
`;

export const CREATE_STRIPE_CUSTOMER = gql`
  mutation CreateStripeCustomer($input: CreateStripeCustomerInput!) {
    createStripeCustomer(input: $input) {
      id
    }
  }
`;

export const UPDATE_MEMBER = gql`
  mutation UpdateMember($input: UpdateMemberInput!) {
    updateMember(input: $input) {
      id
      customFields
    }
  }
`;

export const UPDATE_MEMBER_AUTH = gql`
  mutation UpdateMemberAuth($input: UpdateMemberAuthInput!) {
    updateMemberAuth(input: $input) {
      id
      auth {
        email
      }
    }
  }
`;

export const DELETE_MEMBER = gql`
  mutation deleteMember($input: DeleteMemberInput!) {
    deleteMember(input: $input)
  }
`;

export const GENERATE_MEMBER_PASSWORD = gql`
  mutation generateMemberPassword($input: generateMemberPasswordInput!) {
    generateMemberPassword(input: $input) {
      generatedPassword
    }
  }
`;

export const IMPORT_MEMBERS = gql`
  mutation importMembers($input: ImportMembersInput!) {
    importMembers(input: $input) {
      jobId
      totalPendingJobs
    }
  }
`;

export const REMOVE_ALL_MEMBERS = gql`
  mutation removeAllMembers($input: RemoveAllMembersInput!) {
    removeAllMembers(input: $input) {
      jobId
    }
  }
`;

export const RESYNC_STRIP_MEMBERS = gql`
  mutation resyncStripeMember($input: ResyncStripeMemberInput!) {
    resyncStripeMember(input: $input) {
      jobId
    }
  }
`;

export const REMOVE_SELECT_MEMBERS = gql`
  mutation removeSelectMembers($input: RemoveSelectMembersInput!) {
    removeSelectMembers(input: $input) {
      count
    }
  }
`;

export const UPDATE_NOTES = gql`
  mutation updateMemberNote($input: UpdateMemberNoteInput!) {
    updateMemberNote(input: $input) {
      id
      memberNotes {
        note
        updatedAt
      }
    }
  }
`;

export const EXPORT_MEMBERS = gql`
  mutation ExportMembers($input: ExportMembersInput!) {
    exportMembers(input: $input) {
      jobId
    }
  }
`;

export const GET_MEMBERS_COUNT = gql`
  query GetMembersCount {
    getMembersCount
  }
`;

export const GET_MEMBERS = gql`
  query GetMembers(
    $after: String
    $order: OrderByInput
    $first: Int
    $search: String
    $filters: MemberFilter
  ) {
    getMembers(
      after: $after
      order: $order
      first: $first
      search: $search
      filters: $filters
    ) {
      mode
      edges {
        cursor
        node {
          id
          auth {
            email
          }
          trustStatus
          profileImage
          customFields
          createdAt
          lastLogin
          isNew
          planConnections {
            id
            status
            plan {
              id
              name
            }
            team {
              isTeamPlan
              isTeamOwner
              teamId
              teamOwnerId
            }
          }
        }
      }
      pageInfo {
        endCursor
        totalCountOfFilter
      }
    }
  }
`;

export const GET_MEMBER = gql`
  query GetMember($id: ID!) {
    currentMember(id: $id) {
      id
      auth {
        email
        connectedProviders {
          provider
        }
      }
      trustStatus
      profileImage
      createdAt
      lastLogin
      customFields
      stripeCustomerId
      metaData
      json
      loginRedirect
      verified
      isModerator
      memberNotes {
        note
        updatedAt
      }
      planConnections {
        id
        plan {
          id
          name
        }
        active
        status
        type
        payment {
          amount
          currency
          status
          priceId
          interval {
            type
            count
          }
          cancelAtDate
          pauseCollection
          pauseResumeAt
        }
        team {
          isTeamPlan
          isTeamOwner
          teamId
          teamOwnerId
        }
      }
      permissions {
        all
        manuallyGranted
        planInherited {
          permission
          plans {
            id
            name
          }
        }
      }
    }
  }
`;

export const GET_MEMBER_LOGIN_TOKEN = gql`
  query GetLoginWithMemberToken($input: GetLoginWithMemberTokenInput!) {
    getLoginWithMemberToken(input: $input)
  }
`;

export const GET_METRICS = gql`
  query getMemberMetricsByReport($report: MemberMetricsReport!) {
    getMemberMetricsByReport(report: $report)
  }
`;

export const GET_MEMBER_METRICS = gql`
  query memberMetrics($input: GetMemberMetricsInput!) {
    getMemberMetrics(input: $input)
  }
`;

export const GET_ACCESS_LOGS = gql`
  query getAccessLogs {
    getAccessLogs {
      timestamp
      ip
      user {
        id
        auth {
          email
        }
        profile {
          firstName
          lastName
        }
      }
    }
  }
`;

export const GET_APP_EVENTS = gql`
  query getAppEvents {
    getAppEvents {
      timestamp
      ip
      type
      user {
        id
        auth {
          email
        }
        profile {
          firstName
          lastName
        }
      }
    }
  }
`;

export const GET_TEAM_PLAN_MEMBERS = gql`
  query GetTeamMembers($input: GetTeamMembersInput!) {
    getTeamMembers(input: $input) {
      role
      member {
        id
        auth {
          email
        }
      }
    }
  }
`;

export const REMOVE_TEAM_MEMBER = gql`
  mutation removeTeamMember($input: RemoveTeamMemberInput!) {
    removeTeamMember(input: $input)
  }
`;

export const GET_TEAM = gql`
  query GetTeam($input: GetTeamInput!) {
    getTeam(input: $input) {
      id
      inviteToken
      maxTeamMembers
      currentTeamMemberCount
      inviteLink
    }
  }
`;
