import tw from "twin.macro";
import styled, { css } from "styled-components";
import { Tooltip } from "components/Tooltip";
import React from "react";
import { Link } from "components/Link/Link";

export const Container = styled.div<{ $isExpanded: boolean }>`
  display: flex;
  flex-direction: column;
  width: 68px;
  transition: width 150ms;
  min-height: 0px;
  width: ${({ $isExpanded }) => $isExpanded && "240px"};
  @media (min-width: 768px) {
    flex-shrink: 0;
  }
  /* @media (max-width: 768px) {
    width: 76px;
  } */
`;

export const TopSubContainer = tw.div`flex-1 flex flex-col overflow-y-auto`;

export const SubContainer = styled.div<{ $isExpanded?: boolean }>(
  ({ $isExpanded }) => css`
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    flex: none;
    margin: 0px 12px 12px;
    padding: 0px;
    align-items: ${!$isExpanded && "center"};
  `
);

export const ItemLink = styled(Link)(
  ({ theme: { colors } }) => css`
    padding: 8px;
    :hover {
      color: ${colors?.primary400};
      border-radius: 4px;
      & > svg {
        fill: ${colors?.primary400};
      }
    }
    :focus {
      color: ${colors?.primary400};
      background-color: ${colors?.primary050};
      border: 1px solid ${colors?.primary300};
      outline: none;
      font-weight: bold;
      & > svg {
        fill: ${colors?.primary400};
      }
    }
    &.active {
      background-color: ${colors?.primary050};
      color: ${colors?.primary400};
      font-weight: bold;
      border: none;
      font-weight: bold;
      & > svg {
        fill: ${colors?.primary400};
      }
    }
  `
);

export const Nav = styled.nav`
  margin-top: 20px;
  flex: 1 1 0%;
  & > :not([hidden]) ~ :not([hidden]) {
    margin-top: 8px;
  }
`;

export const AvatarButton = styled.div<{ $isExpanded?: boolean }>`
  ${({ theme: { colors, variables }, $isExpanded }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px;
    cursor: pointer;
    transition: ${variables.mainTransition} ease;
    border-radius: 8px;
    box-sizing: border-box;
    width: ${$isExpanded && "100%"};
    &:hover {
      background-color: ${colors.grey100};
    }
  `}
`;

export const SidebarHint = ({ children, hintText }) => (
  <Tooltip
    content={hintText}
    data-element="Tooltip"
    placement="right"
    showArrow
    tw="flow-root"
    interactive={false}
  >
    {children}
  </Tooltip>
);

export const NavContainer = ({ children, isExpanded, label }) => {
  if (isExpanded) {
    return <>{children}</>;
  }
  return <SidebarHint hintText={label}>{children}</SidebarHint>;
};
