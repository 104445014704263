import React, { useEffect, useMemo, useState } from "react";
import { useContentGroupsContext } from "features/content-groups";
import { Button, Dropdown } from "components";
import { Close } from "@mui/icons-material";
import { FilterType } from "generatedTypes";
import useDuplicateError from "hooks/useDuplicateError";
import { isObjectEqual } from "helpers/isObjectEqual";
import { useToastAlertContext } from "contexts/ToastAlertContext";
import { DEFAULT_GROUP_STATE } from "containers/Plans/utils";
import { generateID } from "helpers/generateID";
import DeleteContentGroup from "features/content-groups/components/DeleteContentGroupModal";
import GatedContentFields from "features/content-groups/components/GatedContentFields";
import {
  accessOptions,
  useContentGroupCalls,
  planChecker,
  getModifiedUrls,
  useHandleSubmit,
} from "features/content-groups/components/content-group.utils";
import { Drawer } from "components/Drawer/Drawer";
import { getResponseError } from "helpers/getResponseError";
import { useUnsavedChangesContext } from "contexts/UnsavedChangesContext";

const initialUrlInputs = [
  {
    url: "",
    filter: FilterType.Starts,
    id: Date.now().toString(36) + Math.random().toString(36).substring(2),
  },
];

const GatedContentPane = ({
  contentId: selectedContentId,
  closePane,
  isPaneOpen,
}) => {
  const [key, setKey] = useState("");
  const [name, setName] = useState("");
  const [redirect, setRedirect] = useState("");
  const [urlInputs, setUrlInputs] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [plans, setPlans] = useState([]);
  const [accessType, setAccessType] = useState(accessOptions[1]);
  const [customContentsInt, setCustomContents] = useState([]);
  const [contentId, setContentId] = useState(selectedContentId);

  useEffect(() => {
    setContentId(selectedContentId);
  }, [selectedContentId]);

  const handleSubmit = useHandleSubmit();
  const { setHasUnsavedChanges } = useUnsavedChangesContext();
  const { errorMessage } = useDuplicateError(urlInputs);

  const { createToastAlert } = useToastAlertContext();
  const { contentGroups, refetchContentGroups } = useContentGroupsContext();

  const {
    loadingMutations,
    createRestrictedUrl,
    deleteRestrictedUrl,
    updateRestrictedUrl,
    updateRestrictedUrlGroup,
    linkPlansToRestrictedUrlGroup,
    detachPlansFromRestrictedUrlGroup,
    createCustomContent,
    deleteCustomContent,
    updateCustomContent,
    createRestrictedUrlGroup,
  } = useContentGroupCalls();

  // get selected group from context using id
  const contentGroup = contentId
    ? contentGroups.find((group) => group.id === contentId)
    : DEFAULT_GROUP_STATE;

  const {
    allowAllMembers,
    id: restrictedUrlGroupId,
    key: contentKey,
    name: contentName,
    plans: contentPlans,
    redirect: groupRedirect,
    urls: restrictedUrls,
    customContent: customContentsExt,
  } = contentGroup;

  const initialPlans = useMemo(
    () =>
      contentPlans?.map((plan) => ({
        label: plan?.name,
        value: plan?.id,
      })),
    [contentPlans]
  );

  useEffect(() => {
    setUrlInputs(restrictedUrls);
    setPlans(initialPlans);
    setName(contentName);
    setKey(contentKey);
    setRedirect(groupRedirect);
    setCustomContents(customContentsExt);
    setAccessType(
      allowAllMembers || restrictedUrls.length < 0
        ? accessOptions[1]
        : accessOptions[0]
    );
  }, [
    allowAllMembers,
    contentKey,
    contentName,
    customContentsExt,
    groupRedirect,
    restrictedUrls,
    initialPlans,
  ]);

  const plansCheck = planChecker(initialPlans, plans);

  // urls from query
  const restrictedUrlsId = restrictedUrls.map((url) => url.id);
  // current urls in state
  const workingUrlsId = urlInputs.map((url) => url.id);

  const createdUrls = urlInputs.filter((url) => !url?.id.startsWith("url"));
  const deletedUrlsIds = restrictedUrlsId.filter(
    (urlId) => !workingUrlsId.includes(urlId)
  );
  // Check for modified plans
  const modifiedUrls = getModifiedUrls(restrictedUrls, urlInputs);

  const initialAccessType = allowAllMembers
    ? accessOptions[1]
    : accessOptions[0];

  // checks if changes were made to name, key, redirect and if a different access type was granted
  const wasInputsChanged = useMemo(
    () =>
      Boolean(name) &&
      (contentName !== name ||
        contentKey !== key ||
        redirect !== groupRedirect ||
        !isObjectEqual(accessType, initialAccessType)),
    [
      accessType,
      contentKey,
      contentName,
      groupRedirect,
      initialAccessType,
      key,
      name,
      redirect,
    ]
  );

  // check createdUrls array, filter out any empty url fields
  const filteredCreatedUrls = useMemo(
    () => createdUrls.filter((url) => url.url !== ""),
    [createdUrls]
  );

  const onReset = () => {
    setAccessType(accessOptions[0]);
    setKey("");
    setName("");
    setUrlInputs(initialUrlInputs);
    setRedirect("");
    setPlans([]);
    setCustomContents([]);
  };

  const wasFormChanged =
    wasInputsChanged ||
    modifiedUrls.length > 0 ||
    plansCheck.deletedPlans.length > 0 ||
    plansCheck.addedPlans.length > 0 ||
    filteredCreatedUrls.length > 0 ||
    deletedUrlsIds.length > 0;

  useEffect(() => {
    if (wasFormChanged) {
      return setHasUnsavedChanges(true);
    }
    return setHasUnsavedChanges(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wasFormChanged]);

  const handleOnCreate = async (e) => {
    e.preventDefault();
    createToastAlert({ processing: true });
    try {
      await createRestrictedUrlGroup({
        variables: {
          input: {
            name,
            key,
            redirect,
            allowAllMembers: accessType.value === "allMembers",
            urls: urlInputs.map(({ filter, url }) => ({ filter, url })),
            planIds: plans.map((p) => p.value),
            customContent: customContentsInt,
          },
        },
      });

      await refetchContentGroups();

      createToastAlert({
        alertType: "success",
        message: "Gated Content successfully created.",
      });
      window.CommandBar?.trackEvent("new-gated-content-added", {});
      onReset();
      closePane();
    } catch (err) {
      createToastAlert({
        alertType: "error",
        message: getResponseError(err),
      });
    }
  };

  const handleOnSave = async (e) => {
    e.preventDefault();

    try {
      // Some urls may have been modified to empty... push them to be deleted
      modifiedUrls.forEach(({ id, url }) => {
        if (!url) {
          deletedUrlsIds.push(id);
        }
      });

      // update restricted URL group if any of the inputs (name, key, redirect and access type) was changed
      if (wasInputsChanged) {
        await updateRestrictedUrlGroup({
          variables: {
            input: {
              restrictedUrlGroupId,
              name,
              key,
              redirect,
              allowAllMembers: accessType.value === "allMembers",
            },
          },
        });
      }

      // create and link newly added urls to restricted groups
      if (createdUrls.length) {
        await Promise.all(
          createdUrls
            .filter(({ url }) => url?.length)
            .map(({ url, filter }) =>
              createRestrictedUrl({
                variables: {
                  input: {
                    url,
                    filter,
                    restrictedUrlGroupId,
                  },
                },
              })
            )
        );
      }

      // detach urls from restricted Url group if urls was deleted
      if (deletedUrlsIds.length) {
        await Promise.all(
          deletedUrlsIds.map((deletedUrlId) =>
            deleteRestrictedUrl({
              variables: {
                input: {
                  urlId: deletedUrlId,
                },
              },
            })
          )
        );
      }

      // run updateRestrictedUrl mutation if any urls were modified
      if (modifiedUrls.length) {
        await Promise.all(
          modifiedUrls
            .filter(({ url }) => url?.length)
            .map(({ url, filter, id: restrictedUrlId }) =>
              updateRestrictedUrl({
                variables: {
                  input: {
                    restrictedUrlId,
                    url,
                    filter,
                  },
                },
              })
            )
        );
      }

      // detach all plans that were removed from the list of connected plans
      if (plansCheck.deletedPlans.length) {
        await detachPlansFromRestrictedUrlGroup({
          variables: {
            input: {
              restrictedUrlGroupId,
              planIds: plansCheck.deletedPlans,
            },
          },
        });
      }

      // link all plans that is being recently added to the connected plans list
      if (plansCheck.addedPlans.length) {
        await linkPlansToRestrictedUrlGroup({
          variables: {
            input: {
              restrictedUrlGroupId,
              planIds: plansCheck.addedPlans,
            },
          },
        });
      }

      await refetchContentGroups();

      createToastAlert({
        alertType: "success",
        message: "Your changes were successfully updated.",
      });
    } catch (err) {
      createToastAlert({
        alertType: "error",
        message: `${err}`,
      });
    }
  };

  const moreOptions = [
    {
      id: "delete-gated-content",
      text: "Delete",
      onClick: () => setShowDeleteModal(true),
    },
  ];

  return (
    <Drawer isOpen={isPaneOpen}>
      <div className="flex items-center justify-between p-5 border-b">
        <div className="flex items-center">
          <Button
            buttonStyle="icon"
            rightIcon={<Close />}
            onClick={closePane}
          />
          <h4 className="ml-1 text-h4 truncate w-52">
            {name || "Add Gated content"}
          </h4>
        </div>
        <div className="flex items-center gap-1">
          <Button
            id="save-gated-content"
            text="Save"
            isLoading={loadingMutations}
            isDisabled={!wasFormChanged || errorMessage === "DUPLICATE"}
            onClick={contentId ? handleOnSave : handleOnCreate}
          />
          {contentId && (
            <Dropdown options={moreOptions} dataCy="gated-content-actions" />
          )}
        </div>
      </div>
      <GatedContentFields
        handleUpdate={contentId ? handleOnSave : handleOnCreate}
        name={name}
        onNameChange={(e) => {
          setName(e.target.value);
          if (!contentId) {
            setKey(generateID(e.target.value));
          }
        }}
        contentKey={key}
        onContentKeyChange={(e) => setKey(generateID(e.target.value))}
        accessType={accessType}
        onAccessTypeChange={(o) => setAccessType(o)}
        plans={plans}
        onPlansChange={(o) => setPlans(o)}
        urlInputs={urlInputs}
        setUrlInputs={setUrlInputs}
        redirect={redirect}
        setRedirect={setRedirect}
        customContentsInt={customContentsInt}
        setCustomContents={setCustomContents}
        className="overflow-y-auto"
        isEditMode={Boolean(restrictedUrlGroupId)}
        onAddContent={(_content) =>
          handleSubmit({
            action: createCustomContent,
            successMessage: "Hosted content successfully created",
            input: { restrictedUrlGroupId, ..._content },
            refetch: refetchContentGroups,
          })
        }
        onDeleteContent={(id) =>
          handleSubmit({
            action: deleteCustomContent,
            successMessage: "Hosted content successfully deleted",
            input: { customContentId: id },
            refetch: refetchContentGroups,
          })
        }
        onUpdateContent={(content) =>
          handleSubmit({
            action: updateCustomContent,
            successMessage: "Hosted content successfully updated.",
            input: content,
            refetch: refetchContentGroups,
          })
        }
      />

      <DeleteContentGroup
        showModal={showDeleteModal}
        setShowModal={setShowDeleteModal}
        group={contentGroup}
        closeModal={closePane}
      />
    </Drawer>
  );
};

export default GatedContentPane;
