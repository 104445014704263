import React from "react";
import { CopyButton } from "../Button";

interface Props {
  label?: string;
  text: string;
  className?: string;
}

const TextComboInline = ({ label, text, className }: Props) => (
  <div tw="flex justify-between items-center" className={className}>
    <span tw="text-base">{label}</span>
    <div className="flex items-center min-w-0">
      <span tw="mr-3.5 text-base whitespace-nowrap">{text}</span>
      <CopyButton value={text} />
    </div>
  </div>
);

export default TextComboInline;
