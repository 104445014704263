import { ExportType } from "generatedTypes";
import React, { useState } from "react";
import Popover from "components/Popover";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import FilterGif from "assets/gifs/plan-filter.gif";
import { useExportMembersMutation } from "features";
import ExportMessageModal from "./ExportMessageModal";

const MembersPageDropdown = ({ planIds, status }) => {
  const [showExportModal, setShowExportModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [exportMembersCSV, { loading }] = useExportMembersMutation();

  const handleExport = async (type: ExportType) => {
    try {
      await exportMembersCSV({
        variables: {
          input: { type, filters: { planIds, status } },
        },
      });
      setShowExportModal(true);
      setModalMessage(
        "Please check your email for your export files.  Exports are done in batches of 2,500 members  as a CSV file."
      );
    } catch (err) {
      setShowExportModal(true);
      setModalMessage(err.graphQLErrors[0].message);
    }
  };

  const handleOnClick = (type: ExportType) => () => handleExport(type);

  const handleKeyDown = (type: ExportType) => (event) => {
    if (event.key === "Enter") {
      handleExport(type);
    }
  };

  return (
    <>
      <Popover
        buttonLeftIcon={<MoreVertIcon />}
        buttonStyle="icon"
        buttonNoBorder
        style={{ right: "0px" }}
      >
        <div tw="p-1 text-base">
          <div
            role="button"
            tabIndex={0}
            tw="rounded px-3 py-2 mb-1 hover:bg-app-gray50 focus:bg-app-gray50"
            onClick={handleOnClick(ExportType.Member)}
            onKeyDown={handleKeyDown(ExportType.Member)}
          >
            Export Members CSV
          </div>
          <div
            role="button"
            tabIndex={0}
            tw="rounded px-3 py-2 hover:bg-app-gray50 focus:bg-app-gray50"
            onClick={handleOnClick(ExportType.MemberPlans)}
            onKeyDown={handleKeyDown(ExportType.MemberPlans)}
          >
            Export Plans CSV
          </div>
        </div>
        <hr />
        <div tw="p-4">
          <p tw="text-utility-tag-md text-app-gray600 pb-2">
            Filters are applied to exports
          </p>
          <img
            src={FilterGif}
            alt="plan-filter"
            tw="border rounded border-app-gray100"
          />
        </div>
      </Popover>
      <ExportMessageModal
        showModal={showExportModal}
        message={modalMessage}
        loading={loading}
        setShowModal={setShowExportModal}
      />
    </>
  );
};

export default MembersPageDropdown;
