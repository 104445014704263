import {
  AttachMoney,
  HourglassBottomOutlined,
  PersonOutlineOutlined,
  EventAvailableOutlined,
} from "@mui/icons-material";
import { Card, StatCard } from "components";
import { MemberMetricsReport } from "generatedTypes";
import React, { useEffect } from "react";
import { convertObjectToArray } from "helpers/convertObjectToArray";
import Skeleton from "react-loading-skeleton";
import { useGetMemberMetricsByReportQuery } from "features";

const defaultMetrics = {
  totalPaidSubscriptionMemberCount: 0,
  totalPaidOneTimeMemberCount: 0,
  totalPaidCount: 0,
  totalTrialingCount: 0,
  totalFreeCount: 0,
};

export const Stats = () => {
  const {
    data: membersInfo,
    loading: memberInfoLoading,
    refetch: refetchMemberInfoMetrics,
  } = useGetMemberMetricsByReportQuery({
    variables: { report: MemberMetricsReport.TotalMemberCountByType },
  });

  useEffect(() => {
    refetchMemberInfoMetrics();
  }, [refetchMemberInfoMetrics]);

  if (memberInfoLoading)
    return (
      <>
        {[1, 2, 3, 4].map((loader) => (
          <Card tw="gap-3 flex items-center p-3 h-[74px]" key={loader}>
            <Skeleton className="rounded-full" width={39} height={41} />
            <div>
              <Skeleton width={90} height={16} />
              <Skeleton width={50} height={28} />
            </div>
          </Card>
        ))}
      </>
    );

  const memberInfo = convertObjectToArray(
    membersInfo?.getMemberMetricsByReport || defaultMetrics
  )?.filter((member) => member?.key !== "totalPaidSubscriptionMemberCount");

  const humanReadableValue = {
    totalPaidCount: {
      title: "Paid Members",
      type: "success",
      icon: <AttachMoney />,
    },
    totalFreeCount: {
      title: "Free Members",
      type: "warning",
      icon: <PersonOutlineOutlined />,
    },
    totalPaidSubscriptionMemberCount: {
      title: "Subscriptions",
      type: "info",
      icon: <EventAvailableOutlined />,
    },
    totalPaidOneTimeMemberCount: {
      title: "One-time plans",
      type: "info",
      icon: <EventAvailableOutlined />,
    },
    totalTrialingCount: {
      title: "Active Trialing",
      type: "danger",
      icon: <HourglassBottomOutlined />,
    },
  };

  return (
    <>
      {memberInfo.map((member) => (
        <StatCard
          key={member?.key}
          title={humanReadableValue[member?.key].title}
          value={member?.value}
          type={humanReadableValue[member?.key].type}
          icon={humanReadableValue[member?.key].icon}
        />
      ))}
    </>
  );
};
