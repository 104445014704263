import React from "react";
import { Outlet } from "react-router-dom";
import SettingsBar from "components/SettingsBar";
import { TabItem, TabNav, Tabs, TabContent } from "components/Tabs";
import { ContentWrapper } from "components";
import { PlansPageLoading } from "./PlansSkeleton";
import PlansHeader from "./PlansHeader";
import { PlanList } from "./PlanList";
import { generateTabLabel } from "./utils";
import { PlansContextProvider, usePlansContext } from "./context/Plans.context";
import EditPlan from "./Plan/edit-plan/EditPlan";

const ContextWrapper = ({ children }) => {
  return <PlansContextProvider>{children}</PlansContextProvider>;
};

export const PlansPage = () => {
  const { loadingPlans, activePlans, archivedPlans } = usePlansContext();

  if (loadingPlans) return <PlansPageLoading />;

  return (
    <ContextWrapper>
      <ContentWrapper>
        <SettingsBar className="flex-1 overflow-hidden">
          <PlansHeader />
          <Tabs isExpanded showBottomBorder>
            <TabNav>
              <TabItem
                id="active"
                label={generateTabLabel("Active", activePlans?.length)}
              />
              <TabItem
                id="archived"
                label={generateTabLabel("Archived", archivedPlans?.length)}
              />
            </TabNav>
            <TabContent id="active" tw="mt-0">
              <PlanList type="active" />
            </TabContent>
            <TabContent id="archived">
              <PlanList type="archived" />
            </TabContent>
          </Tabs>
        </SettingsBar>
        <Outlet />
      </ContentWrapper>
    </ContextWrapper>
  );
};

export default PlansPage;
export { PlanDetails } from "./Plan/Plan";
export { EditPlan };
export { CreatePlan } from "./Plan/create-plan/CreatePlan";
