import React from "react";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import Input from "components/Input";
import { Label } from "components";

interface Props {
  formInputs: { [key: string]: string };
  brandColor: string;
  appImage: string;
}

export const ChangePasswordUI = ({
  formInputs,
  brandColor,
  appImage,
}: Props) => (
  <div tw="w-[360px] rounded-[20px] rounded-3xl shadow-lg bg-white">
    <div tw="p-7">
      <div tw="pb-[30px] flex justify-center">
        <img src={appImage} height={43} tw="h-[43px]" alt="app-logo" />
      </div>

      <h2 tw="text-h2 text-center font-bold mb-5">
        {formInputs.reset_your_password || "Reset your password"}
      </h2>

      <Label
        text={formInputs.reset_enter_code || "Enter your 6-digit code"}
        tw="mb-1"
      />
      <div tw="flex items-center justify-between mb-5">
        <div tw="border border-app-gray300 rounded-md bg-white w-11 h-12" />
        <div tw="border border-app-gray300 rounded-md bg-white w-11 h-12" />
        <div tw="border border-app-gray300 rounded-md bg-white w-11 h-12" />
        <div tw="border border-app-gray300 rounded-md bg-white w-11 h-12" />
        <div tw="border border-app-gray300 rounded-md bg-white w-11 h-12" />
        <div tw="border border-app-gray300 rounded-md bg-white w-11 h-12" />
      </div>

      <Input
        label={formInputs.choose_new_password || "Choose a new password"}
        placeholder={formInputs.reset_password_placeholder || "Enter password"}
        tw="mb-5"
        hideIndicators
        disabled
      />

      <button
        tw="mb-5 w-full font-bold text-white p-3 rounded-lg text-base"
        type="button"
        style={{ backgroundColor: brandColor || "#2962FF" }}
      >
        {formInputs.reset_confirm_reset || "Confirm & Reset"}
      </button>
    </div>
  </div>
);
