import React from "react";
import { Modal, ModalTypes } from "components/Modal";
import { MemberEvent } from "generatedTypes";
import TextCombo from "components/TextCombo";
import { format } from "date-fns";
import { CodeBlock } from "components";
import { isObjectEmpty } from "helpers/isObjectEmpty";
import { json } from "@codemirror/lang-json";

interface Props extends ModalTypes {
  event: MemberEvent;
}

export const EventDetailsModal = ({
  event,
  showModal,
  setShowModal,
}: Props) => {
  const isDataAvailable = !isObjectEmpty(event?.data);

  return (
    <Modal
      name="event-details"
      setShowModal={setShowModal}
      showModal={showModal}
      title="Event Details"
      showDivider
      width="494px"
      actionButtons={{
        cancel: { label: "Cancel" },
      }}
      showBottomSection={false}
    >
      <TextCombo
        text={event?.id}
        label="Event ID"
        withCopy
        spaceOut
        className="mb-3"
      />
      <TextCombo
        text={event?.memberId}
        label="Member ID"
        withCopy
        spaceOut
        className="mb-3"
      />
      <TextCombo
        text={format(new Date(event?.createdAt), "yyyy-LL-dd, K:m:s aaaa")}
        label="Date"
        className="mb-3"
      />
      <TextCombo text={event?.source} label="Source" className="mb-3" />

      <p className="flex items-center text-body-sm font-bold mb-2">Data</p>
      {isDataAvailable ? (
        <CodeBlock
          extensions={[json()]}
          value={JSON.stringify(event?.data, null, 2)}
          readOnly
          editable={false}
        />
      ) : (
        <p className="text-base">No data available</p>
      )}
    </Modal>
  );
};
