import { ChevronRight } from "@mui/icons-material";
import cn from "helpers/cn";
import React from "react";
import { NavLink, Outlet } from "react-router-dom";

const ROUTES = [
  {
    path: "general",
    name: "General",
  },
  // {
  //   path: "notifications",
  //   name: "Notifications",
  // },
  // {
  //   path: "emails",
  //   name: "Emails",
  // },
];

export const CommunitySettings = () => {
  return (
    <div className="flex flex-row h-full overflow-hidden">
      <div className="flex flex-col border-r flex-shrink-0">
        <nav className="flex flex-col text-sm p-5 gap-3">
          {ROUTES.map(({ path, name }) => (
            <NavLink
              data-cy={`settings-${name.replace(/\s+/g, "-").toLowerCase()}`}
              to={path}
              key={name}
              className={(active) =>
                cn("text-base", { "text-app-blue500": active })
              }
            >
              {name}
              <ChevronRight />
            </NavLink>
          ))}
        </nav>
      </div>
      <Outlet />
    </div>
  );
};
