import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import SettingsBar, { TitleSection } from "components/SettingsBar";
import Input from "components/Input";
import { Background, Button, MarkdownEditor, ContentWrapper } from "components";
import { useAppDataContext } from "contexts/AppDataContext";
import { useUpdateEmailsMutation } from "features";
import { useToastAlertContext } from "contexts/ToastAlertContext";
import { isError } from "helpers/typeguards";
import { useGetEmailsQuery } from "features/emails";
import { getObjectWithKeys } from "helpers/getObjectWithKeys";
import { isObjectEqual } from "helpers/isObjectEqual";
import { removeEmpty } from "helpers/removeEmpty";
import { trimObjectStringValues } from "helpers/trimObjectStringValues";
import { useGetBrandingQuery } from "features/branding";
import { ReplyToEmail } from "components/ReplyToEmail";
import {
  INITIAL_COMMENTING_NOTIFICATION_STATE,
  useHandleOnChange,
} from "../emails.utils";
import { EmailTemplateLoading } from "../emails.loading";
import * as S from "../emails.styles";

export const CommentingNotification = () => {
  const { appData, isLoading } = useAppDataContext();
  const [formValues, setFormValues] = useState<{ [key: string]: string }>(
    INITIAL_COMMENTING_NOTIFICATION_STATE
  );

  const formRef = useRef<HTMLFormElement>(null);
  const {
    replyTo,
    subject,
    paragraph1,
    paragraph2,
    unsubscribeLinkText,
    primaryButtonText,
  } = INITIAL_COMMENTING_NOTIFICATION_STATE;

  const valRef = useRef(null);

  const { createToastAlert } = useToastAlertContext();

  const [updateEmails, { loading }] = useUpdateEmailsMutation();

  const { data: branding } = useGetBrandingQuery();

  const {
    data,
    loading: loadingEmails,
    refetch: refetchEmails,
  } = useGetEmailsQuery();

  const commentingNotification = useMemo(
    () =>
      getObjectWithKeys(
        data?.getEmails?.commentNotification || {},
        Object.keys(INITIAL_COMMENTING_NOTIFICATION_STATE)
      ),
    [data?.getEmails?.commentNotification]
  );

  useEffect(() => {
    valRef.current = commentingNotification;
    setFormValues({
      replyTo: valRef.current?.replyTo || "",
      subject: valRef.current?.subject || "",
      paragraph1: valRef.current?.paragraph1 || "",
      paragraph2: valRef.current?.paragraph2 || "",
      unsubscribeLinkText: valRef.current?.unsubscribeLinkText || "",
      primaryButtonText: valRef.current?.primaryButtonText || "",
    });
  }, [commentingNotification]);

  const { handleOnChange } = useHandleOnChange({ setFormValues, formValues });

  // check if form changed then run mutation
  const didFormChange = !isObjectEqual(
    removeEmpty(valRef.current || {}),
    removeEmpty(trimObjectStringValues(formValues))
  );

  const onSubmit = async (e) => {
    e.preventDefault();
    const isValid = e.target.checkValidity();
    if (!isValid) {
      createToastAlert({
        alertType: "error",
        message: "Some of the fields are invalid.",
      });
      return;
    }
    createToastAlert({ processing: true });

    try {
      await updateEmails({
        variables: {
          input: {
            commentNotification: { ...formValues },
          },
        },
      });

      createToastAlert({
        alertType: "success",
        message: "Commenting Notification email updated successfully.",
      });
    } catch (_error) {
      if (isError(_error)) {
        createToastAlert({
          alertType: "error",
          message: _error.message,
        });
      }
    } finally {
      await refetchEmails();
    }
  };

  if (loadingEmails || isLoading)
    return <EmailTemplateLoading title="Abandoned Cart" />;

  return (
    <ContentWrapper>
      <SettingsBar>
        <TitleSection
          title="Commenting Notification"
          backLink="../emails"
          backLinkTitle="Emails"
          emphasize
          gutter="medium"
          buttons={
            <Button
              text="Save"
              onClick={() => formRef.current?.requestSubmit()}
              isLoading={loading}
              isDisabled={!didFormChange}
            />
          }
        />
        <form
          className="p-5 overflow-y-auto flex flex-col h-full"
          onSubmit={onSubmit}
          ref={formRef}
          noValidate
        >
          <>
            <ReplyToEmail
              placeholder="email@somemail.com"
              value={formValues.replyTo}
              onChange={({ target }) => {
                handleOnChange({ type: "replyTo", value: target.value });
              }}
            />
            <Input
              name="subject"
              placeholder={subject}
              value={formValues.subject}
              label="Subject"
              tw="pb-5"
              onChange={({ target: { value } }) =>
                handleOnChange({ type: "subject", value })
              }
              description="Customize the email subject."
            />
            <Input
              name="paragraph1"
              placeholder={paragraph1}
              value={formValues.paragraph1}
              label="Title"
              tw="pb-5"
              onChange={({ target: { value } }) =>
                handleOnChange({ type: "paragraph1", value })
              }
              description="Customize the email title."
            />

            <Input
              name="primaryButtonText"
              placeholder={primaryButtonText}
              value={formValues.primaryButtonText}
              label="Button Text"
              tw="mb-5"
              description="Customize the button text."
              onChange={({ target: { value } }) =>
                handleOnChange({ type: "primaryButtonText", value })
              }
            />
            <MarkdownEditor
              label="Footer"
              name="paragraph2"
              height={150}
              className="mb-5"
              value={formValues.paragraph2}
              placeholder={paragraph2}
              description="Customize the email footer."
              onChange={(value) =>
                handleOnChange({ type: "paragraph2", value })
              }
            />
            <Input
              name="unsubscribeLinkText"
              placeholder={unsubscribeLinkText}
              value={formValues.unsubscribeLinkText}
              label="Unsubscribe"
              tw="mb-5"
              description="Customize the unsubscribe link text"
              onChange={({ target: { value } }) =>
                handleOnChange({ type: "unsubscribeLinkText", value })
              }
            />
          </>
        </form>
      </SettingsBar>
      <Background>
        <div className="p-5 max-h-full min-h-0 overflow-auto">
          <S.UploadLogoText />
          <S.ShowcaseCard>
            {appData?.image && (
              <img
                src={appData?.image}
                height={43}
                className="h-[43px] w-fit"
                alt={appData?.name}
              />
            )}
            <h1
              className="text-h1 font-bold my-[30px]"
              dangerouslySetInnerHTML={{
                __html: formValues.paragraph1 || paragraph1,
              }}
            />
            <div className="px-8 py-6 bg-app-gray50">
              <p className="text-app-gray500 text-body-sm">New Comment</p>
            </div>
            <Button
              text={formValues.primaryButtonText || primaryButtonText}
              className="mb-6 mt-[30px]"
              style={{
                backgroundColor:
                  branding?.getBranding?.colors?.light.primaryButton,
              }}
            />
            <MarkdownEditor
              readonly
              value={formValues.paragraph2 || paragraph2}
            />
            <p
              className="mt-6 text-app-gray500 text-body-sm underline"
              dangerouslySetInnerHTML={{
                __html: formValues.unsubscribeLinkText || unsubscribeLinkText,
              }}
            />
          </S.ShowcaseCard>
        </div>
      </Background>
    </ContentWrapper>
  );
};
