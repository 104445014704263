import { gql } from "@apollo/client";

export const GET_MEMBERS_EVENTS = gql`
  query GetMemberEvents($first: Int, $after: Int, $filters: MemberEventFilter) {
    getMemberEvents(first: $first, after: $after, filters: $filters) {
      edges {
        node {
          id
          memberId
          createdAt
          source
          event
          data
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`;

export const GET_MEMBERS_EVENTS_DASHBOARD = gql`
  query GetMemberEventsDashboard($first: Int, $after: Int, $filters: MemberEventFilter) {
    getMemberEvents(first: $first, after: $after, filters: $filters) {
      edges {
        node {
          id
          memberId
          createdAt
          source
          event
          data
          memberAuth {
            email
          }
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`;
