import React, { useState, useCallback, useMemo } from "react";
import Select from "components/Select";
import Input from "components/Input";
import Spacer from "components/Spacer";
import Text from "components/Text";
import {
  GET_MEMBERS,
  GetMembersQuery,
  useSignupMemberEmailPasswordMutation,
} from "features/members";
import { Modal, ModalTypes } from "components/Modal";
import { Form, Button, Link } from "components";
import useTranslations from "hooks/useTranslations";
import { useEnvContext } from "contexts/EnvironmentContext";
import { MemberEdge } from "generatedTypes";
import { useToastAlertContext } from "contexts/ToastAlertContext";
import { isError } from "helpers/typeguards";
import { useAppDataContext } from "contexts/AppDataContext";
import { useMembersContext } from "containers/Members/context/MembersContextProvider";

export interface OptionType {
  label: string;
  value: string;
  type?: string;
}
interface CreateMemberModalProps extends ModalTypes {
  onImportClick: () => void;
  activeFreePlans: OptionType[];
}

const CreateMemberModal = ({
  showModal,
  setShowModal,
  onImportClick,
  activeFreePlans,
}: CreateMemberModalProps) => {
  const defaultValues = {
    email: "",
    password: "",
    plans: [],
  };

  const { translate } = useTranslations();
  const [newMemberDetails, setNewMemberDetails] = useState(defaultValues);

  const { appData } = useAppDataContext();
  const { isSandboxEnv } = useEnvContext();
  const { createToastAlert } = useToastAlertContext();
  const { refetch } = useMembersContext();
  const { email, password, plans: planInput } = newMemberDetails;
  const [modalError, setModalError] = useState(null);

  const selectedPlans = useMemo(
    () =>
      newMemberDetails.plans.map((plan) => ({
        planId: plan.planId,
      })),
    [newMemberDetails.plans]
  );

  // sort active free plans alphabetically
  const sortedPlans = useMemo(
    () => activeFreePlans.sort((a, b) => a.label.localeCompare(b.label)),
    [activeFreePlans]
  );

  const [signupMemberEmailPasswordMutation, { loading }] =
    useSignupMemberEmailPasswordMutation({
      variables: {
        input: {
          email,
          password,
          plans: selectedPlans,
        },
      },
      update(cache, { data }) {
        const newMember = data?.signupMemberEmailPassword.member;
        const members = cache.readQuery<GetMembersQuery>({
          query: GET_MEMBERS,
        });
        cache.writeQuery<GetMembersQuery>({
          query: GET_MEMBERS,
          data: {
            getMembers: {
              ...members?.getMembers,
              edges: [
                {
                  __typename: "MemberEdge",
                  node: newMember,
                  cursor: newMember?.id,
                } as MemberEdge,
                ...(members?.getMembers.edges || []), // Spread existing edges after the new member
              ],
            },
          },
        });
        // update member count
        cache.modify({
          fields: {
            getMembersCount: (existingCount) => existingCount + 1,
          },
        });
      },
      onCompleted() {
        createToastAlert({
          alertType: "success",
          message: "Member was successfully created.",
        });
        setShowModal(false);
        setNewMemberDetails(defaultValues);
      },
      onError(e) {
        if (isError(e)) {
          setModalError(e.message);
        }
      },
    });

  const isPasswordRequired = appData?.passwordlessEnabled === false;

  const handleOnClick = useCallback(() => {
    setShowModal(false);
    onImportClick();
  }, [onImportClick, setShowModal]);

  const handleOnChange = useCallback(
    (key: string) => (event) =>
      setNewMemberDetails({
        ...newMemberDetails,
        ...{ [key]: event.target.value },
      }),
    [newMemberDetails]
  );

  const isFormValid = useMemo(() => {
    const isEmailValid = email && email.includes("@");
    const isPasswordValid =
      (password && password.length >= 8) || (!isPasswordRequired && !password);

    return isEmailValid && isPasswordValid;
  }, [email, password, isPasswordRequired]);

  const handleOnSubmit = async (e) => {
    e.preventDefault();
    await signupMemberEmailPasswordMutation();
    await refetch();
  };

  return (
    <Modal
      name="create-member"
      setShowModal={setShowModal}
      showModal={showModal}
      title={translate("createMember.title", "Create a new member")}
      showDivider
      width="430px"
      actionButtons={{
        confirm: {
          label: translate("addMember", "Add Member"),
          onConfirm: handleOnSubmit,
          isDisabled: !isFormValid,
          isLoading: loading,
          dataCy: "create-member-submit-button",
        },
        cancel: { label: translate("createMember.cancel", "Cancel") },
      }}
      nonScrollable
      errorMessage={modalError}
      onErrorClose={() => setModalError(null)}
    >
      <Form onSubmit={isFormValid && handleOnSubmit}>
        <Input
          hideIndicators
          type="email"
          name="email"
          label={translate("createMember.email", "Email")}
          placeholder={translate(
            "createMember.emailPlaceHolder",
            "Enter the member's email"
          )}
          value={email}
          onChange={handleOnChange("email")}
          dataCy="create-member-email-input"
        />
        <Spacer spacing="medium" />
        <Input
          hideIndicators
          value={password}
          type="password"
          name="password"
          label={translate("createMember.password", "Password")}
          placeholder={
            isPasswordRequired
              ? "Minimum 8 characters required"
              : "Password is not required"
          }
          onChange={handleOnChange("password")}
          pattern=".{8,}"
          dataCy="create-member-password-input"
          description={
            <>
              Password is not required while{" "}
              <Link
                isExternal
                target="_blank"
                showAsLink
                to="https://docs.memberstack.com/hc/en-us/articles/14052704355483"
              >
                Passwordless
              </Link>{" "}
              is enabled.
            </>
          }
        />
        <button type="submit" className="sr-only" aria-label="Submit Form">
          Submit
        </button>
        <Spacer spacing="medium" />
        <Select
          value={planInput}
          label="Assign to plan (optional)"
          placeholder="Select a plan"
          options={sortedPlans}
          onChange={(selected) => {
            setNewMemberDetails({
              ...newMemberDetails,
              plans: selected,
            });
          }}
          isMulti
        />
        <Spacer spacing="medium" />

        {isSandboxEnv === false && (
          <Text bold>
            {translate(
              "createMember.footerText",
              "To add multiple members, use our"
            )}{" "}
            <Button
              buttonStyle="transparent"
              text={translate("createMember.importTool", "import tool")}
              tw="inline-block text-base underline"
              onClick={handleOnClick}
            />
          </Text>
        )}
      </Form>
    </Modal>
  );
};

export default CreateMemberModal;
