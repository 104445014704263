import { PostStatus } from "generatedTypes";
import styled from "styled-components";

const colorSwitch = (postStatus: PostStatus) => {
  switch (postStatus) {
    case PostStatus.Published:
      return "#2EC862";
    case PostStatus.Spam:
      return "#E40023";
    case PostStatus.Hidden:
      return "#2C2C2C";
    default:
      return "#ebebeb";
  }
};

export const PostStatusBar = styled.div<{ postStatus: PostStatus }>`
  border-radius: 4px;
  border-width: 4px;
  border-color: ${({ postStatus }) => colorSwitch(postStatus)};
`;

export const PostImageContainer = styled.div<{ postStatus: PostStatus }>`
  border-width: 1px;
  border-radius: 9999px;
  width: 48px;
  height: 48px;
  margin-right: 12px;
  border-color: ${({ postStatus }) => colorSwitch(postStatus)};
`;

export const PostActionRadioButtons = styled.div<{ postStatus: PostStatus }>`
  position: relative;
  & label,
  input {
    display: block;
    font-size: 12px;
    font-weight: 700;
    line-height: 16px;
    color: ${({ theme }) => theme.colors?.grey500};
    border-radius: 4px;
    border: 1px solid ${({ theme }) => theme.colors?.grey200};
  }

  & input[type="radio"] {
    opacity: 0.011;
    z-index: 100;
    position: absolute;
  }

  & input[type="radio"]:checked + label {
    color: white;
    background: ${({ postStatus }) => colorSwitch(postStatus)};
    border-color: ${({ postStatus }) => colorSwitch(postStatus)};
  }

  & label {
    cursor: pointer;
    z-index: 90;
    padding: 6px 10px;
  }
`;
