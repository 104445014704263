import React, { lazy, useMemo, useRef, useState } from "react";
// import { OverflowList } from "react-overflow-list";
import {
  ContentGroupsContextProvider,
  useContentGroupsContext,
} from "features/content-groups";
import useSearch from "hooks/useSearch";
import { TitleSection } from "components/SettingsBar";
import {
  GatedContentCard,
  Button,
  EmptyState,
  Link,
  ContentWrapper,
} from "components";
import OnboardingModal from "components/Modal/OnboardingModal";
import NoSearchResult from "components/SearchBar/NoSearchResult";
import { PlansContextProvider } from "containers/Plans/context/Plans.context";
import { DEFAULT_GROUP_STATE } from "../Plans/utils";
import GatedContentLoader from "./GatedContent.loading";
import GatedContentPane from "./GatedContentPane";

const EmptyGatedContentIcon = lazy(
  () => import("assets/images/empty_gatedcontent.svg")
);

// const ItemRenderer = ({ name, id }, idx) => (
//   <Tag
//     key={id}
//     text={name}
//     variant="secondary"
//     size="medium"
//     className={idx !== 0 && "ml-1"}
//   />
// );

// const OverflowRenderer = (items) => (
//   <Tag
//     // eslint-disable-next-line react/destructuring-assignment
//     text={`+${items?.length}`}
//     variant="secondary"
//     size="medium"
//     className="ml-1"
//   />
// );

// const Overflow = ({ names }) => (
//   <OverflowList
//     collapseFrom="end"
//     minVisibleItems={0}
//     items={names}
//     itemRenderer={ItemRenderer}
//     overflowRenderer={OverflowRenderer}
//   />
// );

const Component = () => {
  const [showOnboardingModal, setShowOnboardingModal] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState(DEFAULT_GROUP_STATE);
  const [showPane, setShowPane] = useState(false);
  const { contentGroups, loadingContentGroups } = useContentGroupsContext();

  const {
    searchString,
    setSearchString,
    output: restrictedUrlGroups,
  } = useSearch({
    input: contentGroups,
    map: (cg) => cg?.name,
  });

  const selectedGroupRef = useRef(selectedGroup);

  const gateContentList = useMemo(() => {
    const sortedRestrictedUrlGroups = restrictedUrlGroups?.sort((a, b) =>
      a.name.localeCompare(b.name)
    );

    const handleEditClick = (restrictedUrlGroup) => {
      selectedGroupRef.current = restrictedUrlGroup;
      setSelectedGroup(restrictedUrlGroup);
      if (selectedGroupRef.current.id === selectedGroup.id) {
        return setShowPane((prev) => {
          if (prev) {
            setSelectedGroup(DEFAULT_GROUP_STATE);
            return false;
          }
          return true;
        });
      }
      return setShowPane(true);
    };

    if (sortedRestrictedUrlGroups?.length === 0) {
      return <NoSearchResult onClearSearch={() => setSearchString("")} />;
    }

    return (
      <div className="overflow-y-auto p-5">
        {sortedRestrictedUrlGroups?.map((restrictedUrlGroup) => (
          <GatedContentCard
            selected={selectedGroup.id === restrictedUrlGroup.id}
            contentId={restrictedUrlGroup.key}
            name={restrictedUrlGroup.name}
            plans={restrictedUrlGroup.plans}
            urls={restrictedUrlGroup.urls}
            onClick={() => handleEditClick(restrictedUrlGroup)}
            key={restrictedUrlGroup.id}
            className="mb-3"
            dataCy={`content-group-${restrictedUrlGroup.key}`}
          />
        ))}
      </div>
    );
  }, [restrictedUrlGroups, selectedGroup.id, setSearchString]);

  if (loadingContentGroups) {
    return <GatedContentLoader hasNavigation={false} />;
  }

  const onAddGatedContent = () => {
    setSelectedGroup(DEFAULT_GROUP_STATE);
    setShowPane(true);
  };

  const searchProps = Boolean(contentGroups?.length) && {
    searchString,
    setSearchString,
  };

  return (
    <>
      <ContentWrapper>
        <div className="z-10 flex flex-col flex-1 overflow-hidden">
          <TitleSection
            title="Gated Content"
            titleHint={
              <>
                Gate access to pages, folders, and UI elements on your project.{" "}
                <Link
                  to="https://docs.memberstack.com/hc/en-us/articles/7402927868443-Gated-Content"
                  isExternal
                  underline
                >
                  Learn more →
                </Link>
              </>
            }
            buttons={
              <Button
                text="+ Add"
                onClick={() => onAddGatedContent()}
                dataCy="add-content-group"
              />
            }
            emphasize
            {...searchProps}
          />

          {!contentGroups?.length ? (
            <EmptyState
              isFullScreen
              text="Gate Access to Content"
              description="Use this feature to block access to pages, folders, and page elements. Just tell Memberstack what you want to hide, who should have access, and where to send folks who don't."
              buttonText="Gate Content"
              onCreateClick={() => onAddGatedContent()}
              // onDemoClick={() => setShowOnboardingModal(true)}
              svgImage={<EmptyGatedContentIcon />}
              docsLink="https://docs.memberstack.com/hc/en-us/articles/7402927868443-Gated-Content"
            />
          ) : (
            gateContentList
          )}
        </div>
        <GatedContentPane
          closePane={() => {
            setSelectedGroup(DEFAULT_GROUP_STATE);
            setShowPane(false);
          }}
          isPaneOpen={showPane}
          contentId={selectedGroup.id}
        />
      </ContentWrapper>
      <OnboardingModal
        setShowModal={setShowOnboardingModal}
        showModal={showOnboardingModal}
        title="Welcome! Get started by adding a member 🎉"
        docsLink="https://help.memberstack.com/hc/en-us/articles/7253251685531-Gated-Content-Page-Overview"
        videoSrc="https://www.loom.com/embed/ed0fce075dc54d368ebb3ac12e4a921e"
      />
    </>
  );
};

const GatedContent = () => (
  <PlansContextProvider>
    <ContentGroupsContextProvider>
      <Component />
    </ContentGroupsContextProvider>
  </PlansContextProvider>
);

export default GatedContent;
