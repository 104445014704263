import React from "react";
import styled, { css } from "styled-components";
import Skeleton from "react-loading-skeleton";

const ChartContainer = styled.div(
  () =>
    ({ theme: { colors } }) =>
      css`
        border-radius: 8px;
        background-color: ${colors.white};
        & > .top_section {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 8px;
        }
        & > .top_section > h4 {
          font-weight: 600;
          font-size: 14px;
          line-height: 18px;
          color: ${colors.grey500};
        }
      `
);

export const LineChartLoading = ({
  className,
  title,
}: {
  className?: string;
  title: string;
}) => (
  <ChartContainer className={className}>
    <div className="top_section">
      <h4>{title}</h4>
      <Skeleton width={67} height={20} />
    </div>
    <div className="top_section">
      <Skeleton width={66} height={36} />
      <Skeleton width={50} height={32} />
    </div>
    <div className="top_section">
      <Skeleton width={67} height={20} />
      <Skeleton width={67} height={20} />
    </div>
    <Skeleton height={150} />
    <div className="flex mt-3 items-center justify-center gap-2">
      <Skeleton width={50} height={16} />
      <Skeleton width={50} height={16} />
    </div>
  </ChartContainer>
);
