import React, { useEffect, useCallback, useMemo, useRef } from "react";
import ReactDOM from "react-dom";
import { TriangleAlert, X } from "lucide-react";
import Text from "components/Text";
import { Button, ButtonStyleTypes } from "../Button/Button";

interface ModalProps {
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  children?: React.ReactNode;
  title: string;
  description?: string;
  descriptionLink?: React.ReactNode;
  showDivider?: boolean;
  width?: string;
  showBottomSection?: boolean;
  isCustom?: boolean;
  showOverlay?: boolean;
  actionButtons?: {
    confirm?: {
      label: string;
      onConfirm: (
        event?:
          | React.MouseEvent<HTMLButtonElement>
          | React.FormEvent<HTMLFormElement>
      ) => void;
      isLoading?: boolean;
      isDisabled?: boolean;
      buttonStyle?: ButtonStyleTypes;
      dataCy?: string;
    };
    cancel?: { label: string; onClick?: () => void };
  };
  hideCancel?: boolean;
  bottomSectionComponent?: React.ReactNode;
  onModalClose?: () => void;
  name?: string;
  removePadding?: boolean;
  nonScrollable?: boolean;
  errorMessage?: React.ReactNode | null;
  onErrorClose?: () => void;
}

export interface ModalTypes {
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
}

export const Modal = ({
  showModal,
  setShowModal,
  children = undefined,
  description,
  descriptionLink,
  title,
  showDivider = true,
  width = "500px",
  actionButtons,
  showBottomSection = true,
  hideCancel = false,
  bottomSectionComponent,
  isCustom,
  onModalClose,
  removePadding = false,
  name = "modal",
  nonScrollable = false,
  errorMessage = null,
  onErrorClose = () => {},
}: ModalProps) => {
  const dialog = useRef<HTMLDialogElement>(null);

  useEffect(() => {
    if (dialog.current) {
      if (showModal && !dialog.current.open) {
        dialog.current.showModal();
      } else if (!showModal && dialog.current.open) {
        dialog.current.close();
        onErrorClose();
      }
    }
  }, [showModal, setShowModal, dialog]);

  useEffect(() => {
    const handleClose = () => {
      if (showModal) setShowModal(false);
    };

    const handleClickOutside = (e) => {
      if (showModal && e.target === dialog.current) setShowModal(false);
    };

    if (dialog.current) {
      dialog.current.addEventListener("close", handleClose);
      dialog.current.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      if (dialog.current) {
        dialog.current.removeEventListener("close", handleClose);
        dialog.current.removeEventListener("mousedown", handleClickOutside);
      }
    };
  }, [showModal, setShowModal]);

  const handleOnClose = useCallback(() => {
    setShowModal(false);
    if (actionButtons?.cancel?.onClick) {
      actionButtons?.cancel?.onClick();
    }
    if (onModalClose) {
      setTimeout(() => {
        onModalClose();
      }, 500);
    }
  }, [setShowModal, actionButtons, onModalClose]);

  const cancelText = useMemo(
    () => actionButtons?.cancel?.label || "Cancel",
    [actionButtons]
  );

  return ReactDOM.createPortal(
    <dialog
      data-cy={`${name}-modal`}
      ref={dialog}
      className="overflow-visible rounded-lg shadow-lg"
      style={{
        width,
      }}
    >
      <div className="relative">
        {isCustom ? (
          <>
            {children}
            {errorMessage && <hr />}
            {errorMessage && (
              <div className="mt-5 px-5">
                <div className="px-4 py-3 w-full flex justify-between items-center bg-app-red050 rounded-md">
                  <div className="flex items-center gap-2">
                    <TriangleAlert className="h-8 w-8 fill-app-red500 text-app-red050" />
                    <Text className="text-app-red500">{errorMessage}</Text>
                  </div>
                  <button
                    type="button"
                    id="toast-alert-close"
                    className="bg-transparent"
                    onClick={onErrorClose}
                  >
                    <X className="text-app-red500" />
                  </button>
                </div>
              </div>
            )}
          </>
        ) : (
          <>
            <div className="flex items-start p-5">
              <div className="hidden sm:block absolute top-0 right-0 pt-5 pr-5">
                {!hideCancel && (
                  <div
                    className="p-1 cursor-pointer"
                    onClick={handleOnClose}
                    aria-hidden
                  >
                    <span className="sr-only">Close</span>
                    <X
                      aria-hidden="true"
                      className="!h-6 !w-6 !text-app-gray500 hover:(text-black)!"
                    />
                  </div>
                )}
              </div>
              <div className="mt-0 text-left">
                <h3 tw="text-h3 font-bold">{title}</h3>
                {description && (
                  <p tw="text-body-sm mt-1">
                    <span
                      // eslint-disable-next-line react/no-danger
                      dangerouslySetInnerHTML={{
                        __html: `${description} &nbsp;`,
                      }}
                    />
                    {descriptionLink}
                  </p>
                )}
              </div>
            </div>
            {showDivider && <hr />}
            {children && (
              <div
                style={{ padding: removePadding ? "0px" : "20px" }}
                className={
                  nonScrollable ? "" : "max-h-[70vh] overflow-y-scroll"
                }
              >
                {children}
              </div>
            )}
            {(showBottomSection || errorMessage) && <hr />}
            {errorMessage && (
              <div className="mt-5 px-5">
                <div className="px-4 py-3 w-full flex justify-between items-center bg-app-red050 rounded-md">
                  <div className="flex items-center gap-2">
                    <TriangleAlert className="h-8 w-8 fill-app-red500 text-app-red050" />
                    <Text className="text-app-red500">{errorMessage}</Text>
                  </div>
                  <button
                    type="button"
                    id="toast-alert-close"
                    className="bg-transparent"
                    onClick={onErrorClose}
                  >
                    <X className="text-app-red500" />
                  </button>
                </div>
              </div>
            )}
            {showBottomSection && (
              <div className="p-5">
                <div
                  className="flex items-center"
                  style={{
                    justifyContent: bottomSectionComponent
                      ? "space-between"
                      : "flex-end",
                  }}
                >
                  {bottomSectionComponent}
                  <div className="flex">
                    {!hideCancel && (
                      <Button
                        text={cancelText}
                        onClick={handleOnClose}
                        buttonStyle="skeleton"
                        tw="mr-2"
                      />
                    )}
                    {actionButtons?.confirm && (
                      <Button
                        type="submit"
                        text={actionButtons.confirm.label}
                        dataCy={actionButtons.confirm.dataCy}
                        onClick={actionButtons.confirm.onConfirm}
                        isLoading={actionButtons.confirm.isLoading}
                        isDisabled={actionButtons.confirm.isDisabled}
                        buttonStyle={actionButtons.confirm.buttonStyle}
                      />
                    )}
                  </div>
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </dialog>,
    document.body
  );
};
