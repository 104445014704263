import React, { lazy, useMemo } from "react";
import { Card, Link, Table } from "components";
import { MapEventToTag } from "containers/EventLog/event-log.util";
import Tag from "components/Tag";
import { TagVariants } from "components/Tag/styles";
import Skeleton from "react-loading-skeleton";
import SuspenseWrapper from "components/SuspenseWrapper/SuspenseWrapper";

const EmptyEventLogIcon = lazy(
  () => import("assets/images/empty_eventlog.svg")
);

export const EventLogTable = ({ eventsLoading, eventsData }) => {
  const loading = eventsLoading;
  const data = eventsData;

  const rows = useMemo(
    () => data?.getMemberEvents?.edges?.slice(0, 5)?.map((edge) => edge.node),
    [data?.getMemberEvents?.edges]
  );

  const table = useMemo(() => {
    if (loading || !data)
      return (
        <Table numberOfData={5}>
          <tbody>
            {[1, 2, 3, 4, 5]?.map((row) => {
              return (
                <tr key={row}>
                  <td>
                    <Skeleton />
                  </td>
                  <td>
                    <Skeleton />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      );

    if (!rows?.length)
      return (
        <div className="flex items-center flex-col justify-center">
          <div>
            <SuspenseWrapper>
              <EmptyEventLogIcon />
            </SuspenseWrapper>
          </div>
          <div tw="flex justify-center flex-col items-center mt-[-32px]">
            <p className="font-bold text-base mb-1">Member Event Log</p>
            <p className="text-body-sm text-app-gray500">
              You will be able to see all Member events here <br />
              e.g. Signups, Logins cancellations and more.
            </p>
          </div>
        </div>
      );

    return (
      <Table numberOfData={rows?.length}>
        <tbody>
          {rows?.map((row) => {
            const { label, icon, variant } = MapEventToTag[row.event];
            return (
              <tr key={row.id}>
                <td>{row.memberAuth?.email || row.id}</td>
                <td>
                  <Tag
                    text={label}
                    icon={icon}
                    variant={variant as TagVariants}
                    isClear
                  />
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    );
  }, [data, loading, rows]);

  return (
    <Card>
      <div className="flex items-center justify-between p-4 border-b">
        <h2 className="font-bold text-base">Event Log</h2>
        {rows?.length > 0 && (
          <Link
            to="../event-log"
            className="text-base"
            showAsLink
            dataCy="event-log"
          >
            View all
          </Link>
        )}
      </div>
      <div>{table}</div>
    </Card>
  );
};
