import cn from "helpers/cn";
import React from "react";

interface DrawerProps {
  isOpen: boolean;
  children?: React.ReactNode;
}

export const Drawer = ({ isOpen, children }: DrawerProps) => {
  return (
    <div
      className={cn(
        "w-0 border-l transition-all z-50 overflow-y-auto overflow-x-visible flex-shrink-0 flex-grow-0",
        {
          "w-[400px]": isOpen,
        }
      )}
    >
      {children}
    </div>
  );
};
