import React, { type ReactNode } from "react";
import Tag from "components/Tag";
import { InfoTooltip } from "components/Tooltip";
import Skeleton from "react-loading-skeleton";
import cn from "helpers/cn";
import { Button, ButtonProps } from "../Button/Button";

export const Card = ({
  children,
  className = "",
  style = {},
  isDisabled,
  hasHoverState,
  type = "default",
  hideBorder = false,
  hideShadow = false,
  onClick,
}: {
  children: ReactNode;
  className?: string;
  style?: object;
  isDisabled?: boolean;
  hasHoverState?: boolean;
  type?: "default" | "primary";
  hideBorder?: boolean;
  hideShadow?: boolean;
  onClick?: () => void;
  selected?: boolean;
}) => (
  <div
    className={cn(
      "rounded-lg w-full cursor-default overflow-auto",
      `${type === "default" ? "border-app-gray100 bg-white" : "bg-app-blue50 border-app-blue100"}`,
      {
        "bg-app-gray50": isDisabled,
        "border-app-gray50": isDisabled,
        "hover:bg-app-gray50": hasHoverState,
        "cursor-pointer": hasHoverState,
        "border": !hideBorder,
        "drop-shadow-sm": !hideShadow,
      },
      className
    )}
    style={style}
    {...(onClick ? { onClick, role: "button" } : {})}
  >
    {children}
  </div>
);

type ButtonType = Pick<ButtonProps, "text" | "onClick" | "leftIcon">;

interface SectionCardProps {
  children: React.ReactNode;
  title?: string;
  buttonValues?: ButtonType;
  workInProgress?: boolean;
  tooltipText?: string;
  footerSection?: React.ReactNode;
  maxHeight?: string;
  loading?: boolean;
}

export const SectionCard = ({
  children,
  title,
  buttonValues = undefined,
  workInProgress = false,
  tooltipText,
  footerSection,
  maxHeight = "auto",
  loading,
}: SectionCardProps) => {
  return (
    <Card className="flex flex-col" style={{ maxHeight }}>
      <div className="flex justify-between items-center border-b px-3 py-4">
        <div className="flex items-center">
          <h4 tw="text-h4 font-bold flex items-center m-0">
            {loading ? <Skeleton width={127} height={28} /> : title}
            {!!tooltipText && (
              <InfoTooltip content={tooltipText} tw="ml-1" size="md" />
            )}
          </h4>
          {workInProgress && (
            <Tag text="Work In Progress" variant="warning" tw="ml-4" />
          )}
        </div>
        {buttonValues && (
          <Button
            text={buttonValues.text}
            onClick={buttonValues.onClick}
            leftIcon={buttonValues.leftIcon}
          />
        )}
      </div>
      <div tw="px-4 py-4 overflow-y-auto">{children}</div>
      {!!footerSection && (
        <div className="border-t px-3 py-4">{footerSection}</div>
      )}
    </Card>
  );
};

export const ContentWrapper = ({
  children,
  className = "",
}: {
  children: ReactNode;
  className?: string;
}) => (
  <div
    className={cn(
      "h-full bg-white border-app-gray100 flex overflow-hidden rounded-l-xl border-t border-b border-l",
      className
    )}
  >
    {children}
  </div>
);

export const ContentCard = ({
  children,
  className = "",
}: {
  children: ReactNode;
  className?: string;
}) => (
  <div
    className={cn(
      "h-full bg-white border border-app-gray100 overflow-hidden rounded-xl",
      className
    )}
  >
    {children}
  </div>
);
