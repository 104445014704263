import * as Types from '../../generatedTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type GetCommentSettingsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetCommentSettingsQuery = (
  { __typename?: 'Query' }
  & { getGlobalCommentSettings?: Types.Maybe<(
    { __typename?: 'GlobalCommentSettings' }
    & Pick<Types.GlobalCommentSettings, 'moderateAllComments' | 'moderateNewMembers' | 'restrictedWords' | 'useDefaultRestrictedWords' | 'aiModerationEnabled' | 'communityDescription' | 'aiSpamThreshold'>
  )> }
);


export const GetCommentSettingsDocument = gql`
    query GetCommentSettings {
  getGlobalCommentSettings {
    moderateAllComments
    moderateNewMembers
    restrictedWords
    useDefaultRestrictedWords
    aiModerationEnabled
    communityDescription
    aiSpamThreshold
  }
}
    `;

/**
 * __useGetCommentSettingsQuery__
 *
 * To run a query within a React component, call `useGetCommentSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCommentSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCommentSettingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCommentSettingsQuery(baseOptions?: Apollo.QueryHookOptions<GetCommentSettingsQuery, GetCommentSettingsQueryVariables>) {
        return Apollo.useQuery<GetCommentSettingsQuery, GetCommentSettingsQueryVariables>(GetCommentSettingsDocument, baseOptions);
      }
export function useGetCommentSettingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCommentSettingsQuery, GetCommentSettingsQueryVariables>) {
          return Apollo.useLazyQuery<GetCommentSettingsQuery, GetCommentSettingsQueryVariables>(GetCommentSettingsDocument, baseOptions);
        }
export type GetCommentSettingsQueryHookResult = ReturnType<typeof useGetCommentSettingsQuery>;
export type GetCommentSettingsLazyQueryHookResult = ReturnType<typeof useGetCommentSettingsLazyQuery>;
export type GetCommentSettingsQueryResult = Apollo.QueryResult<GetCommentSettingsQuery, GetCommentSettingsQueryVariables>;