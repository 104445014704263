import React from "react";
import Skeleton from "react-loading-skeleton";
import { Card } from "components/Card";
import { StatChange } from "./StatChange";

interface Props {
  title?: string;
  value?: number;
  percentChange?: number;
  compared?: string;
  loading?: boolean;
  hasStats?: boolean;
  icon?: React.ReactNode;
}

export const MetricsCard = ({
  title,
  value,
  percentChange,
  compared,
  loading,
  hasStats = true,
  icon,
}: Props) => {
  if (loading) {
    return (
      <Card className="h-[156px]">
        <Skeleton
          className="mb-4"
          width={70}
          height={16}
          containerTestId="loading-title"
        />
        <Skeleton
          width={60}
          height={40}
          tw="mb-[14px]"
          containerTestId="loading-value"
        />
        {hasStats && (
          <div className="flex flex-col">
            <Skeleton width={70} height={16} />
            <Skeleton width={70} height={16} />
          </div>
        )}
      </Card>
    );
  }

  return (
    <Card className="p-4">
      <p className="font-semibold text-[12px] leading-4 mb-4 text-app-gray500 items-center flex gap-1">
        {icon && icon} {title}
      </p>
      <p className="text-[32px] leading-[40px] font-bold mb-4">
        {value.toLocaleString(navigator.language, { maximumFractionDigits: 2 })}
      </p>

      {hasStats && (
        <StatChange
          compared={compared}
          percentChange={percentChange}
          tw="mt-4"
        />
      )}
    </Card>
  );
};
