import React, { useEffect, useState } from "react";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import styled from "styled-components";
import { Button, CopyButton } from "../Button";

export const Container = styled.div<{
  $spaceOutContent: boolean;
  $placeLabelAbove;
}>`
  display: flex;
  gap: 30px;
  flex-direction: ${(props) => (props.$placeLabelAbove ? "column" : "row")};
  justify-content: ${(props) => props.$spaceOutContent && "space-between"};
  align-items: ${(props) => (props.$placeLabelAbove ? "flex-start" : "center")};
`;

interface SensitiveTextProps {
  secretText: string;
  label?: string;
  spaceOutContent?: boolean;
  allowCopy?: boolean;
  placeLabelAbove?: boolean;
}

const SensitiveText = ({
  secretText,
  label,
  spaceOutContent,
  allowCopy,
  placeLabelAbove = false,
}: SensitiveTextProps) => {
  const [hiddenText, setHiddenText] = useState("");
  const [show, setShow] = useState(true);

  const hideText = (key) => {
    if (!key) {
      return "";
    }

    const splitChar = "_";
    const hideChar = "*";
    const split = key.split(splitChar);

    if (split.length === 2) {
      return `${split[0]}_${new Array(split[1].length)
        .fill(hideChar)
        .join("")}`;
    }
    return `${new Array(key.length).fill(hideChar).join("")}`;
  };

  useEffect(() => {
    const hidden = hideText(secretText);
    setHiddenText(hidden);
  }, [secretText]);

  return (
    <Container
      $spaceOutContent={spaceOutContent}
      $placeLabelAbove={placeLabelAbove}
    >
      {label && <p className="min-w-0 whitespace-nowrap">{label}</p>}
      <div className="flex items-center min-w-0 gap-2">
        <p className="min-w-0 whitespace-nowrap" data-testid="visible-text">
          {show ? hiddenText : secretText}
        </p>
        <Button
          onClick={() => setShow(!show)}
          leftIcon={show ? <Visibility /> : <VisibilityOff />}
          buttonStyle="icon"
        />
        {allowCopy && <CopyButton value={secretText} />}
      </div>
    </Container>
  );
};

SensitiveText.defaultProps = {
  spaceOutContent: false,
  allowCopy: true,
  label: undefined,
};

export default SensitiveText;
