import React from "react";
import { useTabsContext } from "./Tabs.context";

interface TabContentProps {
  id: string;
  children: React.ReactNode;
}

const TabContent = ({ id, children }: TabContentProps) => {
  const { activeTab } = useTabsContext();
  return activeTab === id ? <>{children}</> : null;
};

export default TabContent;
