import React, { useState } from "react";
import { TabItem, TabNav, Tabs, TabContent } from "components/Tabs";

import {
  useDeactivatePriceMutation,
  useReactivatePriceMutation,
} from "features";
import useSubmit from "hooks/useSubmit";
import { Plan, Price } from "generatedTypes";
import { EmptyState } from "components";
import { useNavigate } from "react-router-dom";
import { generateTabLabel } from "containers/Plans/utils";
import List from "./List";
import { DeletePriceModal } from "./DeletePriceModal";
import { PlanAccordionItem } from "../PlanAccordionItem";

interface Props {
  refetch: () => void;
  planId: string;
  prices: Price[];
}

const PriceList = ({ refetch, planId, prices }: Props) => {
  const navigate = useNavigate();

  const [price, setPrice] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const [deactivate] = useDeactivatePriceMutation();
  const [reactivate] = useReactivatePriceMutation();

  const { submit: deactivatePrice } = useSubmit({
    action: deactivate,
    refetch,
    success: "Price archived successfully.",
  });

  const { submit: activatePrice } = useSubmit({
    action: reactivate,
    refetch,
    success: "Price activated successfully",
  });

  const handleArchival = ({ active, id }) => {
    if (active) {
      deactivatePrice({ priceId: id });
    } else {
      activatePrice({ priceId: id });
    }
  };

  const handleDelete = (targetPrice) => {
    setPrice(targetPrice);
    setShowModal(true);
  };

  if (!prices?.length) {
    return (
      <div className="mt-4">
        <EmptyState
          text="Create a Pricing Option"
          description="A paid plan can have multiple pricing options. Connect to Stripe to create your first price."
          buttonText="Create Price"
          onCreateClick={() => navigate("create-price")}
          docsLink="https://docs.memberstack.com/hc/en-us/articles/7405693562011"
        />
      </div>
    );
  }

  return (
    <>
      <List
        prices={prices}
        onArchival={handleArchival}
        onDeletion={handleDelete}
      />
      <DeletePriceModal
        showModal={showModal}
        setShowModal={setShowModal}
        price={price}
      />
    </>
  );
};

interface PlanPricesProps {
  refetch: () => void;
  plan: Plan;
}

export const PlanPrices = ({ refetch, plan }: PlanPricesProps) => {
  const navigate = useNavigate();

  const activePrices = plan.prices.filter((p) => p.active);
  const archivedPrices = plan.prices.filter((p) => !p.active);

  return (
    <PlanAccordionItem
      label="Prices"
      value="prices"
      total={plan.prices.length}
      onAdd={() => navigate("create-price")}
      className="z-50 relative"
    >
      <Tabs isExpanded showBottomBorder>
        <TabNav>
          <TabItem
            id="active"
            label={generateTabLabel("Active", activePrices.length)}
          />
          <TabItem
            id="archived"
            label={generateTabLabel("Archived", archivedPrices?.length)}
          />
        </TabNav>
        <TabContent id="active">
          <PriceList refetch={refetch} planId={plan.id} prices={activePrices} />
        </TabContent>
        <TabContent id="archived">
          <PriceList
            refetch={refetch}
            planId={plan.id}
            prices={archivedPrices}
          />
        </TabContent>
      </Tabs>
    </PlanAccordionItem>
  );
};
