import React, { useMemo, useRef, useState } from "react";
import { format, Locale } from "date-fns";
import FocusTrap from "focus-trap-react";
import { DayPicker, DateRange, DayPickerProps } from "react-day-picker";
import { usePopper } from "react-popper";
import { CalendarToday } from "@mui/icons-material";
import Select from "components/Select";
import { MemberMetricsInterval } from "generatedTypes";
import { DropdownOptions } from "../Dropdown/Dropdown";
import * as S from "./DatePicker.styles";
// import "react-day-picker/dist/style.css";
import {
  getDateRange,
  periodIntervalOptions,
  periodOptions,
  PeriodTypes,
} from "./datepicker.utils";

const formatDateRange = ({ dates, dateFormat, locale, interval }) => {
  if (!dates) {
    return "Select a date range";
  }
  const actualDateFormat =
    interval === MemberMetricsInterval.Month ? "MMM ‘yy" : dateFormat;

  const formattedStartDate = format(dates?.from, actualDateFormat, { locale });

  if (!dates.to) {
    return `${formattedStartDate} - `;
  }

  const formattedEndDate = format(dates?.to, actualDateFormat, { locale });
  return `${formattedStartDate} - ${formattedEndDate}`;
};

type Props = DayPickerProps & {
  disabled?: boolean;
  onChange?: (
    dates: DateRange,
    period: PeriodTypes,
    interval: MemberMetricsInterval
  ) => void;
  dateFormat?: string;
  locale?: Locale;
  selected?: DateRange;
  allTimeFromDate?: Date;
  defaultQuickDateRange?: DropdownOptions;
};

export const DatePicker = ({
  disabled,
  onChange,
  dateFormat = "d MMM ‘yy",
  locale,
  selected,
  allTimeFromDate,
  defaultQuickDateRange,
}: Props) => {
  const [range, setRange] = useState<DateRange | undefined>(selected);
  // useEffect(() => {
  //   setRange(selected);
  // }, [selected]);
  const [isPopperOpen, setIsPopperOpen] = useState(false);
  const [interval, setInterval] = useState(MemberMetricsInterval.Day);
  const [selectedPeriod, setSelectedPeriod] = useState(periodOptions[0]);
  const [inputValue, setInputValue] = useState<string>(
    formatDateRange({
      dates: selected,
      dateFormat,
      locale,
      interval,
    })
  );

  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
    null
  );

  const popperRef = useRef<HTMLDivElement>(null);
  const controllerRef = useRef<HTMLDivElement>(null);

  const popper = usePopper(popperRef.current, popperElement, {
    placement: "bottom-start",
  });

  const closePopper = () => {
    setIsPopperOpen(false);
    controllerRef?.current?.focus();
  };

  const onDateSelect = (dates: DateRange) => {
    setRange(dates);
    const dateRange = getDateRange({
      dates,
      interval,
      period: PeriodTypes.CUSTOM,
      allTimeFromDate: new Date(allTimeFromDate || "Jan 1 2021"),
    });
    onChange(dateRange, PeriodTypes.CUSTOM, interval);
    setInputValue(formatDateRange({ dates, dateFormat, locale, interval }));
  };

  const toggleCalendar = () =>
    isPopperOpen ? setIsPopperOpen(false) : setIsPopperOpen(true);

  const handleOnSelect = (
    dates: DateRange,
    period: PeriodTypes,
    value?: MemberMetricsInterval
  ) => {
    // check if interval is valid for the selected period and set it to default if not valid
    const isIntervalValid = periodIntervalOptions[period]?.some(
      (option) => option.value === interval
    );

    const actualInterval = isIntervalValid
      ? interval
      : periodIntervalOptions[period][0].value;

    if (!isIntervalValid) {
      setInterval(actualInterval);
    }

    const dateRange = getDateRange({
      dates,
      interval: value || actualInterval,
      period,
      allTimeFromDate: new Date(allTimeFromDate || "Jan 1 2021"),
    });

    onChange(dateRange, period, value || actualInterval);

    if (dateRange) {
      const formattedDates = formatDateRange({
        dates: dateRange,
        dateFormat,
        locale,
        interval: value || actualInterval,
      });
      setInputValue(formattedDates);
    } else {
      setInputValue("Select a date range");
    }
  };

  const intervalsOption = useMemo(
    () => periodIntervalOptions[selectedPeriod.value],
    [selectedPeriod.value]
  );

  return (
    <S.RootContainer>
      <S.Container $disabled={disabled} className="expand">
        <Select
          options={periodOptions}
          value={selectedPeriod}
          onChange={(option) => {
            setSelectedPeriod(option);
            handleOnSelect(selected, option.value);
          }}
          inline
        />

        <S.Picker
          onClick={toggleCalendar}
          ref={popperRef}
          aria-label="Pick a date"
        >
          <CalendarToday className="calendar" />
          <span ref={controllerRef} tw="truncate max-w-[150px]">
            {inputValue}
          </span>
        </S.Picker>
      </S.Container>

      {isPopperOpen && (
        <FocusTrap
          active
          focusTrapOptions={{
            initialFocus: false,
            allowOutsideClick: true,
            clickOutsideDeactivates: true,
            onDeactivate: closePopper,
            fallbackFocus: controllerRef.current,
          }}
        >
          <div
            tabIndex={-1}
            style={popper.styles.popper}
            className="dialog-sheet z-50"
            {...popper.attributes.popper}
            ref={setPopperElement}
            role="dialog"
          >
            <DayPicker
              initialFocus={isPopperOpen}
              mode="range"
              selected={range}
              defaultMonth={new Date()}
              onDayClick={(day) =>
                setSelectedPeriod({
                  label: "Custom",
                  value: PeriodTypes.CUSTOM,
                })
              }
              // disabled={(day) => day > new Date()}
              onSelect={(dates) => {
                onDateSelect(dates);
              }}
              tw="bg-white border border-gray-300 rounded-md shadow-sm p-2 mx-0 mt-2"
            />
          </div>
        </FocusTrap>
      )}

      <Select
        options={intervalsOption}
        value={intervalsOption.find((option) => option.value === interval)}
        onChange={({ value }) => {
          setInterval(value);
          handleOnSelect(range, selectedPeriod.value, value);
        }}
        isDisabled={selectedPeriod.value === PeriodTypes.CUSTOM}
      />
    </S.RootContainer>
  );
};
