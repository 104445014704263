import React, { Suspense, useEffect } from "react";
import { NavLink, Outlet, useLocation } from "react-router-dom";
import { usePostHog } from "posthog-js/react";
import { ChevronRight } from "@mui/icons-material";
import Loader from "components/Loader";
import { ContentWrapper } from "components";

const Settings = () => {
  const posthog = usePostHog();
  const { pathname } = useLocation();

  const routes = [
    ["Application", "application"],
    ["Install Code", "install-code"],
    ["Notifications", "notifications"],
    ["Team Members", "team"],
    ["Auth Providers", "auth-providers"],
    ["Stripe Settings", "stripe-settings"],
    ["Webflow", "connect-webflow"],
    ["Emails", "emails"],
    ["Translation", "translations"],
    ["Design", "design"],
    ["Billing", "billing"],
    ["Danger Zone", "danger-zone"],
  ];

  useEffect(() => {
    if (posthog) {
      const page = pathname.split("/settings/")[1];
      posthog.capture("pageview", { page: `settings/${page}` });
    }
  }, [pathname, posthog]);

  return (
    <ContentWrapper className="flex-col relative">
      <h3 className="text-h3 font-bold p-5">Global Settings</h3>
      <div className="flex-1 flex border-t overflow-hidden">
        <div className="flex flex-col border-r flex-shrink-0 overflow-y-auto">
          <nav className="flex-1 flex flex-col text-sm p-5 gap-3">
            {routes.map(([name, path]) => (
              <NavLink
                className="text-body-sm flex items-center hover:text-app-gray600 [&.active]:text-app-blue400 [&.active]:font-bold group"
                data-cy={`settings-${name.replace(/\s+/g, "-").toLowerCase()}`}
                to={path}
                key={name}
              >
                {name}
                <ChevronRight className="invisible group-[&.active]:visible" />
              </NavLink>
            ))}
          </nav>
        </div>
        <div className="flex-1 overflow-auto bg-app-background p-7">
          <Suspense fallback={<Loader />}>
            <Outlet />
          </Suspense>
        </div>
      </div>
    </ContentWrapper>
  );
};

export default Settings;
