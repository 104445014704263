import React, { ReactNode, useContext, useEffect, useState } from "react";
import { useLocalStorageEnv } from "hooks/useLocalStorage";
import { useLocation, useNavigate } from "react-router-dom";
import { MemberstackEnv } from "generatedTypes";
import { useApolloContext } from "./ApolloContext";

// const formatLocation = (locationPath: string): string => {
//   const locationParts = ["/pln", "/mem_"];
//   let formattedLocation = locationPath;

//   locationParts.forEach((part) => {
//     if (formattedLocation.includes(part)) {
//       [formattedLocation] = formattedLocation.split(part);
//     }
//   });

//   return formattedLocation;
// };

interface EnvContextType {
  env: MemberstackEnv;
  toggleEnv: () => void;
  switchToSandbox: () => void;
  isSandboxEnv: boolean;
}

const defaultEnvContext: EnvContextType = {
  env: MemberstackEnv.Live,
  toggleEnv: () => {},
  switchToSandbox: () => {},
  isSandboxEnv: false,
};

const EnvContext = React.createContext<EnvContextType>(defaultEnvContext);

const useEnvContext = () => useContext(EnvContext);

const EnvContextProvider = ({ children }: { children: ReactNode }) => {
  const { updateClient } = useApolloContext();

  const { storedEnv, setStoredEnv } = useLocalStorageEnv(
    "_ms-env",
    MemberstackEnv.Live
  );
  const [env, setEnv] = useState(storedEnv);

  const setEnvAndStored = (newEnv: MemberstackEnv) => {
    updateClient(newEnv);
    // This is a hacky way to wait for the apollo client to update before changing the env and triggering useEffects inside of app
    setTimeout(() => {
      setEnv(newEnv);
      setStoredEnv(newEnv);
    }, 0);
  };

  const switchToSandbox = () => {
    if (env !== MemberstackEnv.Sandbox) {
      setEnvAndStored(MemberstackEnv.Sandbox);
    }
  };

  const toggleEnv = () => {
    try {
      const newMode =
        env === MemberstackEnv.Live
          ? MemberstackEnv.Sandbox
          : MemberstackEnv.Live;
      setEnvAndStored(newMode);
    } catch (error) {
      console.error("Failed to toggle environment:", error);
    }
  };

  return (
    <EnvContext.Provider
      value={{
        env,
        toggleEnv,
        switchToSandbox,
        isSandboxEnv: env === MemberstackEnv.Sandbox,
      }}
    >
      {children}
    </EnvContext.Provider>
  );
};

export { useEnvContext, EnvContextProvider };
