import React, { useEffect, lazy } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import SuspenseWrapper from "components/SuspenseWrapper/SuspenseWrapper";
import { testimonials } from "enums/testimonials";
import { useCurrentUserQuery } from "features";

const Logo = lazy(() => import("assets/images/logo-full.svg"));

const AuthLayout = () => {
  const { data } = useCurrentUserQuery();
  const navigate = useNavigate();

  useEffect(() => {
    if (data?.currentUser?.id) {
      const redirectTo = localStorage.getItem("redirectTo");
      if (redirectTo) {
        navigate(redirectTo);
        localStorage.removeItem("redirectTo");
      } else {
        navigate("/apps");
      }
    }
  }, [data, navigate]);

  useEffect(() => {
    // @ts-ignore
    if (window.google) window.google?.accounts?.id?.prompt();
  }, []);

  return (
    <div className="overflow-hidden p-3 flex h-screen max-h-screen bg-gray-50">
      <div className="overflow-x-auto flex flex-col bg-white rounded-l-xl w-[400px] shadow-xl drop-shadow-xl">
        <div className="p-10 pb-8 flex">
          <a href="https://www.memberstack.com/">
            <SuspenseWrapper>
              <Logo />
            </SuspenseWrapper>
          </a>
        </div>
        <div className="px-10 pb-32 flex-1 flex flex-col justify-center">
          <Outlet />
        </div>
      </div>
      <div
        className="overflow-hidden flex-1 border border-app-gray100 rounded-r-xl"
        style={{
          backgroundImage: `radial-gradient(
                at 0% 19%,
                hsla(22, 78%, 92%, 0.4) 0,
                transparent 100%
              ),
              radial-gradient(at 31% 100%, hsla(224, 40%, 49%, 0.4) 0, transparent 57%),
              radial-gradient(at 80% 0%, hsla(256, 20%, 96%, 0.4) 0, transparent 89%),
              radial-gradient(at 90% 87%, hsla(224, 100%, 49%, 0.4) 0, transparent 68%)`,
        }}
      >
        <div className="flex flex-col flex-wrap content-center gap-3 max-h-[2475px] py-3 animate-scroll">
          {testimonials.map((testimony) => (
            <div
              className="p-6 bg-white w-[288px] rounded-xl drop-shadow-lg"
              role="listitem"
              key={testimony.id}
            >
              <img
                className="h-12 w-12 rounded-full"
                src={testimony.image}
                alt={testimony.author}
              />
              <p className="my-2">{testimony.testimony}</p>
              <p>- {testimony.author}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AuthLayout;
