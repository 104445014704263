import { SectionCard } from "components/Card/Card";
import React from "react";
import { AppPortal } from "svix-react";
import { useDevtoolsContext } from "./context";
import { WebhooksSkeleton } from "./DevtoolsSkeleton";
// eslint-disable-next-line import/extensions, import/no-unresolved
import "svix-react/style.css";

const SvixEmbed = () => {
  const { svixUrlData } = useDevtoolsContext();
  return (
    <>
      {svixUrlData && (
        <SectionCard
          title="Webhooks"
          tooltipText="Share data when something happens in Memberstack."
        >
          {svixUrlData && (
            <AppPortal loadingIndicator={false} url={svixUrlData} fullSize />
          )}
        </SectionCard>
      )}

      {!svixUrlData && <WebhooksSkeleton />}
    </>
  );
};

export default SvixEmbed;
