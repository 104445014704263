import React from "react";
import { Modal, ModalTypes } from "components/Modal";
import Skeleton from "react-loading-skeleton";

interface Props extends ModalTypes {
  loading: boolean;
  message: string;
}

const ExportMessageModal = ({
  setShowModal,
  showModal,
  loading,
  message,
}: Props) => (
  <Modal
    setShowModal={setShowModal}
    showModal={showModal}
    title="Your export is on the way!"
    description={message}
    width="480px"
    showDivider={false}
    showBottomSection={false}
  >
    {loading && (
      <div className="flex flex-col gap-1">
        <Skeleton height={16} />
        <Skeleton height={16} />
      </div>
    )}
  </Modal>
);

export default ExportMessageModal;
