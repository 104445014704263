export function convertString(str) {
  // Convert string to lowercase
  let convertedStr = str.toLowerCase();

  // Capitalize the first letter
  convertedStr = convertedStr.charAt(0).toUpperCase() + convertedStr.slice(1);

  // Replace all underscores with spaces
  convertedStr = convertedStr.replace(/_/g, " ");

  return convertedStr;
}
