import { Button } from "components";
import React from "react";
import Skeleton from "react-loading-skeleton";

const SettingsLoader = () => (
  <div className="w-full flex flex-col overflow-auto">
    <div className="flex justify-between items-center p-5">
      <h4 className="text-base">General Settings</h4>
      <Button text="Save" isDisabled />
    </div>
    <div className="overflow-auto">
      <div className="p-7 bg-gray-50 flex flex-col gap-4 border-b">
        <Skeleton height={144} />
        <Skeleton height={358} />
        <Skeleton height={144} />
      </div>
    </div>
  </div>
);

export default SettingsLoader;
