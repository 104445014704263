import React, { useMemo } from "react";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import mergeClassNames from "helpers/mergeClassNames/mergeClassNames";
import cn from "helpers/cn";
import { CheckboxProps } from "./Checkbox.types";

export const Checkbox = ({
  className,
  checked,
  disabled,
  label,
  onChange,
  readOnly,
  required,
  value,
  id,
  name,
  description,
  type = "regular",
}: CheckboxProps) => {
  const visibilityCheckbox = useMemo(
    () => (
      <label
        className={cn(
          "flex switch items-center text-body-sm cursor-pointer relative",
          { "text-app-gray500": checked }
        )}
      >
        <input
          type="checkbox"
          onClick={(e) => e.stopPropagation()}
          onChange={onChange}
          checked={checked}
          disabled={disabled}
          id={id}
          readOnly={readOnly}
          required={required}
          name={name}
          value={value}
          className="opacity-0 w-0 h-0"
        />
        {checked ? (
          <VisibilityOff className="mr-2 w-4 h-4" />
        ) : (
          <Visibility className="mr-2 w-4 h-4" />
        )}
        {label || label}
      </label>
    ),
    [checked, disabled, id, label, name, onChange, readOnly, required, value]
  );

  const regularCheckbox = useMemo(
    () => (
      <>
        <input
          checked={checked}
          className={cn(
            "form-checkbox w-4 h-4 cursor-pointer text-app-gray500 rounded",
            { "mt-1": Boolean(description) }
          )}
          disabled={disabled}
          id={id}
          onChange={onChange}
          readOnly={readOnly}
          required={required}
          type="checkbox"
          name={name}
          value={value}
        />
        {label && (
          <label className="pl-2 cursor-pointer" htmlFor={id}>
            <span
              className={mergeClassNames(
                "block",
                description ? "text-base" : "text-body-sm"
              )}
            >
              {label}
            </span>
            <p className="text-body-sm">{description}</p>
          </label>
        )}
      </>
    ),
    [
      checked,
      disabled,
      id,
      label,
      name,
      onChange,
      readOnly,
      required,
      value,
      description,
    ]
  );

  const renderCheckbox = useMemo(() => {
    switch (type) {
      case "regular":
        return regularCheckbox;
      default:
        return visibilityCheckbox;
    }
  }, [regularCheckbox, type, visibilityCheckbox]);

  return (
    <div
      className={cn(
        "flex cursor-pointer",
        `${description ? "items-start" : "items-center"}`,
        className
      )}
    >
      {renderCheckbox}
    </div>
  );
};
