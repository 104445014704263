import React, { useContext, useMemo, useRef, useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import {
  Home,
  Users,
  GalleryHorizontal,
  Folder,
  LayoutGrid,
  MessageSquare,
  List,
  CodeXml,
  Settings,
  ArrowDown,
} from "lucide-react";
import useTranslations from "hooks/useTranslations";
import cn from "helpers/cn";
import { SidebarContext } from "../context/sidebar.context";
import { NavContainer } from "../styles";

const NavItems = () => {
  const { isExpanded } = useContext(SidebarContext);
  const { translate } = useTranslations();
  const [isScrollDownVisible, setIsScrollDownVisible] = useState(false);
  const itemsContainerRef = useRef<HTMLDivElement>(null);
  const mainContainerRef = useRef<HTMLDivElement>(null);
  const items = useMemo(
    () => [
      {
        label: "Dashboard",
        icon: Home,
        link: "dashboard",
      },
      {
        label: translate("sideBar.members", "Members"),
        icon: Users,
        link: "members",
      },
      {
        label: translate("sideBar.plans", "Plans"),
        icon: GalleryHorizontal,
        link: "plans/redirects",
      },
      {
        label: "Gated Content",
        icon: Folder,
        link: "gated-content",
      },
      {
        label: "Components",
        icon: LayoutGrid,
        link: "editor",
      },
      {
        label: "Community",
        icon: MessageSquare,
        link: "community/comments/ALL",
      },
      {
        label: "Event Log",
        icon: List,
        link: "event-log",
      },
      {
        label: translate("sideBar.devTools", "Dev Tools"),
        icon: CodeXml,
        link: "devtools",
      },
      {
        label: translate("sideBar.settings", "Settings"),
        icon: Settings,
        link: "settings/application",
      },
    ],
    [translate]
  );

  const checkScroll = () => {
    if (
      itemsContainerRef.current?.scrollHeight >
        mainContainerRef.current?.clientHeight &&
      itemsContainerRef.current?.scrollTop +
        mainContainerRef.current?.clientHeight <
        itemsContainerRef.current?.scrollHeight
    ) {
      setIsScrollDownVisible(true);
    } else {
      setIsScrollDownVisible(false);
    }
  };
  useEffect(() => {
    checkScroll();
    itemsContainerRef.current?.addEventListener("scroll", checkScroll);
    window.addEventListener("resize", checkScroll);
    return () => {
      itemsContainerRef.current?.removeEventListener("scroll", checkScroll);
      window.removeEventListener("resize", checkScroll);
    };
  }, []);
  return (
    <div
      className="px-3 relative overflow-hidden"
      aria-label="sidebar"
      data-cy="sidebar-nav"
      ref={mainContainerRef}
    >
      <div
        className="flex flex-col gap-1 overflow-auto pt-5 pb-10 h-full"
        ref={itemsContainerRef}
      >
        {items.map((item) => {
          return (
            <NavContainer
              key={item.label}
              isExpanded={isExpanded}
              label={item.label}
            >
              <NavLink
                data-cy={`nav-${item.label.replace(/\s+/g, "-").toLowerCase()}`}
                key={item.label}
                to={item.link}
                className={({ isActive }) =>
                  `flex items-center p-2 text-body-sm rounded-lg whitespace-nowrap group hover:text-black hover:bg-app-gray50 focus:outline-none focus:text-black  group-[.active]:font-bold font-normal  ${isExpanded ? "justify-start" : "justify-center"} ${isActive ? "active bg-app-blue50 focus:bg-app-blue50 hover:bg-app-blue50 font-bold text-black" : ""}`
                }
              >
                <div className="flex justify-center p-1 rounded-sm group-[.active]:bg-white group-[.active]:shadow-sm group-[.justify-start]:mr-2">
                  <item.icon
                    aria-hidden="true"
                    className="h-5 w-5 flex-shrink-0 text-app-gray600 group-hover:text-black group-focus:text-black group-[.active]:text-app-blue400"
                  />
                </div>
                {isExpanded && item.label}
              </NavLink>
            </NavContainer>
          );
        })}
      </div>
      <button
        type="button"
        className={cn(
          "absolute bottom-2 rounded-full bg-white border shadow-sm h-6 w-6 text-center left-1/2 -translate-x-1/2 hidden",
          {
            "block": isScrollDownVisible,
          }
        )}
        onClick={() => {
          itemsContainerRef.current?.scrollTo({
            top: itemsContainerRef.current.scrollHeight,
            behavior: "smooth",
          });
        }}
      >
        <ArrowDown className="text-black inline-block w-3 h-3" />
      </button>
    </div>
  );
};

export default NavItems;
