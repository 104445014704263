import { ApolloQueryResult } from "@apollo/client";
import {
  MemberAuth,
  Member,
  PlanConnection,
  MemberNote,
  MemberTrustStatus,
} from "generatedTypes";
import React from "react";

interface SaveActionsT {
  customFields: Member["customFields"];
  loginRedirectInput: string;
  email: MemberAuth["email"];
  note: MemberNote["note"];
  trustStatus: MemberTrustStatus;
  isModerator: boolean;
}

interface DefaultContext {
  createdAt: string;
  metaData: Member["metaData"];
  loginRedirect: string;
  json: Member["json"];
  memberEmail: MemberAuth["email"];
  connectedProviders: MemberAuth["connectedProviders"];
  memberId: Member["id"];
  memberPlanConnections: PlanConnection[];
  memberCustomFields: Member["customFields"];
  memberPermissions: Member["permissions"];
  stripeCustomerId: Member["stripeCustomerId"];
  memberRefetch: (
    variables?: Partial<Record<string, unknown>>
  ) => Promise<ApolloQueryResult<unknown>>;
  setIsDisabled: React.Dispatch<React.SetStateAction<boolean>>;
  verified: boolean;
  memberNotes: MemberNote;
  showNotes: boolean;
  profileImage: string;
  showMetadataModal: boolean;
  setShowMetadataModal: React.Dispatch<React.SetStateAction<boolean>>;
  showJSONModal: boolean;
  setShowJSONModal: React.Dispatch<React.SetStateAction<boolean>>;
  saveActions: SaveActionsT;
  showAddCFModal: boolean;
  setShowAddCFModal: React.Dispatch<React.SetStateAction<boolean>>;
  isModerator: boolean;
  trustStatus: MemberTrustStatus;
}

const defaultContext: DefaultContext = {
  createdAt: undefined,
  metaData: undefined,
  loginRedirect: undefined,
  json: undefined,
  memberEmail: undefined,
  connectedProviders: undefined,
  memberId: undefined,
  memberPlanConnections: undefined,
  memberCustomFields: undefined,
  memberPermissions: undefined,
  memberRefetch: undefined,
  stripeCustomerId: undefined,
  setIsDisabled: undefined,
  verified: undefined,
  memberNotes: undefined,
  showNotes: undefined,
  profileImage: undefined,
  showMetadataModal: false,
  setShowMetadataModal: undefined,
  showJSONModal: false,
  setShowJSONModal: undefined,
  showAddCFModal: false,
  setShowAddCFModal: undefined,
  isModerator: false,
  trustStatus: MemberTrustStatus.Default,
  saveActions: {
    customFields: undefined,
    loginRedirectInput: undefined,
    email: undefined,
    note: undefined,
    trustStatus: MemberTrustStatus.Default,
    isModerator: false,
  },
};

const MemberContext = React.createContext(defaultContext);

const useMemberContext = () => {
  return React.useContext(MemberContext);
};

export { useMemberContext, MemberContext };
