import React from "react";

export const UiComponents = () => {
  return (
    <iframe
      src="https://www.memberstack.com/webflow-components/commenting"
      title="component page"
      width="100%"
      height="100%"
      // className="rounded-none rounded-tl-xl rounded-bl-xl"
    />
  );
};
