import React from "react";
import SensitiveText from "components/SensitiveText";
import { Button } from "components";
import useTranslations from "hooks/useTranslations";

interface GeneratedPasswordProps {
  generatedPassword: string;
  closeModal: () => void;
}

const GeneratedPassword = ({
  generatedPassword,
  closeModal,
}: GeneratedPasswordProps) => {
  const { translate } = useTranslations();

  return (
    <>
      <div className="p-5">
        <SensitiveText
          secretText={generatedPassword}
          allowCopy
          placeLabelAbove
          label={translate("generatePassword.password", "Password")}
        />
      </div>
      <hr />
      <div className="flex justify-end p-5">
        <Button
          text={translate("generatePassword.done", "Done")}
          onClick={closeModal}
          buttonStyle="skeleton"
        />
      </div>
    </>
  );
};

export default GeneratedPassword;
