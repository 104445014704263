import React, { useState } from "react";
import { Button } from "components";
import useTranslations from "hooks/useTranslations";
import { RadioButtonGroup } from "components/RadioButton";

interface Props {
  closeModal: () => void;
  setSelectedOption: React.Dispatch<(prevState: undefined) => undefined>;
  handleReset: () => void | Promise<void>;
  isSubmitting: boolean;
}
const ResetPasswordOptions = ({
  closeModal,
  setSelectedOption,
  handleReset,
  isSubmitting,
}: Props) => {
  const [enableButton, setEnableButton] = useState(false);
  const { translate } = useTranslations();
  const handleOnChange = (value) => {
    setEnableButton(true);
    setSelectedOption(value);
  };

  const resetPasswordOptions = [
    {
      label: translate(
        "resetPasswordOptions.automatic",
        "Automatically generate a password"
      ),
      value: "generatePassword",
      description: translate(
        "resetPasswordOptions.automaticDesc",
        "You will be able to view and copy the password in the next step"
      ),
    },
    {
      label: translate("resetPasswordOptions.manual", "Create password"),
      value: "createPassword",
      description: translate(
        "resetPasswordOptions.manualDesc",
        "You will be required to manually create a password"
      ),
    },
  ];
  return (
    <>
      <div className="p-5">
        <RadioButtonGroup
          label={translate(
            "resetPasswordOptions.label",
            "Reset Password Option"
          )}
          options={resetPasswordOptions}
          name="resetPasswordOption"
          onChange={handleOnChange}
        />
      </div>
      <hr />
      <div className="flex justify-end p-5">
        <Button
          text={translate("resetPasswordOptions.cancel", "Cancel")}
          buttonStyle="skeleton"
          onClick={closeModal}
          tw="mr-2"
        />
        <Button
          text={translate("resetPasswordOptions.reset", "Reset")}
          isDisabled={!enableButton}
          isLoading={isSubmitting}
          type="submit"
          onClick={handleReset}
        />
      </div>
    </>
  );
};

export default ResetPasswordOptions;
