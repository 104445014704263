import React from "react";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import Input from "components/Input";

interface Props {
  formInputs: { [key: string]: string };
  brandColor: string;
  appImage: string;
}

export const ResetPasswordRqUI = ({
  formInputs,
  brandColor,
  appImage,
}: Props) => (
  <div tw="w-[360px] rounded-[20px] rounded-3xl shadow-lg bg-white">
    <div className="p-7">
      <div tw="pb-[30px] flex justify-center">
        <img src={appImage} height={43} tw="h-[43px]" alt="app-logo" />
      </div>

      <h2 tw="text-h2 text-center font-bold mb-2">
        {formInputs.reset_your_password || "Reset your password"}
      </h2>
      <p className="mb-7 text-base text-center">
        {formInputs.reset_instructions ||
          "We’ll email you a secure link to reset the password for your account."}
      </p>
      <Input
        label={formInputs.email_address || "Email Address"}
        placeholder={
          formInputs.reset_email_placeholder || "Enter your email address"
        }
        className="mb-5"
        hideIndicators
        disabled
      />

      <button
        className="mb-5 w-full font-bold text-white p-3 rounded-lg text-base"
        type="button"
        style={{ backgroundColor: brandColor || "#2962FF" }}
      >
        {formInputs.reset || "Send link"}
      </button>
      <p className="text-body-sm text-center mb-5">
        {formInputs.reset_already_have_code || "I already have a code"}
      </p>
    </div>
  </div>
);
