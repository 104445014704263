import React, { useEffect, useMemo, useState } from "react";
import { DragHandle, ArrowUpward } from "@mui/icons-material";
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from "react-beautiful-dnd";
import { Form, EmptyState } from "components";
import { useToastAlertContext } from "contexts/ToastAlertContext";
import { Modal, ModalTypes } from "components/Modal";
import { usePlansContext } from "containers/Plans/context/Plans.context";
import arraysAreEqual from "helpers/arraysAreEqual";
import { useOrderPlansMutation } from "../../../features/plans/mutations.generated";

interface Props extends ModalTypes {}

const PlanPriorityModal = ({ setShowModal, showModal }: Props) => {
  const { plans, refetchPlans } = usePlansContext();
  const { createToastAlert } = useToastAlertContext();
  const [plansOrder, setPlansOrder] = useState([]);
  const [modalError, setModalError] = useState(null);

  const [order, { loading }] = useOrderPlansMutation({
    variables: {
      input: {
        orders: plansOrder.map((po, idx) => ({
          planId: po.id,
          priority: idx,
        })),
      },
    },
    onCompleted: () => {
      createToastAlert({
        alertType: "success",
        message: "Plan Priority was successfully updated",
      });
      refetchPlans();
      setShowModal(false);
    },
    onError: (e) => {
      setModalError(e);
    },
  });

  const hasOrderChanged = (a, b) => !arraysAreEqual(a, b, "id");

  useEffect(() => {
    const newPlansOrder = plans
      .filter((p) => p.status === "ACTIVE")
      .sort((a, b) => a.priority - b.priority)
      .map((p) => ({
        id: p.id,
        content: p.name,
      }));
    if (hasOrderChanged(newPlansOrder, plansOrder))
      setPlansOrder(newPlansOrder);
  }, [plans]);

  const handleSubmit = async () => {
    await order();
  };

  const handleDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    setPlansOrder((prevPlansOrder) => {
      const updatedPlansOrder = Array.from(prevPlansOrder);
      const [removed] = updatedPlansOrder.splice(result.source.index, 1);
      updatedPlansOrder.splice(result.destination.index, 0, removed);
      return updatedPlansOrder;
    });
  };

  return (
    <Modal
      setShowModal={setShowModal}
      showModal={showModal}
      title="Plan Priority"
      description="Prioritize plans below to let Memberstack know which plan to default to in case of any scenarios where plan settings conflict."
      showDivider
      width="430px"
      actionButtons={{
        confirm: {
          label: "Save",
          onConfirm: handleSubmit,
          isLoading: loading,
          isDisabled: !hasOrderChanged,
        },
      }}
      errorMessage={modalError}
      onErrorClose={() => setModalError(null)}
    >
      <Form onSubmit={handleSubmit}>
        <div className="flex items-center justify-between mb-4">
          <hr className="w-full" />
          <div className="flex items-center mx-3">
            <ArrowUpward className="w-6! text-app-gray400! mr-1.5" />
            <h4 className="text-h4 text-app-gray600 whitespace-nowrap">
              Top Priority
            </h4>
          </div>
          <hr className="w-full" />
        </div>
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId="droppable" direction="vertical">
            {(droppableProvided) => (
              <div
                ref={droppableProvided.innerRef}
                {...droppableProvided.droppableProps}
              >
                {plansOrder.length > 0 ? (
                  plansOrder.map(({ id, content }, index) => (
                    <Draggable key={id} draggableId={id} index={index}>
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          className="cursor-pointer mb-2 p-1 flex items-center gap-2 rounded-lg w-full border border-app-gray100 bg-white shadow-sm !top-auto !left-auto"
                        >
                          <DragHandle />
                          <div className="text-base select-none truncate">
                            {content}
                          </div>
                        </div>
                      )}
                    </Draggable>
                  ))
                ) : (
                  <EmptyState text="No plans available" />
                )}
                {droppableProvided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </Form>
    </Modal>
  );
};

export default PlanPriorityModal;
