import React, { useState } from "react";
import { Switch, Button } from "components";
import Input from "components/Input";
import { Plan } from "generatedTypes";
import useSubmit from "hooks/useSubmit";
import { useUpdatePlanMutation } from "features/plans";

interface Props {
  plan: Plan;
  refetch: () => void;
}

export const PlanSignupMemberLimit = ({ plan, refetch }: Props) => {
  const { limitMembers, memberLimit } = plan;

  const [form, setForm] = useState({
    limitMembers,
    memberLimit: memberLimit || 0,
  });

  const [updatePlan, { loading: updatingPlan }] = useUpdatePlanMutation();

  const isDirty =
    form.limitMembers !== limitMembers || form.memberLimit !== memberLimit;

  const onToggleMemberLimit = () => {
    setForm({
      ...form,
      limitMembers: !form.limitMembers,
    });
  };

  const onMemberLimitChange = (e) => {
    const { value } = e.target;
    setForm({
      ...form,
      memberLimit: Number(value),
    });
  };

  const { submit: handleUpdateLimitSettings } = useSubmit({
    action: updatePlan,
    fields: {
      planId: plan?.id,
      ...form,
    },
    success: "Member limit settings were successfully updated.",
    refetch,
  });

  return (
    <div className="flex flex-col gap-2 mt-2">
      <div className="bottom-0 rounded p-3 bg-app-blue50 text-base">
        <div className="flex justify-between gap-2">
          <Switch
            label="Limit number of members"
            id="limitMembers"
            disabled={false}
            isChecked={form.limitMembers}
            onChange={onToggleMemberLimit}
          />
          <Button
            buttonStyle="default"
            text="Save"
            onClick={() => handleUpdateLimitSettings()}
            isLoading={updatingPlan}
            isDisabled={!isDirty}
          />
        </div>
        <div className="flex gap-2 mt-4">
          <Input
            tw="flex-1"
            type="number"
            label="Maximum number of members"
            name="maxMembers"
            value={form.memberLimit}
            onChange={onMemberLimitChange}
            min="0"
            hideIndicators
            disabled={!form.limitMembers}
          />
          <Input
            tw="flex-1"
            type="text"
            label="Total members"
            name="totalMembers"
            value={plan?.memberCount?.toString()}
            hideIndicators
            disabled
          />
        </div>
      </div>
    </div>
  );
};
