import React from "react";
import { ApolloError, ApolloQueryResult } from "@apollo/client";
import { CustomField } from "generatedTypes";
import { useGetCustomFieldsQuery } from "../queries.generated";

interface ContextValueT {
  error: ApolloError;
  customFields: Omit<CustomField, "plans">[];
  refetchCustomFields: (
    variables?: Partial<Record<string, unknown>>
  ) => Promise<ApolloQueryResult<unknown>>;
  loadingCustomFields: boolean;
}

const defaultPlansValues: ContextValueT = {
  customFields: undefined,
  loadingCustomFields: undefined,
  error: undefined,
  refetchCustomFields: undefined,
};

const CustomFieldContext = React.createContext(defaultPlansValues);

const useCustomFieldContext = () => React.useContext(CustomFieldContext);

const CustomFieldContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { loading, data, refetch, error } = useGetCustomFieldsQuery();

  return (
    <CustomFieldContext.Provider
      value={{
        customFields: data?.getCustomFields,
        loadingCustomFields: loading,
        error,
        refetchCustomFields: refetch,
      }}
    >
      {children}
    </CustomFieldContext.Provider>
  );
};

export { useCustomFieldContext, CustomFieldContextProvider };
