import React from "react";
import { Card, EnvSwitcher, Table } from "components";
import SearchBar from "components/SearchBar";
import Skeleton from "react-loading-skeleton";

const EventLogSkeleton = () => (
  <>
    <Card className="h-full">
      <div className="p-5">
        <div className="flex items-center justify-between mb-4">
          <h3 className="text-h3 font-bold flex items-center">Event Log</h3>
          <EnvSwitcher />
        </div>
        <SearchBar
          value=""
          onChange={() => undefined}
          placeholder="Search by id"
          className="w-full"
        />
      </div>
      <Table isHeadersInteractive={false} numberOfData={9}>
        <thead>
          <tr>
            <th>Event</th>
            <th>Event ID</th>
            <th>Date</th>
          </tr>
        </thead>
        <tbody />
        {[1, 2, 3, 4, 5]?.map((row, i) => {
          return (
            <tr key={row}>
              <td>
                <Skeleton height={34} />
              </td>
              <td>
                <Skeleton height={34} />
              </td>
              <td>
                <Skeleton height={34} />
              </td>
              <td>
                <Skeleton height={34} />
              </td>
            </tr>
          );
        })}
      </Table>
    </Card>
  </>
);

export default EventLogSkeleton;
