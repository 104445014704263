import React, { useMemo } from "react";
import { Permission } from "generatedTypes";
import Text from "components/Text";
import { InfoTooltip } from "components/Tooltip";
import { Switch } from "components/Switch";
import CreateIcon from "@mui/icons-material/Create";
import { PlansContainer, StyledCard } from "./styles";

type PermissionCardProp = {
  className?: string;
  hideToggle?: boolean;
  isDisabled?: boolean;
  isEditable?: boolean;
  onTogglePermission?: (checked: boolean) => void | Promise<void>;
  parentPlans?: { id: string; name: string }[];
  permission?: Permission;
  permissionName?: string;
  checked?: boolean;
  onClick?: () => void;
};

const PermissionCard = ({
  className,
  permission,
  isEditable = false,
  hideToggle = false,
  isDisabled = false,
  parentPlans = [],
  permissionName,
  checked = false,
  onTogglePermission,
  onClick,
}: PermissionCardProp) => {
  const renderToolTipContent = useMemo(() => {
    return (
      <PlansContainer>
        {parentPlans.map((plan) => (
          <p tw="truncate max-w-[160px]" key={plan.id}>
            {plan.name}
          </p>
        ))}
      </PlansContainer>
    );
  }, [parentPlans]);

  return (
    <StyledCard
      isDisabled={isDisabled}
      hasHoverState
      onClick={() => onClick && onClick()}
      className={className}
      data-testid="style-card-container"
    >
      {hideToggle ? (
        <div className="flex items-center">
          <p className="mr-1 text-base">{permission?.name || permissionName}</p>
          {parentPlans.length > 0 && (
            <InfoTooltip
              size="md"
              variant="outline"
              content={renderToolTipContent}
            />
          )}
        </div>
      ) : (
        <div className="flex justify-between items-center w-full">
          <Switch
            label={permission.name}
            id={permission.id}
            disabled={isDisabled}
            isChecked={checked}
            onChange={onTogglePermission}
          />
          {isEditable && (
            <CreateIcon fill="black" className="hoverState" tw="!h-4 !w-4" />
          )}
        </div>
      )}
      {isEditable && permission?.description && (
        <Text className="text-app-gray500 mt-2">{permission?.description}</Text>
      )}
    </StyledCard>
  );
};

export default PermissionCard;
