import React from "react";
import { SectionCard } from "components/Card/Card";
import { CopyButton } from "components";

interface Props {
  appId: string;
  isSandboxEnv: boolean;
}

const ApplicationId = ({ appId, isSandboxEnv }: Props) => (
  <SectionCard
    title={isSandboxEnv ? "Test Mode Application ID" : "Application ID"}
  >
    <div className="flex justify-between items-center">
      <span>App ID</span>
      <div className="flex items-center min-w-0">
        <span className="mr-3.5 whitespace-nowrap">{appId}</span>
        <CopyButton value={appId} />
      </div>
    </div>
  </SectionCard>
);

export default ApplicationId;
