import React, { useState, useEffect, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useEnvContext } from "contexts/EnvironmentContext";
import {
  CustomFieldContextProvider,
  useCustomFieldContext,
} from "features/custom-fields";
import { MemberstackEnv } from "generatedTypes";
import { useGetMembersCountQuery } from "features/members";
import { Card, ContentCard } from "components";
import {
  PlansContextProvider,
  usePlansContext,
} from "containers/Plans/context/Plans.context";

import MemberPane from "./MemberPane";
import MembersTableLoading from "./MembersView/MembersTable/MembersTable.loading";
import MembersView from "./MembersView/MembersView";
import {
  useMembersContext,
  MembersContextProvider,
} from "./context/MembersContextProvider";

const Wrapper = () => {
  const [activeMemberId, setActiveMemberId] = useState(null);
  const { env } = useEnvContext();
  const navigate = useNavigate();
  const { memberId } = useParams<{ memberId: string }>();
  const { loadingMembers, membersError, refetch } = useMembersContext();
  const { loadingPlans, error: plansError } = usePlansContext();
  const {
    loadingCustomFields,
    error: cfError,
    refetchCustomFields,
  } = useCustomFieldContext();
  const { data, refetch: refetchMemberCount } = useGetMembersCountQuery();

  useEffect(() => setActiveMemberId(memberId), [memberId]);

  const totalMembers = data?.getMembersCount;

  const previousEnv = useRef(env);

  useEffect(() => {
    if (env !== previousEnv.current) {
      refetch();
      refetchCustomFields();
      refetchMemberCount();
    }
    previousEnv.current = env;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetch, refetchCustomFields, refetchMemberCount, env]);

  if (membersError || plansError || cfError)
    return <p>Something bad happened.</p>;

  return loadingMembers || loadingPlans || loadingCustomFields ? (
    <Card>
      <MembersTableLoading />
    </Card>
  ) : (
    <div className="flex h-full overflow-hidden">
      <ContentCard className="mr-4 w-full">
        <MembersView
          activeMemberId={activeMemberId}
          totalMembers={totalMembers}
        />
      </ContentCard>
      {memberId && (
        <MemberPane
          isOpen={Boolean(activeMemberId)}
          closeDialog={() => {
            navigate("./");
          }}
          isSandBoxMode={env === MemberstackEnv.Sandbox}
        />
      )}
    </div>
  );
};

const Members = () => {
  return (
    <MembersContextProvider>
      <PlansContextProvider>
        <CustomFieldContextProvider>
          <Wrapper />
        </CustomFieldContextProvider>
      </PlansContextProvider>
    </MembersContextProvider>
  );
};

export default Members;
