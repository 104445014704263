import React, { useState } from "react";
import { Modal } from "components/Modal";
import { Link, Banner, Checkbox } from "components";
import { Warning } from "@mui/icons-material";

export const AddTaxModal = ({
  showModal,
  setShowModal,
  onConfirm,
  isLoading,
}) => {
  const [checked, setChecked] = useState(false);
  return (
    <Modal
      setShowModal={setShowModal}
      showModal={showModal}
      width="441px"
      title="Add taxes"
      showDivider
      actionButtons={{
        cancel: {
          label: "Cancel",
        },
        confirm: {
          label: "Add taxes",
          onConfirm: () => onConfirm(),
          isLoading,
          isDisabled: !checked,
        },
      }}
    >
      <Banner
        text="You're almost done!"
        leftPlugin={<Warning className="warning h-3 w-3 fill-[#E28C0A]" />}
        className="py-8"
      />
      <ol className=" text-body-sm my-4 list-decimal ml-5">
        <li>
          Taxes will not work until you&#39;ve configured your{" "}
          <Link
            to="https://stripe.com/docs/invoicing/taxes#enable"
            isExternal
            underline
            target="_blank"
          >
            Stripe Tax settings
          </Link>{" "}
          in your Stripe account.
        </li>
        <li>
          Apple Pay and Google Pay will not work until you&#39;ve enbaled{" "}
          <Link
            to="https://stripe.com/docs/payments/checkout/taxes?tax-calculation=stripe-tax"
            isExternal
            underline
            target="_blank"
          >
            billing address collection
          </Link>{" "}
          in Memberstack.
        </li>
        <li>Once saved, you cannot remove or edit the tax amount.</li>
      </ol>
      <div>
        <Checkbox
          label="I understand and would like to proceed"
          onChange={() => setChecked(!checked)}
          checked={checked}
          id="confirm-checkbox"
        />
      </div>
    </Modal>
  );
};
