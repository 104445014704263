import * as Types from '../../generatedTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type UpdateGlobalCommentSettingsMutationVariables = Types.Exact<{
  input: Types.UpdateGlobalCommentSettingsInput;
}>;


export type UpdateGlobalCommentSettingsMutation = (
  { __typename?: 'Mutation' }
  & { updateGlobalCommentSettings: (
    { __typename?: 'GlobalCommentSettings' }
    & Pick<Types.GlobalCommentSettings, 'moderateAllComments' | 'moderateNewMembers' | 'restrictedWords' | 'useDefaultRestrictedWords'>
  ) }
);


export const UpdateGlobalCommentSettingsDocument = gql`
    mutation UpdateGlobalCommentSettings($input: UpdateGlobalCommentSettingsInput!) {
  updateGlobalCommentSettings(input: $input) {
    moderateAllComments
    moderateNewMembers
    restrictedWords
    useDefaultRestrictedWords
  }
}
    `;
export type UpdateGlobalCommentSettingsMutationFn = Apollo.MutationFunction<UpdateGlobalCommentSettingsMutation, UpdateGlobalCommentSettingsMutationVariables>;

/**
 * __useUpdateGlobalCommentSettingsMutation__
 *
 * To run a mutation, you first call `useUpdateGlobalCommentSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateGlobalCommentSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateGlobalCommentSettingsMutation, { data, loading, error }] = useUpdateGlobalCommentSettingsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateGlobalCommentSettingsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateGlobalCommentSettingsMutation, UpdateGlobalCommentSettingsMutationVariables>) {
        return Apollo.useMutation<UpdateGlobalCommentSettingsMutation, UpdateGlobalCommentSettingsMutationVariables>(UpdateGlobalCommentSettingsDocument, baseOptions);
      }
export type UpdateGlobalCommentSettingsMutationHookResult = ReturnType<typeof useUpdateGlobalCommentSettingsMutation>;
export type UpdateGlobalCommentSettingsMutationResult = Apollo.MutationResult<UpdateGlobalCommentSettingsMutation>;
export type UpdateGlobalCommentSettingsMutationOptions = Apollo.BaseMutationOptions<UpdateGlobalCommentSettingsMutation, UpdateGlobalCommentSettingsMutationVariables>;