import React from "react";
import { useParams } from "react-router-dom";
import { TitleSection, Section } from "components/SettingsBar";
import { Button, Card, Link } from "components";
import Spacer from "components/Spacer";
import Text from "components/Text";
import { CheckCircle } from "@mui/icons-material";

const options = [
  {
    name: "Free plan",
    description: "Let members sign-up without payments.",
    value: "FREE",
  },
  {
    name: "Paid plan",
    description:
      "Require one-time or recurring payments for members to sign-up.",
    value: "PAID",
  },
];

const ChoosePlanType = ({ type, setType, onNext }) => {
  const { appId } = useParams<{ appId: string }>();

  return (
    <>
      <TitleSection
        title="Add a New Plan"
        backLink={`/apps/${appId}/plans`}
        backLinkTitle="All Plans"
        emphasize
      />
      <Section divider={false} gutter="medium">
        <Text>What plan would you like to create?</Text>
        <Spacer />
        <div className="flex flex-col gap-3">
          {options.map(({ name, description, value }, idx) => (
            <div
              onClick={() => setType(value)}
              data-testid="card-select"
              aria-hidden
              data-cy={`plan-type-${value.toLowerCase()}`}
              key={value}
            >
              <Card selected={type === value} tw="p-3">
                <div
                  style={{ color: type === value ? "#2962FF" : "black" }}
                  className="flex justify-between items-center"
                >
                  <p className="font-bold text-base">{name}</p>
                  {type === value ? <CheckCircle /> : null}
                </div>
                {description && (
                  <p className="text-base mt-1 whitespace-pre-line break-words">
                    {description}
                  </p>
                )}
              </Card>
            </div>
          ))}
        </div>
        <Spacer />
        <div tw="flex justify-between items-center">
          <Link to={`/apps/${appId}/plans/redirects`}>
            <Button text="Cancel" buttonStyle="skeleton" />
          </Link>
          <Button
            text="Next"
            onClick={onNext}
            isDisabled={!type}
            dataCy="add-plan-next-button"
          />
        </div>
      </Section>
    </>
  );
};

export default ChoosePlanType;
