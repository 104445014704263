import React, { useState } from "react";
import Input from "../Input";

const addresses = [
  "no-reply",
  "noreply",
  "donotreply",
  "donotrespond",
  "please-do-not-reply",
  "do-not-reply",
  "कोई-जवाब-नहीं",
  "प्रत्युत्तर-नहीं",
  "उत्तर-न-दें",
  "nicht-antworten",
  "keine-antwort",
  "ne-pas-repondre",
  "pas-de-reponse",
  "no-responder",
  "niet-antwoorden",
  "geen-antwoord",
  "huwag-tumugon",
  "جواب-نہ-دیں",
  "non-rispondere",
  "nessuna-risposta",
  "não-responder",
  "sem-resposta",
  "nie-odpowiadaj",
  "brak-odpowiedzi",
  "не-отвечать",
  "без-ответа",
  "не-відповідайте",
  "без-відповіді",
  "jangan-balas",
  "tidak-perlu-balas",
  "勿回复",
  "无需回复",
  "답장-금지",
  "회신-금지",
];

const noReplyRegex = new RegExp(`^(?!(${addresses.join("|")})@).*@.*$`, "i");

interface props {
  placeholder?: string;
  value: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => Promise<void> | void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => Promise<void> | void;
}

console.log(noReplyRegex.source);
export const ReplyToEmail = ({
  placeholder,
  value,
  onChange,
  onBlur,
}: props) => {
  const [error, setError] = useState<string | null>(null);
  return (
    <Input
      name="replyTo"
      placeholder={placeholder}
      value={value}
      label="Reply-To-Email"
      infoHintText="Email address"
      className="pb-5"
      required
      pattern={noReplyRegex.source}
      description={
        // error ? (
        //   <span className="text-sm text-red-500">{error}</span>
        // ) : (
        "Enter active support email (no 'noreply' addresses)"
        // )
      }
      errorMessage={error}
      onChange={async (e) => {
        const [address] = e.target.value.split("@");
        if (addresses.includes(address)) {
          setError('Email cannot be a "noreply" address');
        } else {
          setError(null);
        }
        await onChange(e);
      }}
      onBlur={onBlur}
    />
  );
};
