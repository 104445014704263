import React, { useEffect, useMemo, useRef, useState } from "react";
import { Card } from "components/Card";
import { Dropdown } from "components/Dropdown";
import {
  DeleteOutlineRounded,
  EditRounded,
  Reply,
  ExpandLess,
  ExpandMore,
} from "@mui/icons-material";
import { getUIAvatarLink } from "helpers/getUIAvatarLink";
import { PostStatus } from "generatedTypes";
import mergeClassNames from "helpers/mergeClassNames/mergeClassNames";
import { Button } from "components/Button";
import Tag from "components/Tag";
import {
  PostActionRadioButtons,
  PostImageContainer,
  PostStatusBar,
} from "./CommentCard.styles";
import { CommentCardSkeleton } from "./CommentCardSkeleton";

interface Props {
  id: string;
  comment: string;
  ipAddress?: string;
  timestamp: string;
  email: string;
  avatarUrl: string;
  upVotes: number;
  downVotes: number;
  postStatus: PostStatus;
  className?: string;
  onStatusChange: (status: PostStatus) => void;
  onSave: (content: string) => void;
  onDeletion: () => void;
  onReplyClick?: () => void;
  loading?: boolean;
  aiSpamLevel?: number;
  viewMode?: "normal" | "compact";
  isNewPost?: boolean;
}

export const CommentCard = ({
  id,
  comment,
  ipAddress,
  timestamp,
  email,
  avatarUrl,
  upVotes,
  downVotes,
  postStatus,
  className,
  onStatusChange,
  onSave,
  onDeletion,
  onReplyClick,
  loading,
  aiSpamLevel,
  viewMode,
  isNewPost,
}: Props) => {
  const [editing, setEditing] = useState<boolean>(false);
  const [content, setContent] = useState(comment);
  const [postType, setPostType] = useState<PostStatus>(postStatus);

  const isCompact = viewMode === "compact";

  const tagVariant = useMemo(
    () => (variant) => {
      if (variant === undefined) return "warning";
      if (variant >= 1 && variant <= 5) return "primary";
      if (variant >= 6 && variant <= 8) return "warning";
      if (variant >= 9 && variant <= 10) return "danger";
      return "warning";
    },
    []
  );

  const textareaRef = useRef(null);
  // Use useEffect to focus on the textarea when editing is true
  useEffect(() => {
    if (editing && textareaRef.current) {
      textareaRef.current.focus();
    }
  }, [editing]);

  const handleRadioChange = async (newPostType: PostStatus) => {
    setPostType(newPostType);
    await onStatusChange(newPostType);
  };

  const saveHandler = async () => {
    setEditing(false);
    await onSave(content);
  };

  const userImgLink = avatarUrl || getUIAvatarLink({ name: email });

  const dropdownOptions = [
    {
      text: "Edit Comment",
      id: "edit-comment",
      onClick: () => setEditing(true),
      Icon: <EditRounded />,
    },
    {
      text: "Delete",
      id: "delete",
      onClick: () => onDeletion(),
      Icon: <DeleteOutlineRounded className="text-red-600" />,
    },
    // {
    //   text: "Delete & Ban",
    //   id: "deleteAndBan",
    //   onClick: () => {},
    //   Icon: <NotInterestedRounded className="text-red-600" />,
    // },
  ];

  if (loading) return <CommentCardSkeleton isCompact={isCompact} />;

  return (
    <div
      className={mergeClassNames(
        "p-3 flex gap-4 border rounded-lg shadow-sm",
        className
      )}
      style={{
        background: isNewPost
          ? "linear-gradient(0deg, rgba(231, 241, 255, 0.50) 0%, rgba(231, 241, 255, 0.50) 100%), #FFF"
          : "white",
      }}
    >
      {!isCompact && <PostStatusBar postStatus={postType} />}
      <div className="flex gap-4 flex-col w-full">
        <div className="flex justify-between">
          {!isCompact ? (
            <div className="flex items-center">
              <PostImageContainer postStatus={postType}>
                <img
                  alt={`${email}'s avatar`}
                  src={userImgLink}
                  className="rounded-full p-px w-full h-full object-cover"
                />
              </PostImageContainer>
              <div className="flex flex-col">
                <div className="text-body-sm font-bold flex items-center gap-2">
                  {email}
                  {!!isNewPost && (
                    <Tag text="NEW" variant="fadedBlue" size="small" />
                  )}
                </div>
                <div className="text-body-sm text-gray-500">
                  {timestamp} ago
                </div>
              </div>
            </div>
          ) : (
            <div className="text-body-sm text-gray-500">{timestamp} ago</div>
          )}
          {!editing && (
            <div className="flex items-center gap-3">
              <Button
                text="Reply"
                leftIcon={<Reply />}
                buttonStyle="transparent"
                onClick={onReplyClick}
              />
              <Dropdown
                position="bottom"
                optionsTextAlign="left"
                options={dropdownOptions}
              />
            </div>
          )}
        </div>
        {editing ? (
          <form
            onSubmit={(e) => {
              e.preventDefault();
              saveHandler();
            }}
          >
            <label htmlFor={`comment-${id}`} className="sr-only">
              Comment
            </label>
            <textarea
              id={`comment-${id}`}
              className="w-full p-1"
              ref={textareaRef} // Assign the ref to the textarea
              value={content}
              onChange={({ target }) => setContent(target.value)}
            />
          </form>
        ) : (
          <p className="text-body-sm">{content}</p>
        )}
        <div className="flex items-center justify-between">
          <div className="flex gap-3 text-utility-tag-md text-app-gray700 font-bold">
            {!editing && (
              <>
                <div className="flex items-center">
                  <ExpandLess /> {upVotes}
                </div>
                <div className="flex items-center">
                  <ExpandMore /> {downVotes}
                </div>
              </>
            )}
          </div>
          <div className="flex items-center gap-2">
            {!isCompact && ipAddress && (
              <p className="text-utility-tag-md text-app-gray500 mr-2">
                IP: {ipAddress}
              </p>
            )}
            {editing ? (
              <>
                <Button
                  text="Cancel"
                  buttonStyle="skeleton"
                  onClick={() => setEditing(false)}
                />
                <Button
                  text="Save"
                  type="submit"
                  onClick={() => saveHandler()}
                />
              </>
            ) : (
              Object.values(PostStatus).map((status) => (
                <PostActionRadioButtons key={status} postStatus={status}>
                  <input
                    type="radio"
                    id={`${status.toLowerCase()}-${id}`}
                    name={`status-${id}`}
                    onChange={({ target: { checked } }) => {
                      if (checked) {
                        handleRadioChange(status);
                      }
                    }}
                    checked={postType === status}
                  />
                  <label
                    className="btn btn-default capitalize"
                    htmlFor={`${status.toLowerCase()}-${id}`}
                  >
                    {status.toLowerCase()}
                    {aiSpamLevel && status === "SPAM" && (
                      <Tag
                        className="ml-1.5"
                        text={`${aiSpamLevel}/10`}
                        variant={tagVariant(aiSpamLevel)}
                      />
                    )}
                  </label>
                </PostActionRadioButtons>
              ))
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
