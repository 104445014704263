import React, { useState } from "react";
import { AccordionLoader, EmptyState, GatedContentCard } from "components";
import { useGetContentGroupsQuery } from "features/content-groups";
import EditContentGroupModal from "features/content-groups/components/EditContentGroupModal";
import CreateContentGroupModal from "features/content-groups/components/CreateContentGroupModal";
import { PlanAccordionItem } from "../plan-settings/PlanAccordionItem";
import { DEFAULT_GROUP_STATE } from "../../utils";

const GatedContentAccordion = () => {
  const [showEditModal, setShowEditModal] = useState(false);
  const [showCreateModal, setShowCreateModal] = useState(false);

  const [selectedGroup, setSelectedGroup] = useState(DEFAULT_GROUP_STATE);

  const {
    loading: gettingContentGroups,
    refetch: refetchContentGroups,
    error: contentGroupsError,
    data: contentGroupsData,
  } = useGetContentGroupsQuery();

  const handleShowModal = (group) => {
    setSelectedGroup(group);
    return setShowEditModal(true);
  };

  return (
    <>
      {gettingContentGroups ? (
        <AccordionLoader noOfItems={1} />
      ) : (
        <PlanAccordionItem
          label="Gated Content"
          value="gated-content"
          onAdd={() => setShowCreateModal(true)}
        >
          <div>
            <div className="overflow-y-auto">
              {!contentGroupsData?.getContentGroups.length ? (
                <EmptyState
                  text="Gate Access to Content"
                  description="Use this feature to block access to pages, folders, and page elements. Just tell Memberstack what you want to hide, who should have access, and where to send folks who don't."
                  buttonText="Gate Content"
                  onCreateClick={() => setShowCreateModal(true)}
                  docsLink="https://docs.memberstack.com/hc/en-us/articles/7402927868443-Gated-Content"
                />
              ) : (
                contentGroupsData.getContentGroups.map((restrictedUrlGroup) => (
                  <GatedContentCard
                    className="mb-3"
                    key={restrictedUrlGroup.id}
                    contentId={restrictedUrlGroup.key}
                    name={restrictedUrlGroup.name}
                    plans={restrictedUrlGroup.plans}
                    urls={restrictedUrlGroup.urls}
                    onClick={() => handleShowModal(restrictedUrlGroup)}
                  />
                ))
              )}
            </div>
          </div>
        </PlanAccordionItem>
      )}
      <EditContentGroupModal
        setShowModal={setShowEditModal}
        showModal={showEditModal}
        selectedGroup={selectedGroup}
        refetchContentGroups={refetchContentGroups}
      />
      <CreateContentGroupModal
        setShowModal={setShowCreateModal}
        showModal={showCreateModal}
        refetchContentGroups={refetchContentGroups}
      />
    </>
  );
};

export default GatedContentAccordion;
