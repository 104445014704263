import React from "react";
import { Link } from "components";

export const ShowcaseCard = ({ children }) => (
  <div className="flex flex-col bg-white shadow-lg py-10 px-9 rounded-[20px] w-full max-w-[612px]">
    {children}
  </div>
);

export const UploadLogoText = () => (
  <p className="pb-2 text-base pl-[35px]">
    Upload your logo & brand color on the{" "}
    <Link to="/settings/design" showAsLink>
      settings page.
    </Link>
  </p>
);
