import { useCallback, useEffect } from "react";
import { debounce } from "lodash";

export const useSearchWithFilter = ({
  refetch,
  planIds,
  status,
  setSearchValue,
  comments,
}) => {
  const handleSearch = useCallback(
    (inputValue) => {
      refetch({
        search: inputValue,
        filters: {
          planIds: planIds.current,
          status: status.current,
          hasComments: comments.current?.hasComments,
          noComments: comments.current?.noComments,
        },
      });
    },
    [planIds, status, comments]
  );

  const debounceSearch = useCallback(debounce(handleSearch, 900), []);

  const changeHandler = useCallback(
    (value) => {
      setSearchValue(value);
      debounceSearch(value);
    },
    [debounceSearch]
  );

  // Stop the invocation of the debounced function after unmounting
  useEffect(() => {
    return () => debounceSearch.cancel();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [changeHandler];
};
