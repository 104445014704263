import React, { useState } from "react";
import { REDIRECT_MAP } from "constants/redirects";
import Input from "components/Input";
import { Form } from "components";
import Select from "components/Select";
import { Modal, ModalTypes } from "components/Modal";
import { useUpdatePlanMutation } from "features/plans";
import { useToastAlertContext } from "contexts/ToastAlertContext";

const redirectEvents = Object.keys(REDIRECT_MAP).map((key) => ({
  value: key,
  label: REDIRECT_MAP[key],
}));

interface Props extends ModalTypes {
  refetch: () => void;
  planId?: string;
}

const CreateRedirectModal = ({
  refetch,
  planId,
  showModal,
  setShowModal,
}: Props) => {
  const [isFormValid, setIsFormValid] = useState(false);
  const [redirectEvent, setRedirectEvent] = useState(redirectEvents[0].value);
  const [redirectUrl, setRedirectUrl] = useState("");
  const { createToastAlert } = useToastAlertContext();
  const [modalError, setModalError] = useState(null);
  const [create, { loading }] = useUpdatePlanMutation();

  const handleOnSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.stopPropagation();
    createToastAlert({
      processing: true,
    });

    try {
      await create({
        variables: {
          input: {
            planId,
            redirects: {
              [redirectEvent]: redirectUrl,
            },
          },
        },
      });

      await refetch();
      createToastAlert({
        alertType: "success",
        message: "Redirect was successfully created.",
      });
      setShowModal(false);
    } catch (error) {
      setModalError(error);
    }
  };

  return (
    <Modal
      setShowModal={setShowModal}
      showModal={showModal}
      title="Create Redirect"
      showDivider
      nonScrollable
      actionButtons={{
        confirm: {
          label: "Save",
          onConfirm: handleOnSubmit,
          isDisabled: !isFormValid,
          isLoading: loading,
        },
        cancel: { label: "Cancel" },
      }}
      errorMessage={modalError}
      onErrorClose={() => setModalError(null)}
    >
      <Form onSubmit={handleOnSubmit} onValidityCheck={setIsFormValid}>
        <Input
          label=""
          required
          placeholder="Enter redirect URL"
          value={redirectUrl}
          onChange={(e) => setRedirectUrl(e.target.value)}
          leading={
            <div tw="flex">
              <Select
                label=""
                value={redirectEvents.find((f) => f.value === redirectEvent)}
                options={redirectEvents}
                onChange={(selected) => setRedirectEvent(selected.value)}
                inline
              />
            </div>
          }
          hideIndicators
        />
      </Form>
    </Modal>
  );
};

CreateRedirectModal.defaultProps = {
  planId: undefined,
};

export default CreateRedirectModal;
