import cn from "helpers/cn";
import React from "react";
import Lottie from "react-lottie";
import loaderData from "./Memberstack-Loading.json";

interface Props {
  isFullScreen?: boolean;
  fillSpace?: boolean;
}

const Loader = ({ isFullScreen, fillSpace }: Props) => (
  <div
    className={cn("flex content-center items-center", {
      "w-full": isFullScreen || fillSpace,
      "h-screen": isFullScreen,
      "h-full": fillSpace,
    })}
  >
    <Lottie
      options={{
        loop: true,
        autoplay: true,
        animationData: loaderData,
      }}
      width={200}
    />
  </div>
);

export default Loader;
