import React, { lazy } from "react";
import { useNavigate } from "react-router-dom";
import { OpenInNew, LockRounded } from "@mui/icons-material";

import {
  Button,
  Banner,
  Link,
  EnvSwitcher,
  EmptyState,
  ContentWrapper,
} from "components";
import { useEnvContext } from "contexts/EnvironmentContext";
import { useAppDataContext } from "contexts/AppDataContext";
import ApiKeyManagement from "./ApiKeyManagement";
import Webhooks from "./Webhooks";
import SSOApplication from "./SSOApplications";
import { DevtoolsContextProvider } from "./context";
import SvixEmbed from "./SvixEmbed";

const EmptyMemberTableIcon = lazy(
  () => import("assets/images/empty_members_table.svg")
);

const openDocs = () => {
  const docs = "https://developer.memberstack.com/";
  window.open(docs, "_blank");
};

const Component = () => {
  const { isSandboxEnv } = useEnvContext();
  const { isSubscribed } = useAppDataContext();

  const navigate = useNavigate();

  return (
    <ContentWrapper className="block overflow-auto">
      <div className="flex flex-col m-5">
        <div className="flex items-center flex-row justify-between">
          <h3 className="text-h3 font-bold">Developer Tools</h3>
          <div className="mt-4  gap-2 flex md:mt-0 md:ml-4">
            <EnvSwitcher />
            <Button
              text="Permissions"
              onClick={() => navigate("permissions")}
              buttonStyle="skeleton"
              leftIcon={<LockRounded />}
            />
            <Button
              text="View Documentation"
              onClick={openDocs}
              buttonStyle="skeleton"
              rightIcon={<OpenInNew />}
            />
          </div>
        </div>
        {isSandboxEnv && (
          <Banner
            className="mt-4"
            text="You’re viewing and editing Test Mode Data"
            description="You will need to update API Keys and SSO Integrations when you switch to Live Mode."
            rightPlugin={
              <Link
                to="https://docs.memberstack.com/hc/en-us/articles/7595262385819-Managing-Test-Mode-and-Live-Mode"
                target="_blank"
                isBold
                isExternal
              >
                Learn more
              </Link>
            }
          />
        )}
      </div>
      <div className="px-5 pb-5 flex flex-col gap-4 h-[calc(100vh - 76px)]">
        {isSandboxEnv || (!isSandboxEnv && isSubscribed) ? (
          <>
            <ApiKeyManagement />
            <SSOApplication />
            <SvixEmbed />
            <Webhooks />
          </>
        ) : (
          <EmptyState
            isFullScreen
            text="A Basic Plan is required to access live data"
            description="Ready to launch?"
            buttonText="View Plans"
            onCreateClick={() => navigate("../settings/billing")}
            showEnvSwitcher
            svgImage={<EmptyMemberTableIcon />}
          />
        )}
      </div>
    </ContentWrapper>
  );
};

const Devtools = () => (
  <DevtoolsContextProvider>
    <Component />
  </DevtoolsContextProvider>
);

export default Devtools;
