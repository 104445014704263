import React from "react";
import { useEnvContext } from "contexts/EnvironmentContext";
import cn from "helpers/cn";

interface Props {
  id: string;
  label?: string;
  onChange: (isChecked: boolean) => void;
  isChecked: boolean;
  description?: React.ReactNode;
  disabled?: boolean;
  name?: string;
  dataCy?: string;
  className?: string;
  retainBgColor?: boolean;
  labelClassName?: string;
  size?: "medium" | "large";
}

export const Switch = ({
  id,
  label,
  onChange,
  isChecked,
  description,
  disabled = false,
  name,
  dataCy,
  className,
  retainBgColor,
  labelClassName,
  size,
}: Props) => {
  const onToggle = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.checked);
  };

  const labelId = `label-${id}`;
  const descriptionId = `description-${id}`;

  const labelBy = `${labelId} ${descriptionId}`;

  return (
    <div className={className}>
      <div className={cn("flex gap-3 items-center")}>
        <input
          id={id}
          type="checkbox"
          role="switch"
          checked={isChecked}
          onChange={onToggle}
          aria-checked={isChecked}
          aria-labelledby={labelBy}
          disabled={disabled}
          name={name}
          onClick={(e) => e.stopPropagation()}
          data-cy={dataCy}
          value={id}
          className={cn(
            "relative appearance-none rounded-full cursor-pointer m-0 select-none outline-1 outline outline-app-gray400 bg-app-gray400 checked:bg-app-green400 checked:outline-app-green400 transition-colors after:content-[' '] after:absolute  after:inset-0 after:bg-white after:transition-all after:rounded-full after:cursor-pointer after:z-10 box-border",
            {
              "w-7 h-4 after:h-4 after:w-4 checked:after:translate-x-3":
                size === undefined,
              "w-10 h-6 after:h-6 after:w-6 checked:after:translate-x-4":
                size === "medium",
              "w-14 h-7 after:h-7 after:w-7 checked:after:translate-x-7":
                size === "large",
              "bg-app-blue400": retainBgColor,
              "opacity-50": disabled,
            }
          )}
        />
        {label && (
          <label
            className={cn(
              "text-body-sm cursor-pointer select-none w-fit",

              labelClassName
            )}
            htmlFor={id}
            id={labelId}
          >
            {label}
          </label>
        )}
      </div>
      {description && (
        <div className="text-sm text-app-gray500 ml-9">{description}</div>
      )}
    </div>
  );
};

interface EnvSwitcherProps {
  className?: string;
  onChangeMode?: () => void;
  responsive?: boolean;
}
export const EnvSwitcher = ({
  className,
  onChangeMode,
  responsive,
}: EnvSwitcherProps) => {
  const { toggleEnv, isSandboxEnv } = useEnvContext();

  const handleToggle = () => {
    toggleEnv();
    onChangeMode?.();
  };

  return (
    <div
      className={cn(
        className,
        "flex rounded-md items-center p-2 bg-white outline outline-1 outline-app-gray200"
      )}
    >
      <Switch
        label="Test Mode"
        isChecked={isSandboxEnv}
        onChange={handleToggle}
        id="gs-env-toggle"
        dataCy="env-toggle"
        labelClassName={cn(
          "text-utility-tag-md whitespace-nowrap text-app-gray600",
          responsive && "hidden xl:block"
        )}
      />
    </div>
  );
};
