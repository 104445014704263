import * as Types from '../../generatedTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type GetPostsQueryVariables = Types.Exact<{
  first?: Types.Maybe<Types.Scalars['Int']>;
  after?: Types.Maybe<Types.Scalars['Int']>;
  order?: Types.Maybe<Types.PostOrder>;
  filters?: Types.Maybe<Types.GetPostsFilter>;
}>;


export type GetPostsQuery = (
  { __typename?: 'Query' }
  & { getPosts?: Types.Maybe<(
    { __typename?: 'PostPage' }
    & { edges: Array<(
      { __typename?: 'PostEdge' }
      & Pick<Types.PostEdge, 'cursor'>
      & { node: (
        { __typename?: 'Post' }
        & Pick<Types.Post, 'id' | 'status' | 'createdAt' | 'content' | 'likedByCount' | 'dislikedByCount' | 'memberId' | 'ip' | 'aiSpamLevel' | 'isNew'>
        & { member?: Types.Maybe<(
          { __typename?: 'Member' }
          & Pick<Types.Member, 'profileImage'>
          & { auth: (
            { __typename?: 'MemberAuth' }
            & Pick<Types.MemberAuth, 'email'>
          ) }
        )> }
      ) }
    )>, pageInfo: (
      { __typename?: 'PostPageInfo' }
      & Pick<Types.PostPageInfo, 'endCursor'>
    ) }
  )> }
);

export type GetChannelsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetChannelsQuery = (
  { __typename?: 'Query' }
  & { getChannels?: Types.Maybe<Array<Types.Maybe<(
    { __typename?: 'Channel' }
    & Pick<Types.Channel, 'id' | 'name' | 'key' | 'createdAt' | 'activityCount'>
  )>>> }
);

export type GetPostLinkQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type GetPostLinkQuery = (
  { __typename?: 'Query' }
  & { getPostLink?: Types.Maybe<(
    { __typename?: 'PostLink' }
    & Pick<Types.PostLink, 'url'>
  )> }
);


export const GetPostsDocument = gql`
    query getPosts($first: Int, $after: Int, $order: PostOrder, $filters: GetPostsFilter) {
  getPosts(first: $first, after: $after, order: $order, filters: $filters) {
    edges {
      cursor
      node {
        id
        status
        createdAt
        content
        likedByCount
        dislikedByCount
        memberId
        ip
        aiSpamLevel
        isNew
        member {
          profileImage
          auth {
            email
          }
        }
      }
    }
    pageInfo {
      endCursor
    }
  }
}
    `;

/**
 * __useGetPostsQuery__
 *
 * To run a query within a React component, call `useGetPostsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPostsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPostsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      order: // value for 'order'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGetPostsQuery(baseOptions?: Apollo.QueryHookOptions<GetPostsQuery, GetPostsQueryVariables>) {
        return Apollo.useQuery<GetPostsQuery, GetPostsQueryVariables>(GetPostsDocument, baseOptions);
      }
export function useGetPostsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPostsQuery, GetPostsQueryVariables>) {
          return Apollo.useLazyQuery<GetPostsQuery, GetPostsQueryVariables>(GetPostsDocument, baseOptions);
        }
export type GetPostsQueryHookResult = ReturnType<typeof useGetPostsQuery>;
export type GetPostsLazyQueryHookResult = ReturnType<typeof useGetPostsLazyQuery>;
export type GetPostsQueryResult = Apollo.QueryResult<GetPostsQuery, GetPostsQueryVariables>;
export const GetChannelsDocument = gql`
    query getChannels {
  getChannels {
    id
    name
    key
    createdAt
    activityCount
  }
}
    `;

/**
 * __useGetChannelsQuery__
 *
 * To run a query within a React component, call `useGetChannelsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetChannelsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChannelsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetChannelsQuery(baseOptions?: Apollo.QueryHookOptions<GetChannelsQuery, GetChannelsQueryVariables>) {
        return Apollo.useQuery<GetChannelsQuery, GetChannelsQueryVariables>(GetChannelsDocument, baseOptions);
      }
export function useGetChannelsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetChannelsQuery, GetChannelsQueryVariables>) {
          return Apollo.useLazyQuery<GetChannelsQuery, GetChannelsQueryVariables>(GetChannelsDocument, baseOptions);
        }
export type GetChannelsQueryHookResult = ReturnType<typeof useGetChannelsQuery>;
export type GetChannelsLazyQueryHookResult = ReturnType<typeof useGetChannelsLazyQuery>;
export type GetChannelsQueryResult = Apollo.QueryResult<GetChannelsQuery, GetChannelsQueryVariables>;
export const GetPostLinkDocument = gql`
    query getPostLink($id: ID!) {
  getPostLink(id: $id) {
    url
  }
}
    `;

/**
 * __useGetPostLinkQuery__
 *
 * To run a query within a React component, call `useGetPostLinkQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPostLinkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPostLinkQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPostLinkQuery(baseOptions: Apollo.QueryHookOptions<GetPostLinkQuery, GetPostLinkQueryVariables>) {
        return Apollo.useQuery<GetPostLinkQuery, GetPostLinkQueryVariables>(GetPostLinkDocument, baseOptions);
      }
export function useGetPostLinkLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPostLinkQuery, GetPostLinkQueryVariables>) {
          return Apollo.useLazyQuery<GetPostLinkQuery, GetPostLinkQueryVariables>(GetPostLinkDocument, baseOptions);
        }
export type GetPostLinkQueryHookResult = ReturnType<typeof useGetPostLinkQuery>;
export type GetPostLinkLazyQueryHookResult = ReturnType<typeof useGetPostLinkLazyQuery>;
export type GetPostLinkQueryResult = Apollo.QueryResult<GetPostLinkQuery, GetPostLinkQueryVariables>;