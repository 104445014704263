import * as Types from '../../generatedTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type CreateCustomFieldMutationVariables = Types.Exact<{
  input: Types.CreateCustomFieldInput;
}>;


export type CreateCustomFieldMutation = (
  { __typename?: 'Mutation' }
  & { createCustomField: (
    { __typename?: 'CustomField' }
    & Pick<Types.CustomField, 'id'>
  ) }
);

export type DeleteCustomFieldMutationVariables = Types.Exact<{
  input: Types.DeleteCustomFieldInput;
}>;


export type DeleteCustomFieldMutation = (
  { __typename?: 'Mutation' }
  & Pick<Types.Mutation, 'deleteCustomField'>
);

export type UpdateCustomFieldMutationVariables = Types.Exact<{
  input: Types.UpdateCustomFieldInput;
}>;


export type UpdateCustomFieldMutation = (
  { __typename?: 'Mutation' }
  & { updateCustomField: (
    { __typename?: 'CustomField' }
    & Pick<Types.CustomField, 'hidden' | 'label' | 'visibility'>
  ) }
);

export type OrderCustomFieldsMutationVariables = Types.Exact<{
  input: Types.OrderCustomFieldsInput;
}>;


export type OrderCustomFieldsMutation = (
  { __typename?: 'Mutation' }
  & { orderCustomFields: Array<(
    { __typename?: 'CustomField' }
    & Pick<Types.CustomField, 'label'>
  )> }
);

export type OrderTableCustomFieldsMutationVariables = Types.Exact<{
  input: Types.OrderCustomFieldsInput;
}>;


export type OrderTableCustomFieldsMutation = (
  { __typename?: 'Mutation' }
  & { orderTableCustomFields: Array<(
    { __typename?: 'CustomField' }
    & Pick<Types.CustomField, 'label'>
  )> }
);


export const CreateCustomFieldDocument = gql`
    mutation CreateCustomField($input: CreateCustomFieldInput!) {
  createCustomField(input: $input) {
    id
  }
}
    `;
export type CreateCustomFieldMutationFn = Apollo.MutationFunction<CreateCustomFieldMutation, CreateCustomFieldMutationVariables>;

/**
 * __useCreateCustomFieldMutation__
 *
 * To run a mutation, you first call `useCreateCustomFieldMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCustomFieldMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCustomFieldMutation, { data, loading, error }] = useCreateCustomFieldMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCustomFieldMutation(baseOptions?: Apollo.MutationHookOptions<CreateCustomFieldMutation, CreateCustomFieldMutationVariables>) {
        return Apollo.useMutation<CreateCustomFieldMutation, CreateCustomFieldMutationVariables>(CreateCustomFieldDocument, baseOptions);
      }
export type CreateCustomFieldMutationHookResult = ReturnType<typeof useCreateCustomFieldMutation>;
export type CreateCustomFieldMutationResult = Apollo.MutationResult<CreateCustomFieldMutation>;
export type CreateCustomFieldMutationOptions = Apollo.BaseMutationOptions<CreateCustomFieldMutation, CreateCustomFieldMutationVariables>;
export const DeleteCustomFieldDocument = gql`
    mutation DeleteCustomField($input: DeleteCustomFieldInput!) {
  deleteCustomField(input: $input)
}
    `;
export type DeleteCustomFieldMutationFn = Apollo.MutationFunction<DeleteCustomFieldMutation, DeleteCustomFieldMutationVariables>;

/**
 * __useDeleteCustomFieldMutation__
 *
 * To run a mutation, you first call `useDeleteCustomFieldMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCustomFieldMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCustomFieldMutation, { data, loading, error }] = useDeleteCustomFieldMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteCustomFieldMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCustomFieldMutation, DeleteCustomFieldMutationVariables>) {
        return Apollo.useMutation<DeleteCustomFieldMutation, DeleteCustomFieldMutationVariables>(DeleteCustomFieldDocument, baseOptions);
      }
export type DeleteCustomFieldMutationHookResult = ReturnType<typeof useDeleteCustomFieldMutation>;
export type DeleteCustomFieldMutationResult = Apollo.MutationResult<DeleteCustomFieldMutation>;
export type DeleteCustomFieldMutationOptions = Apollo.BaseMutationOptions<DeleteCustomFieldMutation, DeleteCustomFieldMutationVariables>;
export const UpdateCustomFieldDocument = gql`
    mutation UpdateCustomField($input: UpdateCustomFieldInput!) {
  updateCustomField(input: $input) {
    hidden
    label
    visibility
  }
}
    `;
export type UpdateCustomFieldMutationFn = Apollo.MutationFunction<UpdateCustomFieldMutation, UpdateCustomFieldMutationVariables>;

/**
 * __useUpdateCustomFieldMutation__
 *
 * To run a mutation, you first call `useUpdateCustomFieldMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCustomFieldMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCustomFieldMutation, { data, loading, error }] = useUpdateCustomFieldMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCustomFieldMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCustomFieldMutation, UpdateCustomFieldMutationVariables>) {
        return Apollo.useMutation<UpdateCustomFieldMutation, UpdateCustomFieldMutationVariables>(UpdateCustomFieldDocument, baseOptions);
      }
export type UpdateCustomFieldMutationHookResult = ReturnType<typeof useUpdateCustomFieldMutation>;
export type UpdateCustomFieldMutationResult = Apollo.MutationResult<UpdateCustomFieldMutation>;
export type UpdateCustomFieldMutationOptions = Apollo.BaseMutationOptions<UpdateCustomFieldMutation, UpdateCustomFieldMutationVariables>;
export const OrderCustomFieldsDocument = gql`
    mutation OrderCustomFields($input: OrderCustomFieldsInput!) {
  orderCustomFields(input: $input) {
    label
  }
}
    `;
export type OrderCustomFieldsMutationFn = Apollo.MutationFunction<OrderCustomFieldsMutation, OrderCustomFieldsMutationVariables>;

/**
 * __useOrderCustomFieldsMutation__
 *
 * To run a mutation, you first call `useOrderCustomFieldsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrderCustomFieldsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [orderCustomFieldsMutation, { data, loading, error }] = useOrderCustomFieldsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useOrderCustomFieldsMutation(baseOptions?: Apollo.MutationHookOptions<OrderCustomFieldsMutation, OrderCustomFieldsMutationVariables>) {
        return Apollo.useMutation<OrderCustomFieldsMutation, OrderCustomFieldsMutationVariables>(OrderCustomFieldsDocument, baseOptions);
      }
export type OrderCustomFieldsMutationHookResult = ReturnType<typeof useOrderCustomFieldsMutation>;
export type OrderCustomFieldsMutationResult = Apollo.MutationResult<OrderCustomFieldsMutation>;
export type OrderCustomFieldsMutationOptions = Apollo.BaseMutationOptions<OrderCustomFieldsMutation, OrderCustomFieldsMutationVariables>;
export const OrderTableCustomFieldsDocument = gql`
    mutation OrderTableCustomFields($input: OrderCustomFieldsInput!) {
  orderTableCustomFields(input: $input) {
    label
  }
}
    `;
export type OrderTableCustomFieldsMutationFn = Apollo.MutationFunction<OrderTableCustomFieldsMutation, OrderTableCustomFieldsMutationVariables>;

/**
 * __useOrderTableCustomFieldsMutation__
 *
 * To run a mutation, you first call `useOrderTableCustomFieldsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrderTableCustomFieldsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [orderTableCustomFieldsMutation, { data, loading, error }] = useOrderTableCustomFieldsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useOrderTableCustomFieldsMutation(baseOptions?: Apollo.MutationHookOptions<OrderTableCustomFieldsMutation, OrderTableCustomFieldsMutationVariables>) {
        return Apollo.useMutation<OrderTableCustomFieldsMutation, OrderTableCustomFieldsMutationVariables>(OrderTableCustomFieldsDocument, baseOptions);
      }
export type OrderTableCustomFieldsMutationHookResult = ReturnType<typeof useOrderTableCustomFieldsMutation>;
export type OrderTableCustomFieldsMutationResult = Apollo.MutationResult<OrderTableCustomFieldsMutation>;
export type OrderTableCustomFieldsMutationOptions = Apollo.BaseMutationOptions<OrderTableCustomFieldsMutation, OrderTableCustomFieldsMutationVariables>;