import React from "react";
import {
  ChatBubbleRounded,
  Settings,
  GridViewRounded,
  FormatListBulletedRounded,
} from "@mui/icons-material";
import { Outlet, NavLink } from "react-router-dom";
import { ContentWrapper } from "components";

const getLinkClasses = (isActive: boolean = false) =>
  `px-2 py-[6px] flex items-center gap-2 rounded-lg text-[14px] leading-6 hover:text-app-gray600 font-bold group ${isActive && "active bg-app-gray50 text-black"}`;

const svgClasses =
  "fill-app-gray50 group-[&.active]:block group-[&.active]:fill-black";

const Community = () => {
  return (
    <ContentWrapper className="flex-col">
      <div className="flex justify-between items-center p-5 border-b">
        <h1 className="text-h3 font-bold">Community</h1>
        <div className="flex gap-4 text-app-gray600">
          <NavLink
            to="comments/ALL"
            className={({ isActive }) => getLinkClasses(isActive)}
          >
            <ChatBubbleRounded className={svgClasses} /> Comments
          </NavLink>
          <NavLink
            to="channels"
            className={({ isActive }) => getLinkClasses(isActive)}
          >
            <FormatListBulletedRounded className={svgClasses} /> Channels
          </NavLink>
          <NavLink
            to="ui-components"
            className={({ isActive }) => getLinkClasses(isActive)}
          >
            <GridViewRounded className={svgClasses} /> UI Components
          </NavLink>
          <NavLink
            to="settings/general"
            className={({ isActive }) => getLinkClasses(isActive)}
          >
            <Settings className={svgClasses} /> Settings
          </NavLink>
        </div>
      </div>
      <div className="flex-1 overflow-hidden">
        <Outlet />
      </div>
    </ContentWrapper>
  );
};

export default Community;
