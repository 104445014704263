import React from "react";
import { colors as c } from "styles";
import cn from "helpers/cn";

interface TextProps {
  bold?: boolean;
  children: React.ReactNode;
  className?: string;
  color?: keyof typeof c;
  ellipsisOverflow?: boolean;
  italic?: boolean;
  textAlign?: "left" | "center" | "right";
  underline?: boolean;
}

const Text = ({
  bold,
  italic,
  underline,
  children,
  className,
  color,
  ellipsisOverflow,
  textAlign,
}: TextProps) => {
  return (
    <p
      className={cn(
        "break-words text-base",
        `${ellipsisOverflow ? "whitespace-nowrap overflow-hidden text-ellipsis" : "whitespace-pre-line"}`,
        {
          "underline": underline,
          "font-bold": bold,
          "italic": italic,
        },
        className
      )}
      style={{
        color: c[color],
        textAlign,
      }}
    >
      {children}
    </p>
  );
};

export default Text;
