import { styled, css } from "twin.macro";

export const RootContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  @media (max-width: 1204px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const Container = styled.div<{
  $disabled: boolean;
}>(
  () =>
    ({ $disabled, theme: { colors } }) => css`
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      border: 1px solid ${colors.grey200};
      border-radius: 8px;
      font-size: 14px;
      line-height: 24px;
      color: ${colors.grey600};
      width: fit-content;
      background-color: ${colors.white};
      cursor: pointer;
      @media (max-width: 1204px) {
        margin-bottom: 8px;
      }
      & > div:first-of-type {
        width: 152px;
      }
      ${$disabled &&
      css`
        background-color: ${colors.grey050};
        color: ${colors.grey400};
        cursor: not-allowed;
        pointer-events: none;
      `}
      &:hover {
        border-color: ${colors.blue400};
      }
      &:focus {
        border-color: ${colors.blue400};
        outline: none;
      }
    `
);

export const Picker = styled.div(
  () =>
    ({ theme: { colors } }) => css`
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 6px 8px;
      border-left: 1px solid ${colors.grey200};
      & > .calendar {
        width: 12px;
        height: 12px;
        margin-right: 4px;
      }
      & > .expand {
        width: 16px;
        height: 16px;
        margin-left: 4px;
      }
    `
);
