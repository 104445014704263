import React from "react";
import { Button, Dropdown, Banner, AccordionLoader } from "components";
import { useResolvedPath } from "react-router-dom";
import { AccountCircle, FlashOn, Close, Chat } from "@mui/icons-material";
import Skeleton from "react-loading-skeleton";
import TextComboLoading from "components/TextCombo/TextCombo.loading";
import InputLoading from "components/Input/Input.loading";
import { Tabs, TabNav, TabItem } from "components/Tabs";

interface Props {
  closeDialog: () => void;
  isSandBoxMode: boolean;
}

const MemberPaneLoading = ({ closeDialog, isSandBoxMode }: Props) => {
  const url = useResolvedPath("").pathname;

  const tabs = [
    {
      Icon: <AccountCircle />,
      label: "Profile",
      url: `${url}/profile`,
      value: "profile",
    },
    {
      Icon: <FlashOn />,
      label: "Plans & Permissions",
      url: `${url}/connections`,
      value: "connections",
    },
    {
      Icon: <Chat />,
      label: "Comments",
      url: `${url}/comments/ALL`,
      value: "comments",
    },
  ];

  const options = [
    {
      text: "Reset Password",
      onClick: undefined,
    },
    {
      text: "Delete Member",
      onClick: undefined,
    },
  ];

  return (
    <>
      <div className="my-5 ml-4 mr-5" aria-label="member-pane">
        <div className="flex items-center justify-between mb-2">
          <div className="flex items-center">
            <Button
              buttonStyle="icon"
              onClick={closeDialog}
              rightIcon={<Close />}
            />
            <Skeleton width={270} height={28} />
          </div>
          <Dropdown options={options} />
        </div>
        {isSandBoxMode && (
          <Banner text="Editing a test member." className="mt-4" />
        )}
      </div>
      <Tabs isExpanded showBottomBorder>
        <TabNav>
          {tabs.map((tab) => (
            <TabItem
              key={tab.label}
              id={tab.value}
              label={tab.label}
              Icon={tab.Icon}
              url={tab.url}
            />
          ))}
        </TabNav>
      </Tabs>
      <div className="pt-5 px-5">
        <TextComboLoading hasLabel spaceOut className="mb-6" />
        <TextComboLoading hasLabel className="mb-6" />
        <InputLoading className="mb-6" />
      </div>
      <AccordionLoader />
    </>
  );
};

export default MemberPaneLoading;
