import React, { lazy, useEffect, useMemo } from "react";
import { sortBy } from "lodash";
import { format } from "date-fns";
import styled from "styled-components";
import Skeleton from "react-loading-skeleton";
import { useNavigate, useParams } from "react-router-dom";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";

import { Button, Card, Link, Table } from "components";
import { PlanTags } from "containers/Members/Members.utils";
import { useGetCustomFieldsQuery } from "features/custom-fields";
import { RouteName } from "enums/routes";
import SuspenseWrapper from "components/SuspenseWrapper/SuspenseWrapper";
import Tag from "components/Tag";
import mergeClassNames from "helpers/mergeClassNames/mergeClassNames";

const EmptyMemberTableIcon = lazy(
  () => import("assets/images/empty_members_table.svg")
);

const EmptyContentContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  & > div {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-left: 56px;
    & > p {
      width: 378px;
    }
  }
`;

export const MembersTable = ({ membersLoading, membersData }) => {
  const navigate = useNavigate();
  const { appId } = useParams<{ appId: string }>();
  const {
    data: customFieldsData,
    loading: customFieldsLoading,
    refetch,
  } = useGetCustomFieldsQuery();

  useEffect(() => {
    refetch();
  }, [refetch]);

  const loading = membersLoading || customFieldsLoading;
  const data = membersData || customFieldsData;

  const sortCustomFields = (customFields) =>
    sortBy(customFields, "tableOrder").slice(0, 2);

  const table = useMemo(() => {
    if (loading || !data)
      return (
        <Table
          isHeadersInteractive={false}
          isRowsInteractive={false}
          numberOfData={5}
        >
          <tbody>
            {[1, 2, 3, 4, 5]?.map((row, i) => {
              return (
                <tr key={row}>
                  <td>
                    <Skeleton />
                  </td>
                  <td>
                    <Skeleton />
                  </td>
                  <td>
                    <Skeleton />
                  </td>
                  <td>
                    <Skeleton />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      );
    const members = membersData?.getMembers?.edges
      ?.slice(0, 4)
      ?.map((edge) => edge.node);

    const rowData = members?.map(
      ({
        id,
        auth,
        customFields,
        createdAt,
        lastLogin,
        planConnections,
        isNew,
      }) => ({
        // return customFields as an object with key value pairs
        ...Object.keys(customFields || {}).reduce(
          (acc, key) => ({
            ...acc,
            [key]: customFields[key]?.toString(),
          }),
          {}
        ),
        id,
        isNew,
        email: auth.email,
        plans: <PlanTags planConnections={planConnections} />,
        createdAt: format(new Date(createdAt), "dd LLL, yyyy"),
        lastLogin: lastLogin
          ? format(new Date(lastLogin), "dd LLL, yyyy")
          : "-",
      })
    );

    if (!rowData?.length)
      return (
        <EmptyContentContainer>
          <div>
            <p className="font-bold text-base mb-1">Create a Test Member</p>
            <p className="text-body-sm text-app-gray500">
              Create you first member to change their plans, edit custom fields,
              login as that person, etc.
            </p>
            <Button
              text="Create Member"
              tw="mt-4"
              onClick={() => navigate("../members")}
              className="w-min"
            />
          </div>
          <SuspenseWrapper>
            <EmptyMemberTableIcon />
          </SuspenseWrapper>
        </EmptyContentContainer>
      );

    return (
      <Table isHeadersInteractive={false} numberOfData={rowData?.length}>
        <tbody>
          {rowData?.map((row) => {
            return (
              <tr
                data-cy="member-row"
                key={row.id}
                onClick={() =>
                  navigate(
                    `/${RouteName.apps}/${appId}/${RouteName.members}/${row.id}/profile`
                  )
                }
                className={mergeClassNames(row.isNew && "active")}
              >
                <td className="flex items-center gap-2 email">
                  {row.email}
                  {row.isNew && (
                    <Tag text="NEW" variant="fadedBlue" size="small" isBold />
                  )}
                </td>
                <td>{row.plans}</td>
                <td>{format(new Date(row.createdAt), "dd LLL, yyyy")}</td>
                {
                  // return table data for each sorted custom field based on actual order in the table
                  sortCustomFields(customFieldsData?.getCustomFields).map(
                    ({ key }) => (
                      <td key={key}>{row[key]}</td>
                    )
                  )
                }
              </tr>
            );
          })}
        </tbody>
      </Table>
    );
  }, [
    appId,
    customFieldsData?.getCustomFields,
    data,
    navigate,
    loading,
    membersData?.getMembers?.edges,
  ]);

  const noOfNewMembers = membersData?.getMembers?.edges?.filter(
    (member) => member.node.isNew
  )?.length;

  return (
    <Card>
      <div className="flex items-center justify-between p-4 border-b">
        <div className="flex items-center gap-2">
          <h2 className="font-bold text-base">New Members</h2>
          {!!noOfNewMembers && (
            <Tag
              text={noOfNewMembers}
              variant="fadedBlue"
              size="small"
              icon={<ArrowUpwardIcon />}
            />
          )}
        </div>
        {membersData?.getMembers?.edges?.length > 0 && (
          <Link
            to="../members"
            className="text-base"
            showAsLink
            dataCy="members"
          >
            View all
          </Link>
        )}
      </div>
      {table}
    </Card>
  );
};
