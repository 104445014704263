import React from "react";
import Input from "components/Input";
import { ProgressBar } from "components/ProgressBar/ProgressBar";

export const ErrorMessagesForm = ({
  handleSubmit,
  disableInputs,
  formProgress,
  form,
  handleInputChange,
}) => (
  <>
    <ProgressBar
      progress={formProgress}
      // tw="p-5"
      className="border-b border-app-gray100 px-4 pb-5"
    />
    <form onSubmit={(e) => handleSubmit(e)} className="overflow-auto">
      <section className="w-1/2 px-5 py-5">
        <h4 tw="text-h4 font-bold mb-5">Error Messages</h4>

        <Input
          disabled={disableInputs}
          name="invalid-credentials"
          label="The provided credentials are invalid."
          placeholder="The provided credentials are invalid."
          tw="mb-5"
          hideIndicators
          value={form["invalid-credentials"]}
          onChange={handleInputChange}
        />
        <Input
          disabled={disableInputs}
          name="use-social-login"
          label="Please login with"
          placeholder="Please login with"
          tw="mb-5"
          hideIndicators
          value={form["use-social-login"]}
          onChange={handleInputChange}
        />
        <Input
          disabled={disableInputs}
          name="password-reset-instructions"
          label="If this email exists, you'll receive an email with the reset instructions."
          placeholder="If this email exists, you'll receive an email with the reset instructions."
          tw="mb-5"
          hideIndicators
          value={form["password-reset-instructions"]}
          onChange={handleInputChange}
        />
        <Input
          disabled={disableInputs}
          name="invalid-reset-code"
          label="The provided reset code is invalid."
          placeholder="The provided reset code is invalid."
          tw="mb-5"
          hideIndicators
          value={form["invalid-reset-code"]}
          onChange={handleInputChange}
        />
        <Input
          disabled={disableInputs}
          name="invalid-password-to-short"
          label="Password must be at least 8 characters."
          placeholder="Password must be at least 8 characters."
          tw="mb-5"
          hideIndicators
          value={form["invalid-password-to-short"]}
          onChange={handleInputChange}
        />
        <Input
          disabled={disableInputs}
          name="account-already-connected"
          label="Account already connected."
          placeholder="Account already connected."
          tw="mb-5"
          hideIndicators
          value={form["account-already-connected"]}
          onChange={handleInputChange}
        />
        <Input
          disabled={disableInputs}
          name="account-connect-failed"
          label="Account couldn't be connected."
          placeholder="Account couldn't be connected."
          tw="mb-5"
          hideIndicators
          value={form["account-connect-failed"]}
          onChange={handleInputChange}
        />

        <Input
          disabled={disableInputs}
          name="account-not-found"
          label="Account not found."
          placeholder="Account not found."
          tw="mb-5"
          hideIndicators
          value={form["account-not-found"]}
          onChange={handleInputChange}
        />
        <Input
          disabled={disableInputs}
          name="account-connect-requires-login"
          label="Must be logged in to connect accounts."
          placeholder="Must be logged in to connect accounts."
          tw="mb-5"
          hideIndicators
          value={form["account-connect-requires-login"]}
          onChange={handleInputChange}
        />
        <Input
          disabled={disableInputs}
          name="account-set-password-before-disconnect"
          label="Please set a password before disconnecting your last provider."
          placeholder="Please set a password before disconnecting your last provider."
          tw="mb-5"
          hideIndicators
          value={form["account-set-password-before-disconnect"]}
          onChange={handleInputChange}
        />
        <Input
          disabled={disableInputs}
          name="account-not-connected"
          label="You aren't connected to this provider."
          placeholder="You aren't connected to this provider."
          tw="mb-5"
          hideIndicators
          value={form["account-not-connected"]}
          onChange={handleInputChange}
        />
        <Input
          disabled={disableInputs}
          name="domain-not-permitted"
          label="Your email domain is not permitted to use this plan."
          placeholder="Your email domain is not permitted to use this plan."
          tw="mb-5"
          hideIndicators
          value={form["domain-not-permitted"]}
          onChange={handleInputChange}
        />
        <Input
          disabled={disableInputs}
          name="already-have-plan"
          label="You already have this plan."
          placeholder="You already have this plan."
          tw="mb-5"
          hideIndicators
          value={form["already-have-plan"]}
          onChange={handleInputChange}
        />
        <Input
          disabled={disableInputs}
          name="login-required"
          label="You must be logged in to perform this action."
          placeholder="You must be logged in to perform this action."
          tw="mb-5"
          hideIndicators
          value={form["login-required"]}
          onChange={handleInputChange}
        />
        <Input
          disabled={disableInputs}
          name="passwordless-email-not-found"
          label="The provided email is not found."
          placeholder="The provided email is not found."
          tw="mb-5"
          hideIndicators
          value={form["passwordless-email-not-found"]}
          onChange={handleInputChange}
        />
        <Input
          disabled={disableInputs}
          name="email-already-in-use"
          label="The provided email is already taken."
          placeholder="The provided email is already taken."
          tw="mb-5"
          hideIndicators
          value={form["email-already-in-use"]}
          onChange={handleInputChange}
        />
        <Input
          disabled={disableInputs}
          name="invalid-password"
          label="The provided password is invalid."
          placeholder="The provided password is invalid."
          tw="mb-5"
          hideIndicators
          value={form["invalid-password"]}
          onChange={handleInputChange}
        />
        <Input
          disabled={disableInputs}
          name="invalid-captcha"
          label="Captcha failed. Please try again."
          placeholder="Captcha failed. Please try again."
          tw="mb-5"
          hideIndicators
          value={form["invalid-captcha"]}
          onChange={handleInputChange}
        />
        <Input
          disabled={disableInputs}
          name="plan-member-limit-reached"
          label="The plan member limit has been reached."
          placeholder="The plan member limit has been reached."
          tw="mb-5"
          hideIndicators
          value={form["plan-member-limit-reached"]}
          onChange={handleInputChange}
        />
        <Input
          disabled={disableInputs}
          name="invalid-passwordless-login-code"
          label="The provided verification code is invalid."
          placeholder="The provided verification code is invalid."
          tw="mb-5"
          hideIndicators
          value={form["invalid-passwordless-login-code"]}
          onChange={handleInputChange}
        />
      </section>
      <hr />
      <section className="w-1/2 px-5">
        <h4 tw="text-h4 font-bold my-5">Success Messages</h4>
        <Input
          disabled={disableInputs}
          name="email-verification-sent"
          label="Email verification sent."
          placeholder="Email verification sent."
          tw="mb-5"
          hideIndicators
          value={form["email-verification-sent"]}
          onChange={handleInputChange}
        />
        <Input
          disabled={disableInputs}
          name="plan-successfully-removed"
          label="Plan successfully removed."
          placeholder="Plan successfully removed."
          tw="mb-5"
          hideIndicators
          value={form["plan-successfully-removed"]}
          onChange={handleInputChange}
        />
        <Input
          disabled={disableInputs}
          name="plan-successfully-added"
          label="Plan successfully added."
          placeholder="Plan successfully added."
          tw="mb-5"
          hideIndicators
          value={form["plan-successfully-added"]}
          onChange={handleInputChange}
        />
        <Input
          disabled={disableInputs}
          name="logout-successful"
          label="You have successfully logged out."
          placeholder="You have successfully logged out."
          tw="mb-5"
          hideIndicators
          value={form["logout-successful"]}
          onChange={handleInputChange}
        />
        <Input
          disabled={disableInputs}
          name="signup-successful"
          label="You have successfully signed up."
          placeholder="You have successfully signed up."
          tw="mb-5"
          hideIndicators
          value={form["signup-successful"]}
          onChange={handleInputChange}
        />
        <Input
          disabled={disableInputs}
          name="login-successful"
          label="You have successfully logged in."
          placeholder="You have successfully logged in."
          tw="mb-5"
          hideIndicators
          value={form["login-successful"]}
          onChange={handleInputChange}
        />
        <Input
          disabled={disableInputs}
          name="account-connect-successful"
          label="You have successfully connected your account."
          placeholder="You have successfully connected your account."
          tw="mb-5"
          hideIndicators
          value={form["account-connect-successful"]}
          onChange={handleInputChange}
        />
        <Input
          disabled={disableInputs}
          name="account-disconnect-successful"
          label="You have successfully disconnected your account."
          placeholder="You have successfully disconnected your account."
          tw="mb-5"
          hideIndicators
          value={form["account-disconnect-successful"]}
          onChange={handleInputChange}
        />
        <Input
          disabled={disableInputs}
          name="member-profile-updated"
          label="Your profile has been updated."
          placeholder="Your profile has been updated."
          tw="mb-5"
          hideIndicators
          value={form["member-profile-updated"]}
          onChange={handleInputChange}
        />
        <Input
          disabled={disableInputs}
          name="member-email-updated"
          label="Your email has been updated."
          placeholder="Your email has been updated."
          tw="mb-5"
          hideIndicators
          value={form["member-email-updated"]}
          onChange={handleInputChange}
        />
        <Input
          disabled={disableInputs}
          name="member-password-updated"
          label="Your password has been updated."
          placeholder="Your password has been updated."
          tw="mb-5"
          hideIndicators
          value={form["member-password-updated"]}
          onChange={handleInputChange}
        />
        <Input
          disabled={disableInputs}
          name="member-password-successfully-reset"
          label="Your password has been reset."
          placeholder="Your password has been reset."
          tw="mb-5"
          hideIndicators
          value={form["member-password-successfully-reset"]}
          onChange={handleInputChange}
        />
      </section>
    </form>
  </>
);
