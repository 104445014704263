import React, { useEffect, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Group } from "@mui/icons-material";
import Spacer from "components/Spacer";
import Tag from "components/Tag";
import Text from "components/Text";
import { Card, Dropdown } from "components";
import { useCurrencies } from "hooks/useCurrencies";
import { Price, PriceType } from "generatedTypes";
import { formatPluralizedString } from "helpers/formatPluralizedString";

const intervalDisplay = {
  WEEKLY: "week",
  MONTHLY: "month",
  YEARLY: "year",
};

interface Props {
  prices: Price[];
  onArchival?: (plan) => void;
  onDeletion?: (plan) => void;
}

const List = ({ prices, onArchival, onDeletion }: Props) => {
  const { getCurrencySymbol } = useCurrencies();
  const navigate = useNavigate();
  const [preventClick, setPreventClick] = useState(false);
  const formatInterval = (count, type) => {
    if (!count || !type) {
      return " once";
    }
    return ` / ${
      count === 1
        ? intervalDisplay[type]
        : formatPluralizedString(count, intervalDisplay[type])
    }`;
  };

  useEffect(() => {
    if (preventClick) {
      const timeout = setTimeout(() => setPreventClick(false), 200);
      return () => clearTimeout(timeout);
    }
    return null;
  }, [preventClick]);

  const onToggle = useCallback(() => setPreventClick(true), []);

  return (
    <div className="pt-4">
      {prices.map(
        (
          {
            id,
            name,
            description,
            amount,
            interval,
            currency,
            active,
            memberCount,
            type,
          },
          idx
        ) => (
          <React.Fragment key={id}>
            {idx ? <Spacer spacing="small" /> : null}
            <Card
              className="p-3 overflow-visible"
              key={id}
              isDisabled={!active}
              onClick={() => navigate(`price/${id}`)}
            >
              <div className="flex justify-between items-center">
                <div className="flex items-center">
                  <Text>
                    {`${getCurrencySymbol(
                      currency
                    )}${amount} ${currency?.toUpperCase()}`}
                    {type === PriceType.Subscription &&
                      formatInterval(interval.count, interval.type)}
                  </Text>
                  {memberCount ? (
                    <>
                      <Spacer isVertical={false} spacing="xSmall" />
                      <Tag
                        icon={<Group className="!h-4 !w-4" />}
                        text={memberCount}
                        variant="secondary"
                      />
                    </>
                  ) : null}
                </div>
                <div tw="flex items-center">
                  <Dropdown
                    options={[
                      {
                        text: active ? "Archive Price" : "Activate Price",
                        onClick: () => onArchival({ id, active }),
                      },
                      {
                        text: "Delete Price",
                        onClick: () => onDeletion({ id, name, memberCount }),
                        isDangerText: true,
                      },
                    ]}
                    horizontal
                    onToggle={onToggle}
                  />
                </div>
              </div>
              {description || name ? (
                <Text className="text-app-gray500 mt-1">
                  {description || name}
                </Text>
              ) : null}
            </Card>
          </React.Fragment>
        )
      )}
    </div>
  );
};

export default List;
