import React, { Suspense } from "react";
import { Outlet } from "react-router-dom";
import SidebarWeb from "components/Sidebar";
import Loader from "components/Loader";
import { SidebarProvider } from "components/Sidebar/context/sidebar.context";
import SupportWidget from "components/SupportWidget/SupportWidget";
import { AppDataContextProvider } from "contexts/AppDataContext";
import { UnsavedChangesProvider } from "contexts/UnsavedChangesContext";

const AppLayoutWrapper = ({ children }) => {
  return (
    <>
      <AppDataContextProvider>
        <UnsavedChangesProvider>
          <SidebarProvider>{children}</SidebarProvider>
        </UnsavedChangesProvider>
      </AppDataContextProvider>
      <SupportWidget />
    </>
  );
};

const AppsLayout = () => {
  return (
    <AppLayoutWrapper>
      <div className="h-screen flex overflow-hidden bg-gray-50">
        <SidebarWeb />
        <div className="py-5 flex-1 h-full w-full overflow-auto">
          <Suspense fallback={<Loader isFullScreen />}>
            <Outlet />
          </Suspense>
        </div>
      </div>
    </AppLayoutWrapper>
  );
};

export default AppsLayout;
