import React, { useState } from "react";
import { useToastAlertContext } from "contexts/ToastAlertContext";
import Input from "components/Input";
import {
  GET_MEMBERS,
  GetMembersQuery,
  useRemoveSelectMembersMutation,
} from "features";
import { isError } from "helpers/typeguards";
import { Modal, ModalTypes } from "components/Modal";
import { Form } from "components";
import { ApolloQueryResult } from "@apollo/client";

interface Props extends ModalTypes {
  membersToDelete: string[];
  refetch: (
    variables?: Partial<Record<string, unknown>>
  ) => Promise<ApolloQueryResult<unknown>>;
}

const DeleteSelectMemberModal = ({
  showModal,
  setShowModal,
  membersToDelete,
  refetch,
}: Props) => {
  const [confirmationTextVal, setConfirmationTextVal] = useState("");
  const { createToastAlert } = useToastAlertContext();
  const [modalError, setModalError] = useState(null);

  const totalSelectedMembers = membersToDelete.length.toString();

  const [removeSelectMembers, { loading }] = useRemoveSelectMembersMutation({
    variables: {
      input: {
        membersToDelete,
      },
    },
    update(cache) {
      const members = cache.readQuery<GetMembersQuery>({
        query: GET_MEMBERS,
      });
      // use membersToDelete to filter out the deleted members
      cache.writeQuery<GetMembersQuery>({
        query: GET_MEMBERS,
        data: {
          getMembers: {
            ...members?.getMembers,
            edges: members?.getMembers.edges.filter(
              (member) => !membersToDelete.includes(member.node.id)
            ),
          },
        },
      });
      // update member count
      cache.modify({
        fields: {
          getMembersCount: (existingCount) =>
            existingCount - membersToDelete.length,
        },
      });
    },
    onCompleted: () => {
      refetch();
      setShowModal(false);
      setConfirmationTextVal("");
      createToastAlert({
        alertType: "success",
        message: "Members were successfully deleted.",
      });
    },
    onError: (e) => {
      if (isError(e)) {
        setModalError(e.message);
      }
    },
  });

  const handleConfirmDelete = async () => {
    createToastAlert({ processing: true });
    await removeSelectMembers();
  };

  return (
    <Modal
      setShowModal={setShowModal}
      showModal={showModal}
      title={`Delete ${totalSelectedMembers}  Members`}
      description={`This action cannot be undone. You are deleting ${totalSelectedMembers} members.`}
      showDivider
      actionButtons={{
        confirm: {
          label: "Confirm & Delete",
          onConfirm: handleConfirmDelete,
          isLoading: loading,
          isDisabled: totalSelectedMembers !== confirmationTextVal,
          buttonStyle: "danger",
          dataCy: "confirm-delete-selected-members-button",
        },
      }}
      errorMessage={modalError}
      onErrorClose={() => setModalError(null)}
    >
      <Form
        onSubmit={
          totalSelectedMembers === confirmationTextVal && handleConfirmDelete
        }
      >
        <p className="text-body-sm mb-1">
          Type <strong>{totalSelectedMembers}</strong> to delete these members
          and all associated data from Memberstack.
        </p>
        <Input
          value={confirmationTextVal}
          onChange={(e) => setConfirmationTextVal(e.target.value)}
          placeholder="Enter number of members"
          hideIndicators
          dataCy="delete-selected-members-input"
        />
      </Form>
    </Modal>
  );
};

export default DeleteSelectMemberModal;
