import { formatDistanceToNow, parseISO } from "date-fns";

export const getRelativeTimeFromNow = (timestamp: string): string => {
  try {
    return formatDistanceToNow(parseISO(timestamp));
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(
      "Invalid timestamp provided to `getRelativeTimeFromNow`:",
      timestamp
    );
    return "";
  }
};
