import React, { useEffect } from "react";
import {
  useGetWebhooksQuery,
  useGetSvixAppPortalQuery,
  useGetSsoAppsQuery,
} from "features";
import { useAppDataContext } from "contexts/AppDataContext";
import { DevtoolsLoader } from "./DevtoolsSkeleton";

const DevtoolsContext = React.createContext(null);

const useDevtoolsContext = () => {
  return React.useContext(DevtoolsContext);
};

// TODO: Server needs to provide list of possible versions for dynamic management.
const apiVersion = "2021-01-07";

export const webHookConfigDefaultState: {
  id: string;
  endpoint: string;
  events: string[];
  enabled: boolean;
  signingSecret: string;
  apiVersion: string;
} = {
  id: "",
  endpoint: "",
  events: [] as string[],
  enabled: true,
  apiVersion,
  signingSecret: "",
};

const DevtoolsContextProvider = (props: {
  children: React.ReactNode | React.ReactNode[];
}) => {
  const { appData, isLoading, refetch } = useAppDataContext();

  const {
    loading: loadingWebhooks,
    data: webhooks,
    refetch: refetchWebhooks,
  } = useGetWebhooksQuery();

  const {
    loading: loadingSSOApps,
    data: ssoApps,
    refetch: refetchSSOApps,
  } = useGetSsoAppsQuery();

  const { data: svixUrlData, refetch: refetchSvixAppPortal } =
    useGetSvixAppPortalQuery();

  useEffect(() => {
    refetch();
    refetchWebhooks();
    refetchSSOApps();
    refetchSvixAppPortal();
  }, [refetch, refetchSSOApps, refetchWebhooks, refetchSvixAppPortal]);

  if (isLoading || loadingWebhooks || loadingSSOApps) return <DevtoolsLoader />;

  const { children } = props;
  return (
    <DevtoolsContext.Provider
      value={{
        refetchSSOApps,
        refetchWebhooks,
        refetchSvixAppPortal,
        ssoApps: ssoApps.getSSOApps,
        webhooks: webhooks.getWebhooks,
        apiKeys: appData?.apiKeys,
        apiVersion: appData?.apiVersion,
        svixUrlData: svixUrlData?.getSvixAppPortal,
      }}
    >
      {children}
    </DevtoolsContext.Provider>
  );
};

export { useDevtoolsContext, DevtoolsContextProvider };
