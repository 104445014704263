import React, { useEffect, useMemo, useRef, useState } from "react";
import SettingsBar, { TitleSection } from "components/SettingsBar";
import { Button, Background, ContentWrapper } from "components";
import { useToastAlertContext } from "contexts/ToastAlertContext";
import { isError } from "helpers/typeguards";
import { useParams } from "react-router-dom";
import { useFormTranslations } from "hooks";
import { LoginUI } from "containers/Settings/translation/HostedLoginPage/HostedComponents/LoginUI";
import { getObjectWithKeys } from "helpers/getObjectWithKeys";
import { isObjectEqual } from "helpers/isObjectEqual";
import { removeEmpty } from "helpers/removeEmpty";
import { trimObjectStringValues } from "helpers/trimObjectStringValues";
import { useUpdateSsoTextMutation } from "features";
import { HostedPagesLoader } from "../HostedPagesLoader";
import { HostedPageContextProvider, useHostedPagesContext } from "../context";
import { TranslateLoginForm } from "./TranslateLoginForm";

const DEFAULT_FORM_STATE = {
  login_to_your_account: "",
  email_address: "",
  login_email_placeholder: "",
  password: "",
  login_password_placeholder: "",
  forgot_password: "",
  login: "",
  continue_with: "",
  or: "",
  login_with_password: "",
  login_with_email: "",
  login_verification_code: "",
  login_passwordless_instructions: "",
  login_verify_email: "",
};

const Component = () => {
  const valRef = useRef(null);
  const { appId } = useParams<{ appId: string }>();
  const { createToastAlert } = useToastAlertContext();
  const [formInputs, setFormInputs] = useState<{ [key: string]: string }>(
    DEFAULT_FORM_STATE
  );

  const { appImage, brandColor, loading, refetch, translations } =
    useHostedPagesContext();

  const extractedState = useMemo(
    () =>
      getObjectWithKeys(translations || {}, Object.keys(DEFAULT_FORM_STATE)),
    [translations]
  );

  useEffect(() => {
    if (!translations) {
      return;
    }
    valRef.current = extractedState;
    setFormInputs(extractedState);
  }, [translations, extractedState]);

  const { formProgress, handleInputChange } = useFormTranslations({
    form: formInputs,
    setForm: setFormInputs,
  });

  const [updateSSOTexts, { loading: updatingErrorTexts }] =
    useUpdateSsoTextMutation();

  if (loading)
    return (
      <HostedPagesLoader title="Login">
        <LoginUI
          formInputs={DEFAULT_FORM_STATE}
          brandColor={undefined}
          appImage={undefined}
        />
      </HostedPagesLoader>
    );

  const handleSubmit = async (e) => {
    createToastAlert({ processing: true });
    e.preventDefault();

    try {
      await updateSSOTexts({
        variables: {
          input: {
            textObject: formInputs,
          },
        },
      });
      createToastAlert({
        alertType: "success",
        message: "Successfully updated Error messages",
      });
    } catch (_error) {
      if (isError(_error)) {
        createToastAlert({
          alertType: "error",
          message: _error.message,
        });
      }
    } finally {
      await refetch();
    }
  };

  const didFormChange = !isObjectEqual(
    removeEmpty(valRef.current || {}),
    removeEmpty(trimObjectStringValues(formInputs))
  );

  return (
    <ContentWrapper>
      <SettingsBar>
        <TitleSection
          title="Login"
          backLink={`/apps/${appId}/settings/translations`}
          backLinkTitle="Translations"
          emphasize
          gutter="medium"
          buttons={
            <Button
              text="Save"
              onClick={(e) => handleSubmit(e)}
              isLoading={updatingErrorTexts}
              isDisabled={!didFormChange}
            />
          }
        />
        <TranslateLoginForm
          disableInputs={updatingErrorTexts}
          handleSubmit={handleSubmit}
          formInputs={formInputs}
          formProgress={formProgress}
          handleInputChange={handleInputChange}
        />
      </SettingsBar>
      <Background>
        <div tw="p-5">
          <LoginUI
            formInputs={formInputs}
            brandColor={brandColor}
            appImage={appImage}
          />
        </div>
      </Background>
    </ContentWrapper>
  );
};

export const TranslateLogin = () => (
  <HostedPageContextProvider>
    <Component />
  </HostedPageContextProvider>
);
