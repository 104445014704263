import React, { useState, useCallback } from "react";
import { useToastAlertContext } from "contexts/ToastAlertContext";
import { useGenerateMemberPasswordMutation } from "features/members";
import { getResponseError } from "helpers/getResponseError";
import { Modal, ModalTypes } from "components/Modal";
import useTranslations from "hooks/useTranslations";
import GeneratedPassword from "./GeneratedPassword";
import ResetPasswordOptions from "./ResetPasswordOptions";
import CreatePassword from "./CreatePassword";

interface Props extends ModalTypes {
  memberId: string;
  memberEmail: string;
}

const ResetPasswordModal = ({
  showModal,
  setShowModal,
  memberId,
  memberEmail,
}: Props) => {
  const [view, setView] = useState<React.ReactNode>();
  const [selectedOption, setSelectedOption] = useState();
  const { translate } = useTranslations();
  const [modalError, setModalError] = useState(null);

  const onModalClose = useCallback(() => {
    setView(null);
    setSelectedOption(null);
  }, []);

  const closeModal = useCallback(() => {
    onModalClose();
    setShowModal((show) => !show);
  }, []);

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const { createToastAlert } = useToastAlertContext();
  const [generateMemberPassword] = useGenerateMemberPasswordMutation();

  const handleGeneratePassword = useCallback(async () => {
    setIsSubmitting(true);

    createToastAlert({ processing: true });

    try {
      await generateMemberPassword({
        variables: {
          input: {
            memberId,
          },
        },
      }).then(({ data: response }) => {
        createToastAlert({
          alertType: "success",
          message: "A password was successfully generated.",
        });
        setView(
          <GeneratedPassword
            generatedPassword={
              response.generateMemberPassword.generatedPassword
            }
            closeModal={closeModal}
          />
        );
      });
    } catch (err) {
      setModalError(getResponseError(err));
    } finally {
      setIsSubmitting(false);
    }
  }, [memberId]);

  const handleReset = useCallback(() => {
    if (selectedOption === "generatePassword") {
      return handleGeneratePassword();
    }
    return setView(
      <CreatePassword memberId={memberId} closeModal={closeModal} />
    );
  }, [memberId, selectedOption]);

  return (
    <Modal
      title={translate("resetMemberPassword.title", "Reset Password")}
      description={`${translate(
        "resetMemberPassword.description",
        "for"
      )} ${memberEmail}`}
      showDivider
      setShowModal={setShowModal}
      showModal={showModal}
      showBottomSection={false}
      removePadding
      onModalClose={onModalClose}
      errorMessage={modalError}
      onErrorClose={() => setModalError(null)}
    >
      {view || (
        <ResetPasswordOptions
          closeModal={closeModal}
          setSelectedOption={setSelectedOption}
          handleReset={handleReset}
          isSubmitting={isSubmitting}
        />
      )}
    </Modal>
  );
};

export default ResetPasswordModal;
