/* eslint-disable no-nested-ternary */
import React, { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import styled, { css } from "styled-components";
import { Tooltip } from "components/Tooltip";

const Container = styled.button<{ progress: number }>(
  ({ theme: { colors }, progress }) => css`
    border-radius: 4px;
    outline: 1px solid #ebebeb;
    align-items: center;
    padding: 8px 12px;
    background-color: white;
    display: flex;
    gap: 8px;
    white-space: nowrap;
    font-size: 12px;
    line-height: 16px;
    color: rgba(92, 92, 92, 1);
    & > .progress-container {
      width: 64px;
      background-color: rgba(229, 231, 235, 1);
      border-radius: 9999px;
      height: 4px;
    }
    .progress {
      border-radius: 9999px;
      height: 4px;
      width: ${progress}%;
      background-color: ${progress < 75
        ? colors?.blue400
        : progress < 90
          ? colors?.yellow1
          : colors?.red200};
    }
  `
);

interface Props {
  memberCountLimit: number;
  totalMemberCount: number;
  text?: string;
  interactionType?: "hover" | "click";
}

export const MemberLimitBox = ({
  memberCountLimit,
  totalMemberCount,
  text = "Members",
  interactionType = "click",
}: Props) => {
  const navigate = useNavigate();

  // format numbers
  const totalMemberCountFormatted = totalMemberCount?.toLocaleString();
  const memberCountLimitFormatted = memberCountLimit?.toLocaleString();

  // calculate percentage
  const progress = Math.min((totalMemberCount / memberCountLimit) * 100, 100);

  const tooltipContent = (
    <p className="text-center text-utility-md leading-5">
      You get a maximum of 50 Test Mode Members, switch to Live Mode for more
      Members
    </p>
  );

  const content = useMemo(() => {
    const onNavigate = () => {
      if (interactionType === "click") {
        navigate("../settings/billing");
      }
    };
    return (
      <Container onClick={onNavigate} progress={progress}>
        <div className="progress-container light:bg-gray-700">
          <div className="progress" />
        </div>
        <div>
          <span className="font-bold">
            {totalMemberCountFormatted || 0}&nbsp;of&nbsp;
            {memberCountLimitFormatted}&nbsp;
          </span>
          {text}
        </div>
        {interactionType === "click" && (
          <ChevronRightIcon style={{ fontSize: 16 }} />
        )}
      </Container>
    );
  }, [
    progress,
    totalMemberCountFormatted,
    memberCountLimitFormatted,
    text,
    interactionType,
    navigate,
  ]);

  if (!memberCountLimit || !totalMemberCount) {
    return null;
  }

  if (interactionType === "click") {
    return content;
  }

  return (
    <Tooltip content={tooltipContent} placement="bottom">
      {content}
    </Tooltip>
  );
};
