import React, { useState, useMemo, useEffect } from "react";
import PlaceholderPlan from "components/PlaceholderPlan";
import SettingsBar from "components/SettingsBar";
import { Background, ContentWrapper } from "components";
import StripeUI from "components/Stripe-UI";
import ChoosePlanType from "./ChoosePlanType";
import NewPlanInfo from "./NewPlanInfo";

const paymentTypes = {
  FREE: {
    defaultTitle: "New free plan",
    button: "Sign up for free",
  },
  PAID: {
    defaultTitle: "New paid plan",
    button: "Pay $100 to sign up",
  },
};

export const CreatePlan = () => {
  const [type, setType] = useState<"FREE" | "PAID" | null>(null);
  const [step, setStep] = useState(0);
  const [planIcon, setPlanIcon] = useState("");
  const [planName, setPlanName] = useState("");

  useEffect(() => {
    if (type === "FREE") {
      setPlanName(paymentTypes.FREE.defaultTitle);
    }
    if (type === "PAID") {
      setPlanName(paymentTypes.PAID.defaultTitle);
    }
  }, [type]);

  const renderStep = useMemo(() => {
    const onNext = () => type && step === 0 && setStep(1);
    const onBack = () => step === 1 && setStep(0);

    if (step === 0) {
      return <ChoosePlanType type={type} setType={setType} onNext={onNext} />;
    }
    return (
      <NewPlanInfo
        onBack={onBack}
        type={type}
        onChanges={({ name, icon }) => {
          setPlanName(name);
          setPlanIcon(icon);
        }}
      />
    );
  }, [step, type]);

  return (
    <ContentWrapper>
      <SettingsBar>{renderStep}</SettingsBar>
      {type === "PAID" ? (
        <Background tw="items-center">
          <StripeUI
            priceInputs={{
              type: "SUBSCRIPTION",
              interval: "month",
              currency: "usd",
            }}
            setupFee={0}
            freeTrialDays={0}
            intervalType="month"
            intervalCount={1}
            planName={planName || "New plan"}
            isDemo
          />
        </Background>
      ) : (
        <PlaceholderPlan
          name={planName || "New plan"}
          icon={planIcon}
          selectTitle={step === 0}
          type={type}
          buttonText={paymentTypes[type]?.button || "Sign up"}
        />
      )}
    </ContentWrapper>
  );
};
