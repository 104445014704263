/**
 * `convertObjectToArray` converts an object to an array of objects without mutating the original object
 */
export const convertObjectToArray = (obj) => {
  if (typeof obj === "object") {
    const arr = Object.entries(obj) as [string, number][];
    return arr?.map(([key, value]) => ({ key, value }));
  }
  return null;
};
