import React, { useMemo } from "react";
import { PlayCircleOutlined } from "@mui/icons-material";
import SuspenseWrapper from "components/SuspenseWrapper/SuspenseWrapper";
import cn from "helpers/cn";
import { EnvSwitcher } from "../Switch";
import { Button } from "../Button";
import { Link } from "../Link";

const buttonClasses = "flex items-center gap-3 w-min";

interface Props {
  text: string;
  description?: string;
  buttonText?: string;
  onCreateClick?: (
    event?: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => void;
  onDemoClick?: () => void;
  isFullScreen?: boolean;
  svgImage?: React.ReactNode;
  showEnvSwitcher?: boolean;
  docsLink?: string;
  className?: string;
  id?: string;
  showSVGOnCompact?: boolean;
}

export const EmptyState = ({
  text,
  description,
  buttonText,
  onCreateClick = undefined,
  onDemoClick = undefined,
  isFullScreen = false,
  svgImage,
  showEnvSwitcher = false,
  docsLink,
  className,
  id,
  showSVGOnCompact = true,
}: Props) => {
  const rightPlugin = useMemo(() => {
    if (showEnvSwitcher) return <EnvSwitcher />;
    if (onDemoClick)
      return (
        <Button
          text="Watch Tutorial"
          onClick={onDemoClick}
          buttonStyle="transparent"
          leftIcon={<PlayCircleOutlined />}
        />
      );
    if (docsLink)
      return (
        <Link
          className="flex items-center"
          isExternal
          to={docsLink}
          target="_blank"
          font="regular"
        >
          <Button
            text="View Help Center"
            onClick={onDemoClick}
            buttonStyle="transparent"
          />
        </Link>
      );
    return null;
  }, [docsLink, onDemoClick, showEnvSwitcher]);

  if (isFullScreen) {
    return (
      <div
        className={cn(
          "flex-1 flex items-center w-full bg-no-repeat bg-right justify-center",
          className
        )}
        id={id}
      >
        <div className="w-[378px] ml-14">
          <p className="font-bold text-base">{text}</p>
          <p className="mt-1 text-body-sm text-app-gray500">{description}</p>
          {buttonText && (
            <div className="flex items-center gap-3 mt-4">
              <Button
                text={buttonText}
                onClick={onCreateClick}
                className={buttonClasses}
              />
              {rightPlugin}
            </div>
          )}
        </div>
        {svgImage && <SuspenseWrapper>{svgImage}</SuspenseWrapper>}
      </div>
    );
  }
  return (
    <div
      className={cn(
        "flex flex-col content-center items-center rounded-lg bg-center bg-no-repeat bg-cover px-10 py-9 bg-app-gray50",
        className,
        showSVGOnCompact
          ? "bg-[url('assets/images/empty_state_bg.png')]"
          : "bg-app-gray50"
      )}
      id={id}
    >
      <p className="text-base font-bold">{text}</p>
      <p className="mt-4 text-body-sm text-center max-w-[440px] text-app-gray500">
        {description}
      </p>
      {buttonText && (
        <div className="flex items-center gap-3 mt-4">
          <Button
            text={buttonText}
            onClick={onCreateClick}
            className={buttonClasses}
          />
          {rightPlugin}
        </div>
      )}
    </div>
  );
};
