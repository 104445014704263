/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
const isObject = (obj) => obj && typeof obj === "object";

export const isDeepObjectEqual = (obj1, obj2) => {
  if (Object.keys(obj1).length !== Object.keys(obj2).length) {
    return true;
  }

  for (const key in obj1) {
    const val1 = obj1[key];
    const val2 = obj2[key];

    const areObjects = isObject(val1) && isObject(val2);
    if (
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      (areObjects && !isDeepObjectEqual(val1, val2)) ||
      (!areObjects && val1 !== val2)
    ) {
      return true;
    }
  }

  return false;
};
