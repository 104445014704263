import React, { useContext, useState } from "react";
import { Modal } from "components/Modal";
import { useBlocker } from "react-router-dom";

export const messages = {
  defaultUnsavedMessage:
    "You have unsaved changes.</br> If you leave this page, your changes will be lost.",
  unsavedPlan: "You have made some changes to this plan.",
  unsavedMember: "You have made some changes to this member.",
};

interface DefaultContext {
  setHasUnsavedChanges: React.Dispatch<React.SetStateAction<boolean>>;
  hasUnsavedChanges: boolean;
}

const defaultContext: DefaultContext = {
  setHasUnsavedChanges: undefined,
  hasUnsavedChanges: false,
};

const UnsavedChangesContext = React.createContext(defaultContext);

const useUnsavedChangesContext = () => {
  return useContext(UnsavedChangesContext);
};

const UnsavedChangesProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [showModal, setShowModal] = useState(false);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);

  const blocker = useBlocker(() => {
    if (hasUnsavedChanges) {
      setShowModal(true);
    }
    return hasUnsavedChanges;
  });

  const handleOnConfirm = () => {
    blocker.proceed();
    setShowModal(false);
    setHasUnsavedChanges(false);
  };

  const alertMessage = messages.defaultUnsavedMessage;

  return (
    <UnsavedChangesContext.Provider
      value={{
        setHasUnsavedChanges,
        hasUnsavedChanges,
      }}
    >
      {children}
      <Modal
        setShowModal={setShowModal}
        showModal={showModal}
        title="Discard changes?"
        description={alertMessage}
        showDivider
        actionButtons={{
          confirm: {
            label: "Discard",
            onConfirm: handleOnConfirm,
            buttonStyle: "danger",
          },
          cancel: {
            label: "Go Back",
          },
        }}
      />
    </UnsavedChangesContext.Provider>
  );
};

export { UnsavedChangesProvider, useUnsavedChangesContext };
