import useWebSockets from "hooks/useWebsockets";
import { useEffect } from "react";

const createObservable = () => {
  const observer = new Map();

  return {
    subscribe: (key, fn) => {
      if (typeof fn !== "function") return;
      observer.set(key, fn);
    },
    unsubscribe: (key) => {
      return observer.delete(key);
    },
    notify: (data) => {
      // @ts-ignore
      return [...observer.values()].forEach((fn) => fn(data));
    },
  };
};

const dashboardEventsObservable = createObservable();

const useDashboardEvent = () => {
  const appWebSockets = useWebSockets();

  useEffect(() => {
    if (appWebSockets) {
      try {
        appWebSockets.then((channel) => {
          channel.listen("dashboard.event", (_data) => {
            dashboardEventsObservable.notify(_data);
          });
        });
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      }
    }
  }, [appWebSockets]);

  return dashboardEventsObservable;
};

export default useDashboardEvent;
