import * as Types from '../../generatedTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type GetMemberEventsQueryVariables = Types.Exact<{
  first?: Types.Maybe<Types.Scalars['Int']>;
  after?: Types.Maybe<Types.Scalars['Int']>;
  filters?: Types.Maybe<Types.MemberEventFilter>;
}>;


export type GetMemberEventsQuery = (
  { __typename?: 'Query' }
  & { getMemberEvents?: Types.Maybe<(
    { __typename?: 'MemberEventConnection' }
    & { edges: Array<(
      { __typename?: 'MemberEventEdge' }
      & { node: (
        { __typename?: 'MemberEvent' }
        & Pick<Types.MemberEvent, 'id' | 'memberId' | 'createdAt' | 'source' | 'event' | 'data'>
      ) }
    )>, pageInfo: (
      { __typename?: 'MemberEventPageInfo' }
      & Pick<Types.MemberEventPageInfo, 'endCursor' | 'hasNextPage'>
    ) }
  )> }
);

export type GetMemberEventsDashboardQueryVariables = Types.Exact<{
  first?: Types.Maybe<Types.Scalars['Int']>;
  after?: Types.Maybe<Types.Scalars['Int']>;
  filters?: Types.Maybe<Types.MemberEventFilter>;
}>;


export type GetMemberEventsDashboardQuery = (
  { __typename?: 'Query' }
  & { getMemberEvents?: Types.Maybe<(
    { __typename?: 'MemberEventConnection' }
    & { edges: Array<(
      { __typename?: 'MemberEventEdge' }
      & { node: (
        { __typename?: 'MemberEvent' }
        & Pick<Types.MemberEvent, 'id' | 'memberId' | 'createdAt' | 'source' | 'event' | 'data'>
        & { memberAuth?: Types.Maybe<(
          { __typename?: 'MemberAuthForEvent' }
          & Pick<Types.MemberAuthForEvent, 'email'>
        )> }
      ) }
    )>, pageInfo: (
      { __typename?: 'MemberEventPageInfo' }
      & Pick<Types.MemberEventPageInfo, 'endCursor' | 'hasNextPage'>
    ) }
  )> }
);


export const GetMemberEventsDocument = gql`
    query GetMemberEvents($first: Int, $after: Int, $filters: MemberEventFilter) {
  getMemberEvents(first: $first, after: $after, filters: $filters) {
    edges {
      node {
        id
        memberId
        createdAt
        source
        event
        data
      }
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}
    `;

/**
 * __useGetMemberEventsQuery__
 *
 * To run a query within a React component, call `useGetMemberEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMemberEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMemberEventsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGetMemberEventsQuery(baseOptions?: Apollo.QueryHookOptions<GetMemberEventsQuery, GetMemberEventsQueryVariables>) {
        return Apollo.useQuery<GetMemberEventsQuery, GetMemberEventsQueryVariables>(GetMemberEventsDocument, baseOptions);
      }
export function useGetMemberEventsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMemberEventsQuery, GetMemberEventsQueryVariables>) {
          return Apollo.useLazyQuery<GetMemberEventsQuery, GetMemberEventsQueryVariables>(GetMemberEventsDocument, baseOptions);
        }
export type GetMemberEventsQueryHookResult = ReturnType<typeof useGetMemberEventsQuery>;
export type GetMemberEventsLazyQueryHookResult = ReturnType<typeof useGetMemberEventsLazyQuery>;
export type GetMemberEventsQueryResult = Apollo.QueryResult<GetMemberEventsQuery, GetMemberEventsQueryVariables>;
export const GetMemberEventsDashboardDocument = gql`
    query GetMemberEventsDashboard($first: Int, $after: Int, $filters: MemberEventFilter) {
  getMemberEvents(first: $first, after: $after, filters: $filters) {
    edges {
      node {
        id
        memberId
        createdAt
        source
        event
        data
        memberAuth {
          email
        }
      }
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}
    `;

/**
 * __useGetMemberEventsDashboardQuery__
 *
 * To run a query within a React component, call `useGetMemberEventsDashboardQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMemberEventsDashboardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMemberEventsDashboardQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGetMemberEventsDashboardQuery(baseOptions?: Apollo.QueryHookOptions<GetMemberEventsDashboardQuery, GetMemberEventsDashboardQueryVariables>) {
        return Apollo.useQuery<GetMemberEventsDashboardQuery, GetMemberEventsDashboardQueryVariables>(GetMemberEventsDashboardDocument, baseOptions);
      }
export function useGetMemberEventsDashboardLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMemberEventsDashboardQuery, GetMemberEventsDashboardQueryVariables>) {
          return Apollo.useLazyQuery<GetMemberEventsDashboardQuery, GetMemberEventsDashboardQueryVariables>(GetMemberEventsDashboardDocument, baseOptions);
        }
export type GetMemberEventsDashboardQueryHookResult = ReturnType<typeof useGetMemberEventsDashboardQuery>;
export type GetMemberEventsDashboardLazyQueryHookResult = ReturnType<typeof useGetMemberEventsDashboardLazyQuery>;
export type GetMemberEventsDashboardQueryResult = Apollo.QueryResult<GetMemberEventsDashboardQuery, GetMemberEventsDashboardQueryVariables>;