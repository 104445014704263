import cn from "helpers/cn";
import React from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import Skeleton from "react-loading-skeleton";

// STYLES ARE SPECIFIED IN /src/styles.css

interface TableProps {
  children: React.ReactNode;
  isRowsInteractive?: boolean;
  isHeadersInteractive?: boolean;
  dataTestId?: string;
  fetchMoreData?: () => void;
  hasMoreData?: boolean;
  numberOfData: number;
  className?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  tableProps?: any;
  headerCellPadding?: number;
}

export const Table = ({
  children,
  isRowsInteractive = false,
  isHeadersInteractive = false,
  dataTestId,
  fetchMoreData,
  hasMoreData,
  numberOfData,
  className,
  tableProps,
}: TableProps) => (
  <div
    data-testid={dataTestId}
    className={cn(className, "overflow-x-auto overflow-y-hidden")}
  >
    <InfiniteScroll
      height="100%"
      dataLength={numberOfData}
      next={fetchMoreData}
      hasMore={hasMoreData}
      loader={<Skeleton width="100%" height={40} />}
    >
      <table
        className={cn("inifinite-table", {
          "headers-interactive": isHeadersInteractive,
          "rows-interactive": isRowsInteractive,
        })}
        {...tableProps}
      >
        {children}
      </table>
    </InfiniteScroll>
  </div>
);
