import * as Types from "generatedTypes";
import { ApolloQueryResult } from "@apollo/client";
import { GetPostsQuery } from "features/comments/queries.generated";

export type TabStatus = "all" | keyof typeof Types.PostStatus;

export const STATUS_TABS = [
  {
    label: "All",
    value: "all",
    url: "ALL",
  },
  {
    label: "Published",
    value: Types.PostStatus.Published,
    url: Types.PostStatus.Published,
  },
  {
    label: "Hidden",
    value: Types.PostStatus.Hidden,
    url: Types.PostStatus.Hidden,
  },
  {
    label: "Spam",
    value: Types.PostStatus.Spam,
    url: Types.PostStatus.Spam,
  },
];

export type PostProps = Pick<
  Types.Post,
  | "id"
  | "status"
  | "createdAt"
  | "updatedAt"
  | "content"
  | "likedByCount"
  | "dislikedByCount"
  | "memberId"
  | "ip"
> & {
  member?: Types.Maybe<
    Pick<Types.Member, "profileImage"> & {
      auth: Pick<Types.MemberAuth, "email">;
    }
  >;
};

export interface CommentsProps {
  posts: PostProps[];
  fetchMore: (variables: { variables: { after: number } }) => void;
  endCursor: number;
  refetch: (variables?: {
    order?: Types.PostOrder;
    filters?: Types.GetPostsFilter;
  }) => Promise<ApolloQueryResult<GetPostsQuery>>;
}

export const contentMap = {
  [Types.PostStatus.Published]: {
    title: "No Published Comments",
    description:
      "You don’t currently have any published comments, they will appear here when they are added.",
  },
  [Types.PostStatus.Hidden]: {
    title: "No Hidden Comments",
    description:
      "You don’t currently have any hidden comments, they will appear here when they are added.",
  },
  [Types.PostStatus.Spam]: {
    title: "No Spam Comments",
    description:
      "You don’t currently have any spam comments, they will appear here when you set their status to “spam”.",
  },
  DEFAULT_CONTENT: {
    title: "No Comments",
    description: "There are no comments available.",
  },
};

export const VALID_STATUS_VALUES = [
  Types.PostStatus.Published,
  Types.PostStatus.Spam,
  Types.PostStatus.Hidden,
];
