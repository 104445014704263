import React from "react";
import { useParams } from "react-router-dom";
import SettingsBar, { TitleSection } from "components/SettingsBar";
import { AccordionLoader } from "components";
import { Background } from "components/BackgroundWithGradient/BackgroundWithGradient";
import StripeUI from "components/Stripe-UI";
import InputLoading from "components/Input/Input.loading";
import { DEFAULT_PRICE_INPUT } from "containers/Plans/utils";
import Skeleton from "react-loading-skeleton";

export const CreatePriceLoading = () => {
  const { appId, planId } = useParams<{ appId: string; planId: string }>();

  return (
    <div tw="flex h-full">
      <SettingsBar>
        <TitleSection
          title={<Skeleton width={101} height={32} />}
          backLink={`/apps/${appId}/plans/${planId}/prices`}
          backLinkTitle="Plans"
          buttons={<Skeleton width={103} height={32} />}
          emphasize
        />
        <div className="flex flex-col gap-5 p-5">
          <Skeleton height={32} />
          {Array.from({ length: 4 }, (_, index) => (
            <InputLoading key={index} row={index === 2 ? 2 : undefined} />
          ))}
        </div>
        <AccordionLoader noOfItems={1} hasBorder />
      </SettingsBar>
      <Background>
        <StripeUI
          priceInputs={DEFAULT_PRICE_INPUT}
          setupFee={undefined}
          freeTrialDays={undefined}
          intervalType={undefined}
          intervalCount={undefined}
          planName={undefined}
        />
      </Background>
    </div>
  );
};
