import React from "react";
import { useParams } from "react-router-dom";
import { useEnvContext } from "contexts/EnvironmentContext";
import { useDevtoolsContext } from "../context";
import ApiKeys from "./ApiKeysSection";
import ApplicationId from "./ApplicationId";

const ApiKeyManagement = () => {
  const { apiKeys } = useDevtoolsContext();
  const { isSandboxEnv } = useEnvContext();
  const { appId } = useParams<{ appId: string }>();

  return (
    <div className="flex flex-row gap-6">
      <ApiKeys apiKeys={apiKeys} />
      <ApplicationId appId={appId} isSandboxEnv={isSandboxEnv} />
    </div>
  );
};

export default ApiKeyManagement;
