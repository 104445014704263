import React from "react";
import { TitleSection } from "components/SettingsBar";
import { Button, Link } from "components";
import Skeleton from "react-loading-skeleton";

interface Props {
  hasNavigation?: boolean;
}

const GatedContentLoader = ({ hasNavigation = true }: Props) => (
  <div tw="flex flex-row h-full">
    <div className="w-full h-full flex flex-col">
      <TitleSection
        title="Gated Content"
        titleHint={
          <>
            Gate access to pages, folders, and UI elements on your project.{" "}
            <Link
              to="https://docs.memberstack.com/hc/en-us/articles/7402927868443-Gated-Content"
              isExternal
              underline
            >
              Learn more →
            </Link>
          </>
        }
        buttons={<Button text="+ Add" onClick={undefined} />}
        emphasize
        searchString=""
        setSearchString={() => {}}
      />
      <section className="p-5 overflow-auto">
        <div className="flex flex-col gap-3">
          {[
            "loader1",
            "loader2",
            "loader3",
            "loader4",
            "loader5",
            "loader6",
          ].map((loader) => (
            <Skeleton height={80} key={loader} />
          ))}
        </div>
      </section>
    </div>
  </div>
);

export default GatedContentLoader;
