import React, { useMemo } from "react";
import { MemberTrustStatus } from "generatedTypes";
import { StatusTag } from "components/PlanStatusTag/styles";
import {
  CheckOutlined,
  ClearOutlined,
  NotInterestedOutlined,
  VerifiedUserOutlined,
} from "@mui/icons-material";
import { convertString } from "helpers/convertString";

interface Props {
  status: MemberTrustStatus;
}

const TrustLevelTag = ({ status }: Props) => {
  const transformedStatus = useMemo(
    () => <span>{convertString(status)}</span>,
    [status]
  );

  const renderIcon = useMemo(() => {
    switch (status) {
      case MemberTrustStatus.Trusted:
        return (
          <CheckOutlined
            className="p-0.5 rounded-full bg-app-green50 text-app-green800"
            style={{ fontSize: "16px" }}
          />
        );
      case MemberTrustStatus.Banned:
        return (
          <NotInterestedOutlined
            className="p-0.5 rounded-full bg-red-100 text-red-700"
            style={{ fontSize: "16px" }}
          />
        );
      case MemberTrustStatus.ShadowBanned:
        return (
          <ClearOutlined
            className="p-0.5 rounded-full bg-red-100 text-red-700"
            style={{ fontSize: "16px" }}
          />
        );
      default:
        return (
          <VerifiedUserOutlined
            className="p-0.5 rounded-full bg-app-gray100 text-app-gray800"
            style={{ fontSize: "16px" }}
          />
        );
    }
  }, [status]);

  return (
    <StatusTag
      $fontSize="utilityTagMedium"
      $isOverflow={false}
      className="gap-1"
    >
      {renderIcon}
      {transformedStatus}
    </StatusTag>
  );
};

export default TrustLevelTag;
