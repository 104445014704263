import React, { useEffect, Ref } from "react";

interface Props {
  indeterminate: boolean;
  title: string;
  checked: boolean;
  onChange: (e) => void;
  id: string;
  toggleRowSelected: (id: string) => void;
  dataCy?: string;
}

export const IndeterminateCheckbox = React.forwardRef<
  Ref<HTMLInputElement>,
  Props
>(
  (
    { id, indeterminate, title, checked, onChange, toggleRowSelected, dataCy },
    ref
  ) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;

    const handleOnKeyPress = (event) => {
      if (event.key === "Enter" || event.key === " ") {
        event.stopPropagation();
        event.preventDefault();
        toggleRowSelected(id);
      }
    };

    useEffect(() => {
      // @ts-ignore
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <div
        tw="flex items-center justify-center w-full h-[61px]"
        role="button"
        tabIndex={0}
        onKeyPress={handleOnKeyPress}
        onClick={(e) => {
          e.stopPropagation();
          toggleRowSelected(id);
        }}
      >
        <span tw="flex items-center justify-center w-full border-r h-2/4 px-4">
          <input
            type="checkbox"
            tw="cursor-pointer w-4 h-4"
            checked={checked}
            title={title}
            // @ts-ignore
            ref={resolvedRef}
            onClick={(e) => e.stopPropagation()}
            onChange={onChange}
            data-cy={dataCy}
          />
        </span>
      </div>
    );
  }
);

IndeterminateCheckbox.displayName = "IndeterminateCheckbox";
